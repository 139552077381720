<div class="main-content-sidebar">
  <div class="main-content">
    <div class="header">
      <span class="title">{{ "information" | translate }}</span>
      <img class="close-button" src="../../../../assets/custom-icons/dark_close_icon.svg" (click)="closeSidebar()" />
    </div>
    <div class="rows">
      <div class="rounded-white-background15 item">
        <span class="fontRegular10 fontColorMediumDarkGray">{{ "number" | translate }}</span>
        <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{ codeInfo.code }}</span>
      </div>

      <div class="rounded-white-background15 item">
        <span class="fontRegular10 fontColorMediumDarkGray">{{ "responsible" | translate }}</span>
        <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{ codeInfo.emitter.name }}</span>
      </div>

      <div class="rounded-white-background15 item">
        <span class="fontRegular10 fontColorMediumDarkGray">{{ "reason" | translate }}</span>
        <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{ codeInfo.reason }}</span>
      </div>

      <div class="info-single-row">
        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ "used_by" | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{ codeInfo.account?.name || "Ainda não usado" }}</span>
        </div>

        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ "exam_id" | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{ codeInfo.exam_id! || "Ainda não usado" }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
