import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  urlBeforeSearching = ''
  resetSearch: Subject<string> = new Subject<string>()

  constructor(private router: Router) {}

  goToUrlBeforeSearching() {
    this.router.navigateByUrl(this.urlBeforeSearching)
  }
}
