import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FileService } from "../../../services/file.service";

@Component({
  selector: "app-pdf-preview-modal",
  templateUrl: "./pdf-preview-modal.component.html",
  styleUrls: ["./pdf-preview-modal.component.scss"],
})
export class PdfPreviewModalComponent implements OnInit {
  pdfSrc!: string;

  source!: Uint8Array;

  totalPages = 0;
  currentPage = 1;

  constructor(public modalService: NgbActiveModal, private fileService: FileService) {}

  ngOnInit(): void {
    const fileReader = new FileReader();

    this.fileService.downloadFile(this.pdfSrc).then((res) => {
      fileReader.onload = () => {
        this.source = new Uint8Array(fileReader.result as ArrayBuffer);
      };

      fileReader.readAsArrayBuffer(res);
    });
  }

  afterLoadComplete(pdf: any): void {
    this.totalPages = pdf.numPages;
  }
}
