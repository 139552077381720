<div class="main-content-sidebar">
  <div class="main-content">
    <div class="header">
      <span class="title">{{ 'information' | translate }}</span>
      <img
        class="close-button"
        src="../../../../assets/custom-icons/dark_close_icon.svg"
        (click)="closeSidebar()"
      />
    </div>

    <div class="rows">
      <div class="info-single-row">
        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ 'exam_nr' | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
            examInfo.id
          }}</span>
        </div>

        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ 'date' | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
            examInfo.createdAt | date : 'dd/MM/yyyy'
          }}</span>
        </div>
      </div>
      <div class="info-single-row" *ngIf="examPlacementDate != 'Invalid date'">
        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{
            'placement_date' | translate
          }}</span>
          <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
            examPlacementDate
          }}</span>
        </div>
      </div>
      <div class="info-single-row">
        <div class="rounded-white-background15 item" *ngIf="examWakeUpTime">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ 'wakeup' | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
            examWakeUpTime
          }}</span>
        </div>

        <div class="rounded-white-background15 item" *ngIf="examSleepTime">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ 'bedtime' | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
            examSleepTime
          }}</span>
        </div>
      </div>
      <div class="info-single-row">
        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ 'hour' | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
            examInfo.createdAt | date : 'H:mm'
          }}</span>
        </div>

        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ 'gender' | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
            examInfo.patient.gender | gender
          }}</span>
        </div>
      </div>
      <div class="info-single-row">
        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ 'birthdate' | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">
            {{ examInfo.patient.dateOfBirth | date : 'dd/MM/yyyy' }}
          </span>
        </div>

        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ 'age' | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
            examInfo.patient.dateOfBirth! | age
          }}</span>
        </div>
      </div>
      <div class="info-single-row">
        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ 'height' | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
            examInfo.patient.height
          }}</span>
        </div>

        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ 'weight' | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
            examInfo.patient.weight
          }}</span>
        </div>
      </div>

      <div class="rounded-white-background15 item">
        <span class="fontRegular10 fontColorMediumDarkGray">{{ 'medication' | translate }}</span>
        <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
          examMedication
        }}</span>
      </div>

      <div class="rounded-white-background15 item">
        <span class="fontRegular10 fontColorMediumDarkGray">{{ 'symptoms' | translate }}</span>
        <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
          examSymptoms
        }}</span>
      </div>

      <div class="rounded-white-background15 item" *ngIf="examNotes">
        <span class="fontRegular10 fontColorMediumDarkGray">{{ 'notes' | translate }}</span>
        <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
          examNotes
        }}</span>
      </div>

      <!-- <div class="rounded-white-background15 item">
        <span class="fontRegular10 fontColorMediumDarkGray">{{ "attachments" | translate }}</span>
        <span class="fontRegular10 fontColorPrimary" style="cursor: pointer">1 2 3 4 5 6 7 </span>
      </div> -->

      <!-- <button class="primary-button" style="margin-top: 2em" *ngIf="examIsReported" (click)="seeReport()">{{ "see_report" | translate }}</button> -->
    </div>

    <div *ngIf="examInfo.download_logs?.length">
      <div class="header" style="margin-top: 2em">
        <span class="title">{{ 'download_logs' | translate }}</span>
      </div>
      <div class="rows">
        <div class="rounded-white-background15 item" *ngFor="let log of examInfo.download_logs">
          <span class="fontRegular10 fontColorMediumDarkGray">{{
            log.createdAt | date : 'dd/MM/yyyy, H:mm'
          }}</span>
          <ng-container *ngIf="log.account">
            <ng-container
              *ngIf="log.download_type === 'invoice-store' || log.download_type === 'report-store'"
            >
              <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer"
                >{{ 'O posto descarregou ' }} {{ log.download_type | translate }} com a conta
                {{ log.account.name }} - {{ log.account.id }}</span
              >
            </ng-container>
            <ng-container *ngIf="log.download_type === 'invoice' || log.download_type === 'report'">
              <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer"
                >{{ 'A conta ' }} {{ log.account.name }} - {{ log.account.id }} descarregou
                {{ log.download_type | translate }}</span
              >
            </ng-container>
          </ng-container>
          <ng-container
            *ngIf="
              log.download_type === 'invoice-patient' || log.download_type === 'report-patient'
            "
          >
            <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer"
              >O paciente descarregou {{ log.download_type | translate }}</span
            >
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
