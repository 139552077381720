import { HttpParams } from '@angular/common/http'

export function createHttpParams(params: {}): HttpParams {
  let httpParams: HttpParams = new HttpParams()
  Object.keys(params).forEach((param) => {
    if (params[param as keyof typeof params] != undefined && params[param as keyof typeof params] != null) {
      httpParams = httpParams.set(param, params[param as keyof typeof params])
    }
  })

  return httpParams
}
