import { Component, OnDestroy, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { filter, Observable, Subscription } from 'rxjs'
import { SidebarLoaderService } from '../../services/sidebar-loader.service'
import { TranslateService } from '@ngx-translate/core'
import { AuthService } from '../../services/auth.service'
import { CommonService } from '../../services/common.service'
import RoleGroups from 'src/app/shared/constants/roleGroups'
import { UserRoles } from 'src/app/utils/userRoles'
import { GenericRealTimeService } from 'src/app/services/generic-real-time.service'
import { SearchService } from 'src/app/services/search.service'
import { Account } from 'src/app/models/account'

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss']
})
export class HeaderMobileComponent implements OnInit, OnDestroy {
  //sidebar sub menus visibility
  roleGroups = RoleGroups
  showExamsSubMenu = false
  showTicketsSubMenu = false
  showManagementSubMenu = false
  showToolsSubMenu = false
  showTeleheartSubMenu = false
  showUserMenu = false
  languagesCollapsed = true
  selectedLanguage!: any
  userName = ''
  userRolesTranslated = ''
  searchingValue = ''
  numOfNotifications = 0
  userProfilePic = 'https://www.w3schools.com/howto/img_avatar.png'
  userPicObservable!: Observable<string>
  searchValueSubs!: Subscription
  urlBeforeSearching = ''
  showSearchInput = false
  loggedInUserInfo!: Account

  languagesList = [
    {
      flag: '../../../assets/custom-icons/flag_pt_icon.svg',
      description: 'Português',
      prefix: 'pt'
    },
    { flag: '../../../assets/custom-icons/flag_en_icon.svg', description: 'English', prefix: 'en' },
    {
      flag: '../../../assets/custom-icons/flag_fr_icon.svg',
      description: 'Français',
      prefix: 'fr'
    },
    { flag: '../../../assets/custom-icons/flag_de_icon.svg', description: 'Deutsch', prefix: 'de' }
  ]

  constructor(
    public router: Router,
    private sidebarLoaderService: SidebarLoaderService,
    private translateService: TranslateService,
    private authService: AuthService,
    private commonService: CommonService,
    private genericRealTimeService: GenericRealTimeService,
    private searchService: SearchService
  ) {}

  ngOnDestroy(): void {
    if (this.searchValueSubs) {
      this.searchValueSubs.unsubscribe()
    }
  }

  classApplied = false
  searchActiveApplied = false

  isAuthenticated = true

  ngOnInit(): void {
    this.loggedInUserInfo = this.authService.getUserInfo()
    // initialize default language
    this.selectedLanguage = localStorage.getItem('defaultLang')
    const lang = localStorage.getItem('defaultLang')
    if (lang) {
      this.selectedLanguage = JSON.parse(lang)
    } else {
      this.selectedLanguage = {
        flag: '../../../assets/custom-icons/flag_pt_icon.svg',
        description: 'Português',
        prefix: 'pt'
      }
    }

    // get user profile pic
    const userPic = this.authService.getUserProfilePic()
    this.userName = this.authService.getUserInfo().name || ''

    // get user roles
    const userRoles = this.authService.getUserRoles()

    this.userRolesTranslated = userRoles
      .map(role => this.translateService.instant(UserRoles[role].toLowerCase()))
      .join(', ')

    if (userPic) {
      this.userProfilePic = userPic
    }

    this.userPicObservable = this.commonService.userProfilePic.asObservable()

    this.searchValueSubs = this.searchService.resetSearch.subscribe(res => {
      this.searchingValue = ''
      this.urlBeforeSearching = ''
    })

    this.userPicObservable.subscribe(res => {
      if (res) {
        this.userProfilePic = res
      }
    })

    this.genericRealTimeService.newNotificationsCount.asObservable().subscribe(res => {
      this.numOfNotifications = res
    })
  }

  // detect if user has changed route while sidebar is collapsed. if so, all submenus are closed
  private sub = this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe(event => {
      this.classApplied = false
    })

  userLogout() {
    if (this.classApplied) {
      this.classApplied = false
    }
    this.authService.logout()
  }

  toggleSearchInput() {
    console.log('toggle search clicked.')

    this.showSearchInput = !this.showSearchInput
  }

  toggleAllLanguages() {
    this.languagesCollapsed = !this.languagesCollapsed
  }

  onSearchChange(inputEvent: Event) {
    if (!this.urlBeforeSearching) {
      this.urlBeforeSearching = this.router.url
      this.searchService.urlBeforeSearching = this.router.url
    }

    const searchValue = (inputEvent.target as HTMLInputElement).value
    if (searchValue.length > 0) {
      this.router.navigateByUrl('dashboard/search-results')
      this.router.navigate(['dashboard/search-results'], {
        queryParams: { searchValue: searchValue }
      })
      console.log('not empty: ', searchValue)
    } else {
      this.router.navigateByUrl(this.urlBeforeSearching)
      this.urlBeforeSearching = ''
    }
  }

  newLanguageSelected(language: any) {
    this.selectedLanguage = language
    localStorage.setItem('defaultLang', JSON.stringify(this.selectedLanguage))
    this.translateService.use(language.prefix)
  }

  //sidebar user menu toggle
  toggleUserMenu() {
    this.showUserMenu = !this.showUserMenu
  }

  showUserSettingsSidebar() {
    this.classApplied = false
    this.sidebarLoaderService.toggleUserSettingsSidebar()
  }

  // collapse all submenus
  closeAllSubMenus() {
    this.showExamsSubMenu = false
    this.showTicketsSubMenu = false
    this.showManagementSubMenu = false
    this.showToolsSubMenu = false
  }

  // expand/collaps exams submenu
  toggleExamsSubMenu() {
    const currentState = this.showExamsSubMenu
    this.closeAllSubMenus()
    this.showExamsSubMenu = !currentState
  }

  // expand/collaps tickets submenu
  toggleTicketsSubMenu() {
    const currentState = this.showTicketsSubMenu
    this.closeAllSubMenus()
    this.showTicketsSubMenu = !currentState
  }

  // expand/collapse management submenu
  toggleManagementSubMenu() {
    const currentState = this.showManagementSubMenu
    this.closeAllSubMenus()
    this.showManagementSubMenu = !currentState
  }

  toggleTeleheartSubMenu() {
    const currentState = this.showTeleheartSubMenu
    this.closeAllSubMenus()
    this.showTeleheartSubMenu = !currentState
  }

  // expand/collapse tools submenu
  toggleToolsSubMenu() {
    const currentState = this.showToolsSubMenu
    this.closeAllSubMenus()
    this.showToolsSubMenu = !currentState
  }

  toggleClass() {
    this.classApplied = !this.classApplied
  }

  addSearchActiveClass() {
    this.searchActiveApplied = true
  }

  removeSearchActiveClass() {
    this.searchActiveApplied = false
  }

  showNotificationsSidebar() {
    this.sidebarLoaderService.toggleNotificationsSidebar()
  }
}
