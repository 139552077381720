import { Injectable } from '@angular/core'
import { ExamFilters } from '../models/exam-filters'
import { BehaviorSubject, Subject } from 'rxjs'
import { Exam } from '../models/exam'

@Injectable({
  providedIn: 'root'
})
export class ExamFiltersService {
  currentExamFilters: Subject<ExamFilters> = new Subject<ExamFilters>()
  examFiltersChanged: Subject<ExamFilters> = new Subject<ExamFilters>()

  constructor() {}

  getCurrentFilters(): ExamFilters {
    const savedFiltersString = localStorage.getItem('examFilters') || '{}' // default to empty object
    const filterObj = JSON.parse(savedFiltersString) as any

    // get order by filter information
    const orderType = localStorage.getItem('orderByFilterType')
    const orderDirection = localStorage.getItem('orderByFilterDirection')
    // Check if filterObj has a property "id" and orderByFilterType is "id"
    const shouldExcludeOrderByType = filterObj && 'id' in filterObj && orderType === 'id'

    if (orderType && orderDirection && !shouldExcludeOrderByType) {
      filterObj[orderType] = orderDirection
    }

    // filter object might have nested objects, so we need to check if the nested object has an id property
    if (filterObj) {
      for (const key in filterObj) {
        if (typeof filterObj[key] === 'object' && filterObj[key] !== null) {
          if ('id' in filterObj[key]) {
            filterObj[key] = filterObj[key].id
          }
        }
      }

      return filterObj as ExamFilters
    }
    return {}
  }

  filterExam(exam: Exam): boolean {
    const currentFilters = this.getCurrentFilters()

    if (
      currentFilters.exam_type_id &&
      !currentFilters.exam_type_id.includes(exam.exam_type_id.toString())
    ) {
      return false
    }

    if (
      currentFilters.report_status_id &&
      currentFilters.report_status_id != exam.report_status_id.toString()
    ) {
      return false
    }

    return true
  }
}
