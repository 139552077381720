import { Component, Input, OnInit, OnDestroy } from '@angular/core'
import { NotificationModel } from 'src/app/models/notification-model'
import { NotificationService } from 'src/app/services/notifications.service'
import { SidebarLoaderService } from '../../../services/sidebar-loader.service'
import { Subscription } from 'rxjs'
import { GenericRealTimeService } from 'src/app/services/generic-real-time.service'
import { AuthService } from 'src/app/services/auth.service'
import { createHttpBody } from 'src/app/utils/http-body'
import { SocketInterceptorService } from 'src/app/services/socket-interceptor.service'

@Component({
  selector: 'app-notifications-sidebar',
  templateUrl: './notifications-sidebar.component.html',
  styleUrls: ['./notifications-sidebar.component.scss']
})
export class NotificationsSidebarComponent implements OnInit, OnDestroy {
  @Input()
  notificationsArray: NotificationModel[] = []

  notificationSubs!: Subscription
  showButtonMoreNotificationsSubs!: Subscription
  isButtonShowMoreVisible = true

  constructor(
    private sidebarLoaderService: SidebarLoaderService,
    private notificationService: NotificationService,
    private genericRealTimeService: GenericRealTimeService,
    private authService: AuthService,
    private socketService: SocketInterceptorService
  ) {}

  ngOnInit() {
    this.initNotificationsSubs()
  }

  initNotificationsSubs() {
    this.notificationSubs = this.notificationService.updatedNotifications.asObservable().subscribe({
      next: res => {
        this.notificationsArray = res
      },
      error: err => {}
    })

    this.showButtonMoreNotificationsSubs = this.notificationService.showGetMoreNotificationsButton
      .asObservable()
      .subscribe({
        next: res => {
          this.isButtonShowMoreVisible = res
        },
        error: err => {}
      })
  }

  ngOnDestroy(): void {
    if (this.notificationSubs) {
      this.notificationSubs.unsubscribe()
    }

    if (this.showButtonMoreNotificationsSubs) {
      this.showButtonMoreNotificationsSubs.unsubscribe()
    }
  }

  async clickedNotification(notification: NotificationModel) {
    if (notification.seen == 0) {
      await this.notificationService.updateNotification(notification.id!, 1)
      const notificationIndex = this.notificationsArray.findIndex(
        notif => notif.id == notification.id
      )
      this.notificationsArray[notificationIndex].seen = 1
      this.notificationService.updatedNotifications.next(this.notificationsArray)

      const currentValue = this.genericRealTimeService.newNotificationsCount.getValue()
      const updatedValue = currentValue - 1
      this.genericRealTimeService.newNotificationsCount.next(updatedValue)
    }
  }

  markAllAsRead() {
    const body: any = createHttpBody({
      accountId: this.authService.getLoggedInUserId()
    })

    this.socketService
      .makeCustomRequest('notifications', 'markAllAsRead', body)
      .then(res => {
        console.log('res: ', res)
        this.notificationsArray.forEach(notif => {
          notif.seen = 1
        })
        this.notificationService.updatedNotifications.next(this.notificationsArray)
        this.genericRealTimeService.newNotificationsCount.next(0)
      })
      .catch(err => {
        console.log('error marking all as read: ', err)
      })
  }

  loadMoreNotifications() {
    this.notificationService.loadMoreNotifications(this.authService.getLoggedInUserId())
  }

  closeSidebar() {
    this.sidebarLoaderService.closeSidebar()
  }
}
