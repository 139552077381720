<!-- show default header and sidebar on desktop -->
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

<div class="loader-container" *ngIf="isLoading | async">
  <div class="loader">
    <img
      src="../../assets/custom-icons/logo_collapsed.svg"
      style="height: 20vmin"
      class="loading-logo"
      alt="logo"
    />
  </div>
</div>

<div style="display: flex; height: 100%" *ngIf="!mobileView">
  <div [ngStyle]="{ width: sidebarCollapsed ? '10%' : '20%' }">
    <app-default-sidebar [collapsed]="sidebarCollapsed"></app-default-sidebar>
  </div>

  <div class="container-1" [ngStyle]="{ width: sidebarCollapsed ? '90%' : '80%' }">
    <div class="header-1">
      <app-default-header
        (sidebarCollapsedEvent)="sidebarCollapseEvent($event)"
      ></app-default-header>
      <mat-progress-bar mode="indeterminate" *ngIf="isLoadingBar | async"></mat-progress-bar>
    </div>
    <div class="main-1">
      <div style="height: 100%; display: flex">
        <div class="main-container">
          <router-outlet></router-outlet>
        </div>
        <div class="side-container-desktop" [class.showDetailsSidebarDesktop]="showDetailsSidebar">
          <ng-container #container></ng-container>
        </div>
      </div>
    </div>
    <div class="footer-1"><app-default-footer></app-default-footer></div>
  </div>

  <div>
    <div></div>
  </div>
</div>

<!-- show mobile header on mobile -->
<div style="height: 100%" *ngIf="mobileView">
  <app-header-mobile></app-header-mobile>
  <mat-progress-bar mode="indeterminate" *ngIf="isLoadingBar | async"></mat-progress-bar>
  <div class="loader-container" *ngIf="isLoading | async">
    <div class="loader">
      <img
        src="../../assets/custom-icons/logo_collapsed.svg"
        style="height: 50vmin"
        class="loading-logo"
        alt="logo"
      />
    </div>
  </div>

  <div class="main-container">
    <router-outlet></router-outlet>
  </div>
  <div class="side-container-mobile" [class.showDetailsSidebarMobile]="showDetailsSidebar">
    <ng-container #container></ng-container>
  </div>
</div>
