<div class="notification-card" (click)="clickedWarning(item)">
  <div class="flexRow5">
    <span class="fontColorMediumDarkGray fontSemiBold10">{{ item.name }}</span>
    <button (click)="removeExamGroupFromList()" class="button-with-img">
      <img src="../../../../../../assets/custom-icons/delete_icon.svg" alt="" />
    </button>
  </div>
  <span class="fontRegular10 fontColorDefaultGray">{{ item.message }}</span>
  <span class="fontColorMediumDarkGray fontRegular10 pointer"
    (click)="openReqPdf(item.examRequestGroup!)">{{item.examRequestGroup?.state | examRequestGroupState}}</span>
  <ng-container *ngIf="examsToBeScheduled.length > 0">
    <span class="fontColorMediumDarkGray fontRegular10">{{ 'in_house_exams' | translate }}:
      <div class="flexColumn5">
        <ng-container *ngFor="let examRequest of examsToBeScheduled">
          <div *ngIf="examRequest.exam_request_type.exam_type_id"
            (click)="examRequest.state != 3 ? scheduleExam(examRequest.exam_request_type.exam_type_id) : null">
            <span>{{ examRequest.exam_request_type.exam_type_id | examType }}</span>
            <span>{{ examRequest.state === 2 ? "(Agendar)" : "(Agendado)" }}</span>
          </div>
        </ng-container>
        <!-- <span *ngFor="let examTypeId of inHouseExamTypeIds" (click)="scheduleExam(examTypeId)"
          class="fontSemiBold10 pointer">{{
          examTypeId | examType
          }} {{ }}</span> -->
      </div>
      <div *ngIf="examsToBeScheduled.length > 1" style="margin-top: 5px;">
        <span class="pointer fontSemiBold10" (click)="scheduleExam()">Agendar todos</span>
      </div>
    </span>
  </ng-container>
</div>