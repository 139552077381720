import { Injectable } from '@angular/core'
import { timer } from 'rxjs'

export enum ToastType {
  INFO,
  SUCCESS,
  ERROR
}

export interface ToastStyle {
  icon: string
  color: string
}

export interface ToastInfo {
  toastStyle?: ToastStyle
  header: string
  body: string
  delay?: number
}

@Injectable({ providedIn: 'root' })
export class AppToastService {
  newToastInfo: ToastInfo = { header: '', body: '' }
  toasts: ToastInfo[] = []
  tempToasts: ToastInfo[] = []
  currentSubscription: any

  show(type: ToastType, header: string, body: string, delay?: number) {
    let toastStyle = {} as ToastStyle

    if (type == ToastType.SUCCESS) {
      toastStyle = {
        color: '#0090C6',
        icon: '../../../../assets/custom-icons/toast_check_icon.svg'
      }
    } else if (type == ToastType.INFO) {
      toastStyle = {
        color: '#6775C2',
        icon: '../../../../assets/custom-icons/toast_exclamation_icon.svg'
      }
    } else if (type == ToastType.ERROR) {
      toastStyle = {
        color: '#B03B7A',
        icon: '../../../../assets/custom-icons/toast_close_icon.svg'
      }
    }

    this.newToastInfo = { toastStyle, header, body, delay }
    const toastIndex = this.tempToasts.findIndex(toast => toast.body == this.newToastInfo.body)

    if (toastIndex < 0) {
      this.tempToasts.push(this.newToastInfo)
      if (this.toasts.length === 0) {
        this.displayNextToast()
      }
    }
  }

  displayNextToast() {
    const nextToast = this.tempToasts.shift()

    if (nextToast) {
      this.toasts.push(nextToast)
      this.currentSubscription = timer(nextToast.delay || 4500).subscribe(() => {
        this.remove(nextToast)
        this.displayNextToast()
      })
    }
  }

  remove(toast: ToastInfo) {
    this.toasts = this.toasts.filter(t => t != toast)
    this.tempToasts = this.tempToasts.filter(t => t != toast)
  }

  clickedToast(toast: ToastInfo) {
    this.currentSubscription.unsubscribe()
    this.remove(toast)
    this.displayNextToast()
  }
}
