import { Injectable, OnDestroy } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router'
import { Observable, Subscription } from 'rxjs'
import { AuthService } from '../services/auth.service'

@Injectable()
export class AuthGuard implements OnDestroy {
  constructor(private authService: AuthService, private router: Router) {}

  private authListenerSub!: Subscription

  ngOnDestroy(): void {
    if (this.authListenerSub) {
      this.authListenerSub.unsubscribe()
    }
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> | Observable<boolean> | boolean {
    return new Promise(async (resolve, reject) => {
      const alreadyAuth = this.authService.getIsAuth()
      if (alreadyAuth) {
        resolve(true)
      } else {
        this.authListenerSub = this.authService
          .getAuthStatusListener()
          .subscribe((isAuthenticated: boolean) => {
            if (isAuthenticated) {
              resolve(true)
            } else {
              this.router.navigate(['/login'])
              resolve(false)
            }
          })
      }
    })
  }
}
