// import { StepOptions } from 'angular-shepherd';

import { Router } from '@angular/router'
import { ShepherdService } from 'angular-shepherd'
import { AccountService } from '../services/account.service'
import { AuthService } from '../services/auth.service'

export class ShedpherdTutorialGuides {
  router!: Router
  tour!: ShepherdService
  accountsService!: AccountService
  authService!: AuthService

  constructor(
    _router: Router,
    _tour: ShepherdService,
    _accountsService: AccountService,
    _authService: AuthService
  ) {
    this.router = _router
    this.tour = _tour
    this.accountsService = _accountsService
    this.authService = _authService
  }

  STEPS_BUTTONS = {
    back: {
      classes: 'back-button',
      secondary: true,
      text: 'Retroceder',
      type: 'back'
    },
    cancel: {
      classes: 'cancel-button',
      secondary: true,
      text: 'Sair',
      type: 'cancel'
    },
    next: {
      classes: 'next-button',
      text: 'Avançar',
      type: 'next'
    },
    complete: {
      classes: 'cancel-button',
      secondary: false,
      text: 'Completar',
      action: () => {
        this.tour.complete()
      }
    }
  }

  INITIALGUIDEWITHOUTEXAMSUBMITTION = [
    {
      attachTo: {
        element: '#welcome',
        on: 'bottom'
      },
      canClickTarget: false,
      buttons: [this.STEPS_BUTTONS.next],
      title: 'Bem vindo ao novo portal',
      text: `Pedimos que leia com atenção as seguintes instruções de modo a facilitar a sua navegação e utilização do novo portal.`,
      when: {
        show: () => {
          this.router.url != '/dashboard' ? this.router.navigate(['dashboard']) : null
        }
      }
    },
    {
      attachTo: {
        element: '#tabExamsWithoutFile',
        on: 'right'
      },
      canClickTarget: false,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Exames sem anexo',
      text: `Neste separador pode consultar os exames da sua loja que não tem anexo.`
    },
    {
      attachTo: {
        element: '#tabExamsToRepeat',
        on: 'right'
      },
      canClickTarget: false,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Exames por repetir',
      text: `Neste separador pode consultar os exames que foram marcados para repetir e anexar um novo ficheiro de exame.`
    },
    {
      attachTo: {
        element: '#tabSubmittedExams',
        on: 'right'
      },
      canClickTarget: false,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Exames submetidos',
      text: `Neste separador pode consultar todos os exames que já foram submetidos pela sua loja. Poderá também fazer algumas alterações às informações do mesmo caso seja permitido.`
    },
    {
      attachTo: {
        element: '#tabTickets',
        on: 'right'
      },
      canClickTarget: false,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Situações',
      text: 'No caso de haver alguma dúvida, poderá submeter uma situação e acompanhar o estado da mesma neste separador.',
      advanceOn: { selector: '#tabTickets', event: 'click' }
    },
    {
      attachTo: {
        element: '#tabManagement',
        on: 'right'
      },
      canClickTarget: false,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Gestão',
      text: 'Neste separador terá a acesso a ferramentas de gestão para a sua loja (exemplo: submeter recibos).'
    },
    {
      canClickTarget: false,
      buttons: [
        this.STEPS_BUTTONS.back,
        {
          classes: 'cancel-button',
          secondary: false,
          text: 'Completar',
          action: async () => {
            await this.accountsService.updateShowTutorialParam(
              this.authService.getLoggedInUserId(),
              false
            )
            localStorage.setItem('showTour', '0')
            this.tour.complete()
          }
        }
      ],
      title: 'Tutorial completo',
      text: 'Pode sempre voltar a ver este pequeno guia clicando no ponto de interrogação na barra superior do site.'
    }
  ]

  INITIALGUIDEWITHEXAMSUBMITTION = [
    {
      attachTo: {
        element: '#welcome',
        on: 'bottom'
      },
      canClickTarget: false,
      buttons: [this.STEPS_BUTTONS.next],
      title: 'Bem vindo ao novo portal',
      text: `Pedimos que leia com atenção as seguintes instruções de modo a facilitar a sua navegação e utilização do novo portal.`,
      when: {
        show: () => {
          this.router.url != '/dashboard' ? this.router.navigate(['dashboard']) : null
        }
      }
    },
    // start of how to submit exam
    {
      attachTo: {
        element: '#tabSubmitExam',
        on: 'right'
      },
      canClickTarget: false,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Submeter Exame',
      text: 'Este é o separador onde pode submeter novos exames.'
    },
    {
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Como submeter um exame',
      text: 'Vamos agora aprender como submeter um exame.',
      when: {
        show: () => {
          this.router.navigate(['dashboard/submissions/submit-exam'])
        }
      }
    },
    {
      attachTo: {
        element: '#selectExamTypeTutorial',
        on: 'bottom'
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Selecionar tipo de exame',
      text: 'O primeiro passo é selecionar o tipo de exame que quer submeter.'
    },
    {
      attachTo: {
        element: '#selectExamPriorityTutorial',
        on: 'bottom'
      },
      showOn() {
        const element = document.querySelector('#selectExamPriorityTutorial')
        return Boolean(element)
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Selecionar prioridade do exame',
      text: 'Pode também selecionar a prioridade do exame (baixa, média e alta). Pedimos que use esta opção apenas quando for mesmo necessário. No caso de notarmos que a opção está de alguma maneira a ser abusada, a mesma será desativada para a sua loja.'
    },
    {
      attachTo: {
        element: '#searchPatientInputTutorial',
        on: 'bottom'
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Pesquisar o paciente',
      text: 'Aqui pode procurar um paciente por nome ou nº de identificação. O paciente só vai ser listado caso a sua loja já tenha submetido algum exame com esse mesmo paciente.'
    },
    {
      attachTo: {
        element: '#patientDetailsTutorial',
        on: 'bottom'
      },
      modalOverlayOpeningPadding: 10,
      canClickTarget: false,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Informações do paciente',
      text: 'Nesta secção poderá editar as informações de um paciente previamente selecionado da barra de pesquisa. Caso seja um novo paciente, basta preencher as informações e o mesmo será criado.'
    },
    {
      attachTo: {
        element: '#examInfoTutorial',
        on: 'top'
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Informações do exame',
      text: 'Nesta secção deve preencher as informações do exame.'
    },
    {
      attachTo: {
        element: '#attachFilesTutorial',
        on: 'top'
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Anexar ficheiros',
      text: 'Nesta secção deve anexar ficheiros relativos ao exame, bem como algum ficheiro com informações adicionais ao mesmo.'
    },
    {
      attachTo: {
        element: '#attachFilesTutorial',
        on: 'top'
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Anexar ficheiros mais tarde',
      text: 'Pode também submeter um exame nem anexar nenhum ficheiro, podendo anexar os ficheiros mais tarde na opção "Exames sem Anexo".'
    },
    // end of how to submit exam
    {
      attachTo: {
        element: '#tabExamsWithoutFile',
        on: 'right'
      },
      canClickTarget: false,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Exames sem anexo',
      text: `Neste separador pode consultar os exames da sua loja que não tem anexo.`
    },
    {
      attachTo: {
        element: '#tabExamsToRepeat',
        on: 'right'
      },
      canClickTarget: false,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Exames por repetir',
      text: `Neste separador pode consultar os exames que foram marcados para repetir e anexar um novo ficheiro de exame.`
    },
    {
      attachTo: {
        element: '#tabSubmittedExams',
        on: 'right'
      },
      canClickTarget: false,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Exames submetidos',
      text: `Neste separador pode consultar todos os exames que já foram submetidos pela sua loja. Poderá também fazer algumas alterações às informações do mesmo caso seja permitido.`
    },
    {
      attachTo: {
        element: '#tabTickets',
        on: 'right'
      },
      canClickTarget: false,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Situações',
      text: 'No caso de haver alguma dúvida, poderá submeter uma situação e acompanhar o estado da mesma neste separador.',
      advanceOn: { selector: '#tabTickets', event: 'click' }
    },
    {
      attachTo: {
        element: '#tabManagement',
        on: 'right'
      },
      canClickTarget: false,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Gestão',
      text: 'Neste separador terá a acesso a ferramentas de gestão para a sua loja (exemplo: submeter depósitos).'
    },
    {
      attachTo: {
        element: '#help-guide-button',
        on: 'bottom'
      },
      canClickTarget: false,
      buttons: [
        this.STEPS_BUTTONS.back,
        {
          classes: 'cancel-button',
          secondary: false,
          text: 'Completar',
          action: async () => {
            await this.accountsService.updateShowTutorialParam(
              this.authService.getLoggedInUserId(),
              false
            )
            localStorage.setItem('showTour', '0')
            this.tour.complete()
          }
        }
      ],
      title: 'Tutorial completo',
      text: 'Pode sempre voltar a ver este pequeno guia clicando no ponto de interrogação na barra superior do site.'
    }
  ]

  HOW_TO_SUBMIT_EXAM = [
    {
      attachTo: {
        element: '#tabSubmitExam',
        on: 'right'
      },
      canClickTarget: false,
      buttons: [this.STEPS_BUTTONS.next],
      title: 'Submeter Exame',
      text: 'Este é o separador onde clica para submeter novos exames.'
    },
    {
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Como submeter um exame',
      text: 'Vamos agora aprender como submeter um exame.',
      when: {
        show: () => {
          this.router.navigate(['dashboard/submissions/submit-exam'])
        }
      }
    },
    {
      attachTo: {
        element: '#selectExamTypeTutorial',
        on: 'bottom'
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Selecionar tipo de exame',
      text: 'O primeiro passo é selecionar o tipo de exame que quer submeter.'
    },
    {
      attachTo: {
        element: '#selectExamPriorityTutorial',
        on: 'bottom'
      },
      showOn() {
        const element = document.querySelector('#selectExamPriorityTutorial')
        return Boolean(element)
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Selecionar prioridade do exame',
      text: 'Pode também selecionar a prioridade do exame (baixa, média e alta). Pedimos que use esta opção apenas quando for mesmo necessário. No caso de notarmos que a opção está de alguma maneira a ser abusada, a mesma será desativada para a sua loja.'
    },
    {
      attachTo: {
        element: '#searchPatientInputTutorial',
        on: 'bottom'
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Pesquisar o paciente',
      text: 'Aqui pode procurar um paciente por nome ou nº de identificação. O paciente só vai ser listado caso a sua loja já tenha submetido algum exame com esse mesmo paciente.'
    },
    {
      attachTo: {
        element: '#patientDetailsTutorial',
        on: 'bottom'
      },
      modalOverlayOpeningPadding: 10,
      canClickTarget: false,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Informações do paciente',
      text: 'Nesta secção poderá editar as informações de um paciente previamente selecionado da barra de pesquisa. Caso seja um novo paciente, basta preencher as informações e o mesmo será criado.'
    },
    {
      attachTo: {
        element: '#examInfoTutorial',
        on: 'top'
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Informações do exame',
      text: 'Nesta secção deve preencher as informações do exame.'
    },
    {
      attachTo: {
        element: '#attachFilesTutorial',
        on: 'top'
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Anexar ficheiros',
      text: 'Nesta secção deve anexar ficheiros relativos ao exame, bem como algum ficheiro com informações adicionais ao mesmo.'
    },
    {
      attachTo: {
        element: '#attachFilesTutorial',
        on: 'top'
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [
        this.STEPS_BUTTONS.back,
        {
          classes: 'cancel-button',
          secondary: false,
          text: 'Completar',
          action: () => {
            this.tour.complete()
          }
        }
      ],
      title: 'Anexar ficheiros mais tarde',
      text: 'Pode também submeter um exame nem anexar nenhum ficheiro, podendo anexar os ficheiros mais tarde na opção "Exames sem Anexo".'
    }
  ]

  HOW_TO_SUBMIT_RECEIPT = [
    {
      buttons: [this.STEPS_BUTTONS.next],
      title: 'Como submeter um depósito',
      text: 'Vamos agora aprender como submeter um novo depósito.',
      modalOverlayOpeningPadding: 10,
      showOn: () => {
        const element = document.getElementById('tabManagement')
        const tabManagementVisible = Boolean(element)
        if (!tabManagementVisible) {
          this.tour.complete()
          window.alert('Não tem acesso para submeter um depósito.')
          return tabManagementVisible
        }
        return tabManagementVisible
      },
      when: {
        show: () => {
          const managementTab = document.getElementById('tabManagement')

          if (managementTab) {
            const subMenu = managementTab.querySelector('.sidebar-sub-menu')

            if (!subMenu) {
              const managementTabItem = document.getElementById('tabManagementItem')
              if (managementTabItem) {
                managementTabItem.click()
              }
            }
          }
        }
      }
    },
    {
      attachTo: {
        element: '#managementTabReceipts',
        on: 'right'
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Anexar ficheiros',
      text: 'Primeiro deve clicar neste botão.',
      when: {
        hide: () => {
          this.router.navigate(['dashboard/management/receipts-deposits'])
        }
      }
    },
    {
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Anexar ficheiros',
      text: 'Será redirecionado para esta página.'
    },
    {
      attachTo: {
        element: '#addReceiptButton',
        on: 'top'
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Anexar ficheiros',
      text: 'Clica neste botão.',
      when: {
        hide: () => {
          const addReceiptButton = document.getElementById('addReceiptButton')

          if (addReceiptButton) {
            addReceiptButton.click()
          }
        }
      }
    },
    {
      attachTo: {
        element: '#addReceiptSelectDates',
        on: 'bottom'
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Anexar ficheiros',
      text: 'Aqui escolhe as data de início e a data de fim a que o depósito se refere.'
    },
    {
      attachTo: {
        element: '#addReceiptSelectStore',
        on: 'bottom'
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Anexar ficheiros',
      text: 'Seleciona a loja a que se refere o depósito.'
    },
    {
      attachTo: {
        element: '#addReceiptInsertValue',
        on: 'top'
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Anexar ficheiros',
      text: 'Insere o valor do depósito.'
    },
    {
      attachTo: {
        element: '#addReceiptSelectFile',
        on: 'top'
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Anexar ficheiros',
      text: 'E finalmente escolhe o ficheiro referente ao depósito.'
    },
    {
      attachTo: {
        element: '#addReceiptButtonModal',
        on: 'top'
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.complete],
      title: 'Anexar ficheiros',
      text: 'Após as informações estarem todas preenchidas, pode clicar no botão "Adicionar depósito" para que o mesmo seja adicionado.'
    }
  ]

  HOW_TO_DOWNLOAD_EXAM = [
    {
      attachTo: {
        element: '#tabSubmittedExams',
        on: 'right'
      },
      buttons: [this.STEPS_BUTTONS.next],
      title: 'Descarregar exame/fatura/enviar email',
      text: 'Primeiro deve clicar no botão "Exames Submetidos.',
      modalOverlayOpeningPadding: 10,
      showOn: () => {
        const element = document.getElementById('tabSubmittedExams')
        const tabSubmittedExamsVisible = Boolean(element)
        if (!tabSubmittedExamsVisible) {
          this.tour.complete()
          window.alert('Não tem acesso para descarregar exames.')
          return tabSubmittedExamsVisible
        }
        return tabSubmittedExamsVisible
      },
      when: {
        hide: () => {
          const storeSubmittedExamsButton = document.getElementById('tabSubmittedExamsItem')

          if (storeSubmittedExamsButton) {
            storeSubmittedExamsButton.click()
          }
        }
      }
    },
    {
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Descarregar exame/fatura/enviar email',
      text: 'Será redirecionado para o ecrã com todos os exames submetidos pelo seu posto.',
      when: {
        hide: () => {
          const testRow = document.getElementById('example-row')

          if (testRow) {
            return
          }

          // Step 1: Select the target div by its class
          const targetDiv = document.querySelector('.table-header-container')

          // insert test row
          if (targetDiv) {
            targetDiv.insertAdjacentHTML(
              'afterend',
              `<div class="table-data-row" id="example-row">
            <span class="fontSemiBold10 fontColorDefaultGray" style="width: 10%;">123 - ECG</span>
            <span style="width: 10%;" id="example-row-date">27-09-2023</span>
            <span style="width: 10%;" id="example-row-store">Posto de teste</span>
            <span style="width: 15%;" id="example-row-state">Concluído</span>
            <span style="width: 25%;" id="example-row-patient">Nome Paciente</span>
            <span style="width: 15%;" id="example-row-submitted-by">Nome Técnico</span>
            <div style="width: 20%; display: flex; justify-content: center; gap: 7px; align-items: center">
              <div id="example-icon-info">
                <button style="width: 25px;
                height: 25px;
                padding: 6px;
                cursor: pointer;
                background: #C8CFD0;
                border-radius: 50%;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;">
                  <img src="../assets/custom-icons/exam_row_info_icon.svg" alt="" style="height: 100%; width: 100%; cursor:pointer;">
                </button>
              </div>
              <div id="example-icon-report">
                <button style="width: 25px;
                height: 25px;
                padding: 6px;
                cursor: pointer;
                background: #C8CFD0;
                border-radius: 50%;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;">
                  <img src="../assets/custom-icons/exam_row_report_icon.svg" alt="" style="height: 100%; width: 100%; cursor:pointer;">
                </button>
              </div>
              <div id="example-icon-invoice">
                <button style="width: 25px;
                height: 25px;
                padding: 6px;
                cursor: pointer;
                background: #C8CFD0;
                border-radius: 50%;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;">
                  <img src="../assets/custom-icons/invoice_icon.svg" alt="" style="height: 100%; width: 100%; cursor:pointer;">
                </button>
              </div>
              <div id="example-icon-email">
                <button style="width: 25px;
                height: 25px;
                padding: 6px;
                cursor: pointer;
                background: #C8CFD0;
                border-radius: 50%;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;">
                  <img src="../assets/custom-icons/email_icon.svg" alt="" style="height: 100%; width: 100%; cursor:pointer;">
                </button>
              </div>
              <div id="example-icon-delete">
                <button style="width: 25px;
                height: 25px;
                padding: 6px;
                cursor: pointer;
                background: #C8CFD0;
                border-radius: 50%;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;">
                  <img src="../assets/custom-icons/exam_row_delete_icon.svg" alt="" style="height: 100%; width: 100%; cursor:pointer;">
                </button>
              </div>
            </div>
          </div>`
            )
          }
        }
      }
    },
    {
      attachTo: {
        element: '#example-row',
        on: 'bottom'
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 10,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Descarregar exame/fatura/enviar email',
      text: 'Cada linha representa um exame submetido.'
    },
    {
      attachTo: {
        element: '#example-row-date',
        on: 'bottom'
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 5,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Descarregar exame/fatura/enviar email',
      text: 'Esta é a data em que o exame foi submetido.'
    },
    {
      attachTo: {
        element: '#example-row-store',
        on: 'bottom'
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 5,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Descarregar exame/fatura/enviar email',
      text: 'Esta é a identificação da loja que submeteu o exame.'
    },
    {
      attachTo: {
        element: '#example-row-state',
        on: 'bottom'
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 5,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Descarregar exame/fatura/enviar email',
      text: 'Pode consultar o estado do exame. \n1- Submetido \n2- Análise em curso \n3- Aguarda assinatura \n4- Concluído'
    },
    {
      attachTo: {
        element: '#example-row-patient',
        on: 'bottom'
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 5,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Descarregar exame/fatura/enviar email',
      text: 'O nome do paciente'
    },
    {
      attachTo: {
        element: '#example-row-submitted-by',
        on: 'bottom'
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 5,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Descarregar exame/fatura/enviar email',
      text: 'A identificação da conta que submeteu o exame.'
    },
    {
      attachTo: {
        element: '#example-icon-info',
        on: 'left'
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 5,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Descarregar exame/fatura/enviar email',
      text: 'Neste botão pode ver mais informações sobre o exame submetido.'
    },
    {
      attachTo: {
        element: '#example-icon-report',
        on: 'left'
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 5,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Descarregar exame/fatura/enviar email',
      text: 'Neste botão pode descarregar o exame caso o mesmo já tenha sido concluído.'
    },
    {
      attachTo: {
        element: '#example-icon-invoice',
        on: 'left'
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 5,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Descarregar exame/fatura/enviar email',
      text: 'Caso o exame tenha uma fatura associada, poderá consulta a mesma aqui.'
    },
    {
      attachTo: {
        element: '#example-icon-email',
        on: 'left'
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 5,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Descarregar exame/fatura/enviar email',
      text: 'Caso queira notificar o paciente de que o exame já está pronto, pode enviar-lhe por email uma notificação para que o mesmo possa consultar o seu exame diretamente no nosso portal.'
    },
    {
      attachTo: {
        element: '#example-icon-delete',
        on: 'left'
      },
      canClickTarget: false,
      modalOverlayOpeningPadding: 5,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.next],
      title: 'Descarregar exame/fatura/enviar email',
      text: 'Pode também eliminar o exame.'
    },
    {
      canClickTarget: false,
      modalOverlayOpeningPadding: 5,
      buttons: [this.STEPS_BUTTONS.back, this.STEPS_BUTTONS.complete],
      title: 'Descarregar exame/fatura/enviar email',
      text: 'Pode sempre voltar a consultar este guia clicando no ponto de interrogação na barra superior do site.',
      when: {
        show: () => {
          const testRow = document.getElementById('example-row')

          if (testRow) {
            testRow.remove()
          }
        }
      }
    }
  ]
}
