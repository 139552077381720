import { Component, Input, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss']
})
export class GenericModalComponent implements OnInit {
  @Input() closeButton: boolean = true
  @Input() title!: string
  @Input() body!: string
  @Input() cancelButtonLabel: string = 'cancel'
  @Input() confirmButtonLabel: string = 'confirm'

  constructor(public modalService: NgbActiveModal) {}

  ngOnInit(): void {}
}
