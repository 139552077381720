import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { firstValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ApiResponse } from '../models/apiResponse'
import { Organization } from '../models/organization'
import { createHttpBody } from '../utils/http-body'
import { createHttpParams } from '../utils/http-params'
import { SocketInterceptorService } from './socket-interceptor.service'

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  private url = `${environment.url}/organizations/` //server

  constructor(
    private http: HttpClient,
    private socketInterceptorService: SocketInterceptorService
  ) { }

  getOrganizations(pageNumber: number, pageSize: number): Promise<ApiResponse<Organization>> {
    let httpParams = new HttpParams()
    httpParams = httpParams.append('page', pageNumber)
    httpParams = httpParams.append('size', pageSize)
    httpParams = httpParams.append('name', 'ASC')

    return firstValueFrom(
      this.http.get<ApiResponse<Organization>>(`${this.url}`, { params: httpParams })
    )
  }

  getOrganizationById(id: string): Promise<Organization> {
    return firstValueFrom(this.http.get<Organization>(`${this.url}${id}`))
  }

  getOrganizationByIdViaSockets(orgId: number): Promise<Organization> {
    return this.socketInterceptorService.makeGetRequest('organizations', orgId)
  }

  addOrganization(
    name: string,
    email: string,
    taxNumber: string,
    contact_id: number,
    code: string,
    organization_id: number,
    template_id: number,
    picture_id?: number
  ): Promise<Organization> {
    const httpBody = createHttpBody({
      name: name,
      email: email,
      taxNumber: taxNumber,
      organization_id: organization_id,
      contact_id: contact_id,
      code: code,
      picture_id: picture_id,
      template_id: template_id
    })
    return firstValueFrom(this.http.post<Organization>(`${this.url}`, httpBody))
  }

  getChildOrganizations(organization_id: number): Promise<ApiResponse<Organization>> {
    const httpParams = createHttpParams({
      organization_id: organization_id
    })
    return firstValueFrom(
      this.http.get<ApiResponse<Organization>>(this.url, { params: httpParams })
    )
  }

  searchOrganizations(
    value: string,
    associatedAttributes?: number
  ): Promise<ApiResponse<Organization>> {
    const httpParams = createHttpParams({
      name: value,
      taxNumber: value,
      associatedAttributes: associatedAttributes
    })
    return firstValueFrom(
      this.http.get<ApiResponse<Organization>>(this.url, { params: httpParams })
    )
  }

  updateOrganizationParentOrg(orgId: number, parentOrgId: number | null): Promise<Organization> {
    return firstValueFrom(
      this.http.put<Organization>(`${this.url}${orgId}`, { organization_id: parentOrgId })
    )
  }

  updateOrganization(
    orgId: number,
    name: string,
    email: string,
    taxNumber: string,
    transferExams: boolean,
    blockTimeslots: boolean,
    template_id?: number,
    picture_id?: number,
    appointment_types?: number
  ): Promise<Organization> {
    let httpBody = createHttpBody({
      name: name,
      email: email,
      taxNumber: taxNumber,
      transfer_exams: transferExams,
      block_timeslots: blockTimeslots,
      template_id: template_id,
      picture_id: picture_id,
      appointment_types: appointment_types
    })
    return firstValueFrom(this.http.put<Organization>(`${this.url}${orgId}`, httpBody))
  }
}
