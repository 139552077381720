import { Pipe, PipeTransform } from '@angular/core'
import { ExamTypes } from '../utils/examTypes'

@Pipe({
  name: 'examType'
})
export class ExamTypePipe implements PipeTransform {
  transform(value: number): string {
    if (value == ExamTypes.ECG) {
      return 'ECG'
    } else if (value == ExamTypes.MAPA24) {
      return 'MAPA24'
    } else if (value == ExamTypes.MAPA48) {
      return 'MAPA48'
    } else if (value == ExamTypes.HOLTER24) {
      return 'HOLTER24'
    } else if (value == ExamTypes.HOLTER48) {
      return 'HOLTER48'
    } else if (value == ExamTypes.ECO) {
      return 'ECO'
    } else if (value == ExamTypes.ECODOPPLER) {
      return 'ECODOPPLER'
    } else if (value == ExamTypes.PROVA) {
      return 'PROVA'
    }

    return ''
  }
}
