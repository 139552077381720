import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  ViewContainerRef,
  Type,
  ChangeDetectorRef,
  AfterViewChecked
} from '@angular/core'
import { SidebarLoaderService } from '../services/sidebar-loader.service'
import { UserSettingsSidebarComponent } from '../shared/sidebars/user-settings-sidebar/user-settings-sidebar.component'
import { ExamInfoSidebarComponent } from '../shared/sidebars/exam-info-sidebar/exam-info-sidebar.component'
import { ExamReportSidebarComponent } from '../custom-views/exams/reusable-components/exam-report-sidebar/exam-report-sidebar.component'
import { RecentTicketsSidebarComponent } from '../shared/sidebars/recent-tickets-sidebar/recent-tickets-sidebar.component'
import { AssociatedStoresSidebarComponent } from '../shared/sidebars/associated-stores-sidebar/associated-stores-sidebar.component'
import { InvoiceInfoSidebarComponent } from '../shared/sidebars/invoice-info-sidebar/invoice-info-sidebar.component'
import { ComplaintsBookInfoSidebarComponent } from '../shared/sidebars/complaints-book-info-sidebar/complaints-book-info-sidebar.component'
import { StoreInfoSidebarComponent } from '../shared/sidebars/store-info-sidebar/store-info-sidebar.component'
import { MaterialInfoSidebarComponent } from '../shared/sidebars/material-info-sidebar/material-info-sidebar.component'
import { PatientInfoSidebarComponent } from '../shared/sidebars/patient-info-sidebar/patient-info-sidebar.component'
import { PersonInfoSidebarComponent } from '../shared/sidebars/person-info-sidebar/person-info-sidebar.component'
import { CodeInfoSidebarComponent } from '../shared/sidebars/code-info-sidebar/code-info-sidebar.component'
import { Observable } from 'rxjs'
import { LoaderService } from '../services/loader.service'
import { Exam } from '../models/exam'
import { Issue } from '../models/issue'
import { SimplifiedTicketSidebarComponent } from '../shared/sidebars/simplified-ticket-sidebar/simplified-ticket-sidebar.component'
import { Account } from '../models/account'
import { Person } from '../models/person'
import { Store } from '../models/store'
import { ComplaintBookLog } from '../models/complaint-book-log'
import { Code } from '../models/code'
import { MaterialLog } from '../models/material-log'
import { MaterialList } from '../custom-views/management/material/material.component'
import { NotificationsSidebarComponent } from '../shared/sidebars/notifications-sidebar/notifications-sidebar.component'
import { AuthService } from '../services/auth.service'
import { WarningInfoSidebarComponent } from '../shared/sidebars/warning-info-sidebar/warning-info-sidebar.component'

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit, AfterViewChecked {
  isLoading: Observable<boolean> = this.loaderService.isLoading
  isLoadingBar: Observable<boolean> = this.loaderService.isLoadingBar
  userAgent = navigator.userAgent.toLowerCase()

  constructor(
    private sidebarLoaderService: SidebarLoaderService,
    private loaderService: LoaderService,
    private changeDetector: ChangeDetectorRef,
    private authService: AuthService
  ) {
    this.checkIfMobile()
  }

  @ViewChild('container', { read: ViewContainerRef })
  sidebarContainer!: ViewContainerRef

  // right side sidebar
  showDetailsSidebar = false

  // left side sidebar
  sidebarCollapsed!: boolean
  mobileView!: boolean

  ngAfterViewChecked() {
    this.changeDetector.detectChanges()
  }

  checkIfMobile() {
    this.mobileView =
      /iphone|ipod|ipad|android|blackberry|windows phone|webos|iemobile|opera mini/i.test(
        this.userAgent
      )
    this.authService.isUserMobile = this.mobileView
  }

  ngOnInit(): void {
    // subscribe to right side sidebar visibility
    this.sidebarLoaderService.sidebarVisibility.subscribe(res => {
      if (res == true) {
        if (window.innerWidth < 990) {
          this.sidebarCollapsed = true
        }
      }
      this.showDetailsSidebar = res
    })

    // subscribe to showing notifications sidebar
    this.sidebarLoaderService.showNotificationsSidebar.subscribe(res => {
      this.showNotificationsSidebar()
    })

    // subscribe to showing warnings sidebar
    this.sidebarLoaderService.showWarningsSidebar.subscribe(res => {
      this.showWarningsSidebar()
    })

    //subscribe to showing user settings sidebar
    this.sidebarLoaderService.showUserSettingsSidebar.subscribe(res => {
      this.showUserSettingsSidebar()
    })

    //subscribe to showing exam info sidebar
    this.sidebarLoaderService.showExamInfoSidebar.subscribe(res => {
      // console.log('container ref: ', this.sidebarContainer)
      this.showExamInfoSidebar(res)
    })

    //subscribe to showing exam report sidebar
    this.sidebarLoaderService.showExamReportSidebar.subscribe(
      (res: { examInfo: Exam; patientInfo: Person }) => {
        this.showExamReportSidebar(res.examInfo, res.patientInfo)
      }
    )

    //subscribe to showing simplified ticket information sidebar
    this.sidebarLoaderService.showSimplifiedTicketInfoSidebar.subscribe(res => {
      this.showSimplifiedTicketInfoSidebar(res)
    })

    //subscribe to showing recent tickets sidebar
    this.sidebarLoaderService.showRecentTicketsSidebar.subscribe(res => {
      this.showRecentTicketsSidebar(res)
    })

    //subscribe to showing comapany associated stores sidebar
    this.sidebarLoaderService.showAssociatedStoresSidebar.subscribe(res => {
      this.showAssociatedStoresSidebar(res)
    })

    //subscribe to showing invoice info sidebar
    this.sidebarLoaderService.showInvoiceInfoSidebar.subscribe(res => {
      this.showInvoiceInfoSidebar(res)
    })

    //subscribe to showing complaints book info sidebar
    this.sidebarLoaderService.showComplaintsBooksInfoSidebar.subscribe(
      (res: { currentLog: ComplaintBookLog; historyLog: ComplaintBookLog[]; number: string }) => {
        this.showComplaintsBookInfoSidebar(res.currentLog, res.historyLog, res.number)
      }
    )

    //subscribe to showing store info sidebar
    this.sidebarLoaderService.showStoreInfoSidebar.subscribe(res => {
      this.showStoreInfoSidebar(res)
    })

    //subscribe to showing material info sidebar
    this.sidebarLoaderService.showMaterialInfoSidebar.subscribe(
      (res: { currentLog: MaterialList; historyLog: MaterialLog[] }) => {
        this.showMaterialInfoSidebar(res.currentLog, res.historyLog)
      }
    )

    //subscribe to showing patient info sidebar
    this.sidebarLoaderService.showPatientInfoSidebar.subscribe(res => {
      this.showPatientInfoSidebar(res)
    })

    //subscribe to showing person info sidebar
    this.sidebarLoaderService.showPersonInfoSidebar.subscribe(res => {
      this.showPersonInfoSidebar(res)
    })

    //subscribe to showing code info sidebar
    this.sidebarLoaderService.showCodeInfoSidebar.subscribe(res => {
      this.showCodeInfoSidebar(res)
    })
  }

  sidebarCollapseEvent(sidebarCollapsed: boolean) {
    this.sidebarCollapsed = sidebarCollapsed
  }

  // @HostListener("window:resize", ["$event"])
  // onWindowResize() {
  //   this.checkIfMobile();

  //   if (window.innerWidth < 990 && this.showDetailsSidebar) {
  //     this.sidebarCollapsed = true;
  //   }
  // }

  showNotificationsSidebar() {
    this.sidebarContainer.clear()
    const componentRef = this.sidebarContainer.createComponent(NotificationsSidebarComponent)
  }

  showWarningsSidebar() {
    this.sidebarContainer.clear()
    const componentRef = this.sidebarContainer.createComponent(WarningInfoSidebarComponent)
  }

  showUserSettingsSidebar() {
    this.sidebarContainer.clear()
    const componentRef = this.sidebarContainer.createComponent(UserSettingsSidebarComponent)
  }

  showExamInfoSidebar(examInfo: Exam) {
    this.sidebarContainer.clear()

    const componentRef = this.sidebarContainer.createComponent(ExamInfoSidebarComponent)
    componentRef.instance.examInfo = examInfo
  }

  showExamReportSidebar(examInfo: Exam, patientInfo: Person) {
    this.sidebarContainer.clear()

    const componentRef = this.sidebarContainer.createComponent(ExamReportSidebarComponent)
    componentRef.instance.examInfo = examInfo
    componentRef.instance.patientInfo = patientInfo
  }

  showSimplifiedTicketInfoSidebar(ticketInfo: Issue) {
    this.sidebarContainer.clear()

    const componentRef = this.sidebarContainer.createComponent(SimplifiedTicketSidebarComponent)
    componentRef.instance.ticketInfo = ticketInfo
  }

  showRecentTicketsSidebar(tickets: any) {
    this.sidebarContainer.clear()

    const componentRef = this.sidebarContainer.createComponent(RecentTicketsSidebarComponent)
    // componentRef.instance.recentTickets = tickets;
  }

  showAssociatedStoresSidebar(stores: Store[]) {
    this.sidebarContainer.clear()

    const componentRef = this.sidebarContainer.createComponent(AssociatedStoresSidebarComponent)
    componentRef.instance.stores = stores
  }

  showInvoiceInfoSidebar(invoiceInfo: any) {
    this.sidebarContainer.clear()

    const componentRef = this.sidebarContainer.createComponent(InvoiceInfoSidebarComponent)
    componentRef.instance.invoiceInfo = invoiceInfo
  }

  showComplaintsBookInfoSidebar(
    currentLog: ComplaintBookLog,
    historyLog: ComplaintBookLog[],
    number: string
  ) {
    this.sidebarContainer.clear()
    const componentRef = this.sidebarContainer.createComponent(ComplaintsBookInfoSidebarComponent)
    componentRef.instance.currentLog = currentLog
    componentRef.instance.historyLog = historyLog
    componentRef.instance.complaintBookNumber = number
  }

  showStoreInfoSidebar(storeInfo: Store) {
    this.sidebarContainer.clear()

    const componentRef = this.sidebarContainer.createComponent(StoreInfoSidebarComponent)
    componentRef.instance.storeInfo = storeInfo
  }

  showMaterialInfoSidebar(currentLog: MaterialList, historyLog: MaterialLog[]) {
    this.sidebarContainer.clear()
    const componentRef = this.sidebarContainer.createComponent(MaterialInfoSidebarComponent)
    componentRef.instance.currentLog = currentLog
    // console.log("history log: ", historyLog);

    componentRef.instance.historyLog = historyLog
  }

  showPatientInfoSidebar(patientInfo: Person) {
    this.sidebarContainer.clear()

    const componentRef = this.sidebarContainer.createComponent(PatientInfoSidebarComponent)
    componentRef.instance.patientInfo = patientInfo
  }

  showPersonInfoSidebar(personInfo: Account) {
    this.sidebarContainer.clear()

    const componentRef = this.sidebarContainer.createComponent(PersonInfoSidebarComponent)
    componentRef.instance.accountInfo = personInfo
  }

  showCodeInfoSidebar(codeInfo: Code) {
    this.sidebarContainer.clear()
    const componentRef = this.sidebarContainer.createComponent(CodeInfoSidebarComponent)
    componentRef.instance.codeInfo = codeInfo
  }
}
