<div class="fontLight12 tab-header">
  <div class="item" [class.active]="activeTab == 1" (click)="changeTab(1)">
    <a>{{ 'exams' | translate }}</a>
  </div>
  <div class="item" [class.active]="activeTab == 2" (click)="changeTab(2)">
    <a>{{ 'information' | translate }}</a>
  </div>
  <div class="item" [class.active]="activeTab == 3" (click)="changeTab(3)">
    <a>{{ 'suggestions' | translate }}</a>
  </div>
</div>

<div class="main-content-sidebar">
  <div class="main-content">
    <!-- EXAMS TAB -->
    <ng-container *ngIf="activeTab == 1">
      <div class="header">
        <span class="title">{{ 'exams' | translate }}</span>
        <img
          class="close-button"
          src="../../../../assets/custom-icons/dark_close_icon.svg"
          (click)="closeSidebar()"
        />
      </div>
      <div *ngFor="let exam of exams">
        <div *ngIf="exam.reports.length > 0" class="previous-exam">
          <div class="top">
            <span class="fontSemiBold10 fontColorPrimary">Exame nº {{ exam.id }}</span>
            <span class="fontRegular10 fontColorMediumDarkGray">{{
              exam.exam_type_id | examType
            }}</span>
          </div>
          <div class="bottom">
            <div class="left">
              <span class="fontRegular10 fontColorMediumLightGray">Estado</span>
              <span class="fontRegular10 fontColorMediumDarkGray">{{
                exam.reports[exam.reports.length - 1].status_id | reportState
              }}</span>
            </div>
            <img
              *ngIf="exam.reports[exam.reports.length - 1].status_id == 4"
              class="preview-pdf"
              src="../../../../../assets/custom-icons/exam_report_sidebar_file.svg"
              (click)="previewReport(exam)"
            />
          </div>
        </div>
      </div>
    </ng-container>

    <!-- INFORMATIONS TAB -->
    <ng-container *ngIf="activeTab == 2">
      <div class="header">
        <span class="title">{{ 'information' | translate }}</span>
        <img
          class="close-button"
          src="../../../../assets/custom-icons/dark_close_icon.svg"
          (click)="closeSidebar()"
        />
      </div>
      <div class="rows">
        <div class="info-single-row">
          <div class="rounded-white-background15 item">
            <span class="fontRegular10 fontColorMediumDarkGray">{{ 'exam_nr' | translate }}</span>
            <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
              examInfo.id
            }}</span>
          </div>

          <div class="rounded-white-background15 item">
            <span class="fontRegular10 fontColorMediumDarkGray">{{ 'date' | translate }}</span>
            <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
              examInfo.exam_date | date : 'dd-MM-yyyy'
            }}</span>
          </div>
        </div>
        <div class="info-single-row">
          <div class="rounded-white-background15 item">
            <span class="fontRegular10 fontColorMediumDarkGray">{{ 'hour' | translate }}</span>
            <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
              examInfo.exam_date | date : 'H:mm'
            }}</span>
          </div>

          <div class="rounded-white-background15 item">
            <span class="fontRegular10 fontColorMediumDarkGray">{{ 'gender' | translate }}</span>
            <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
              examInfo.patient.gender | gender
            }}</span>
          </div>
        </div>
        <div class="info-single-row">
          <div class="rounded-white-background15 item">
            <span class="fontRegular10 fontColorMediumDarkGray">{{ 'birthdate' | translate }}</span>
            <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
              examInfo.patient.dateOfBirth | date : 'dd-MM-yyyy'
            }}</span>
          </div>

          <div class="rounded-white-background15 item">
            <span class="fontRegular10 fontColorMediumDarkGray">{{ 'age' | translate }}</span>
            <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
              examInfo.patient.dateOfBirth | age
            }}</span>
          </div>
        </div>
        <div class="info-single-row">
          <div class="rounded-white-background15 item">
            <span class="fontRegular10 fontColorMediumDarkGray">{{ 'height' | translate }}</span>
            <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
              examInfo.patient.height
            }}</span>
          </div>

          <div class="rounded-white-background15 item">
            <span class="fontRegular10 fontColorMediumDarkGray">{{ 'weight' | translate }}</span>
            <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
              examInfo.patient.weight
            }}</span>
          </div>
        </div>

        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ 'medication' | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
            examMedication
          }}</span>
        </div>

        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ 'symptoms' | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
            examSymptoms
          }}</span>
        </div>

        <div class="rounded-white-background15 item" *ngIf="examNotes">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ 'notes' | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
            examNotes
          }}</span>
        </div>
      </div>
    </ng-container>

    <!-- SUGESTIONS TAB -->
    <ng-container *ngIf="activeTab == 3">
      <div class="header">
        <span class="title">{{ 'suggestions' | translate }}</span>
        <img
          class="close-button"
          src="../../../../assets/custom-icons/dark_close_icon.svg"
          (click)="closeSidebar()"
        />
      </div>
      <p>sugestões body.</p>
    </ng-container>
  </div>
</div>
