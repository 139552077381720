import { Component, Input, OnInit, OnDestroy } from '@angular/core'
import { SidebarLoaderService } from '../../../services/sidebar-loader.service'
import { Subscription } from 'rxjs'
import { GenericRealTimeService } from 'src/app/services/generic-real-time.service'
import { AuthService } from 'src/app/services/auth.service'
import { HeaderInfoWarning } from 'src/app/models/header-info-warning'
import { Router } from '@angular/router'
import { ExamRequestGroup } from 'src/app/models/exam-request-group'
import { GeneratePdfService } from 'src/app/services/generate-pdf.service'
import { ExamRequestService } from 'src/app/services/exam-request.service'
import { RequestExamsGroupState } from '../../../utils/requestExamsGroupState';

@Component({
  selector: 'app-warning-info-sidebar',
  templateUrl: './warning-info-sidebar.component.html',
  styleUrls: ['./warning-info-sidebar.component.scss']
})
export class WarningInfoSidebarComponent implements OnInit, OnDestroy {
  warningInfoSubs!: Subscription
  warningInfoArray: HeaderInfoWarning[] = []

  constructor(
    private sidebarLoaderService: SidebarLoaderService,
    public genericRealTimeService: GenericRealTimeService,
    private router: Router
  ) { }

  ngOnInit() {
    this.initSubs()
  }

  initSubs() {
    this.warningInfoSubs = this.genericRealTimeService.warningInfo.asObservable().subscribe({
      next: res => {
        this.warningInfoArray = res
      },
      error: err => { }
    })
  }


  clickLink(warning: HeaderInfoWarning) {
    if (warning.link) {
      // remove warning from array
      const currentWarningInfos = this.genericRealTimeService.warningInfo.getValue()
      const newWarningInfos = currentWarningInfos.filter(w => w.link != warning.link)
      this.genericRealTimeService.warningInfo.next(newWarningInfos)
      this.router.navigateByUrl(warning.link)
    }
  }

  ngOnDestroy(): void {
    if (this.warningInfoSubs) {
      this.warningInfoSubs.unsubscribe()
    }
  }

  onRemoveExamGroup(examReqGroupId: number) {
    const foundExamReqGroup = this.warningInfoArray.findIndex(w => w.examRequestGroupId === examReqGroupId)

    if (foundExamReqGroup > -1) {
      this.warningInfoArray.splice(foundExamReqGroup, 1)
      this.genericRealTimeService.warningInfo.next(this.warningInfoArray)
    }
  }

  onUpdateExamGroupInfo(newReqGroupInfo: ExamRequestGroup) {
    const foundExamReqGroup = this.warningInfoArray.findIndex(w => w.examRequestGroupId === newReqGroupInfo.id)

    if (foundExamReqGroup > -1) {
      this.warningInfoArray[foundExamReqGroup].examRequestGroup = newReqGroupInfo
      this.genericRealTimeService.warningInfo.next(this.warningInfoArray)
    }
  }

  closeSidebar() {
    this.sidebarLoaderService.closeSidebar()
  }
}
