import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Observable } from 'rxjs'
import { LoaderService } from '../services/loader.service'
import { finalize } from 'rxjs/operators'
import { log } from 'console'

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  loadingBarUrls = ['authentication']
  noLoadingUrls = ['notifications', 'appointment-has-document', 'appointment-documents']

  constructor(private loaderService: LoaderService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.loadingBarUrls.some(str => request.url.includes(str)) && request.method == 'GET') {
      this.loaderService.showLoadingBar()
      // don't show loading bar if
    } else if (this.noLoadingUrls.some(str => request.url.includes(str))) {
    } else {
      this.loaderService.show()
    }

    return next.handle(request).pipe(finalize(() => this.loaderService.hide()))
  }
}
