<div class="modal-header">
  <span>Pré-Visualização PDF</span>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="modalService.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body">
  <pdf-viewer
    [src]="source"
    [render-text]="false"
    [original-size]="false"
    [autoresize]="false"
    [fit-to-page]="true"
    style="width: 100%; height: 100%; display: block"
  ></pdf-viewer>
</div>
