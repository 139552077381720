<div class="main-content-sidebar">
  <div class="main-content">
    <div class="header">
      <span class="title">{{ "associated_stores" | translate }}</span>
      <img class="close-button" src="../../../../assets/custom-icons/dark_close_icon.svg" (click)="closeSidebar()" />
    </div>
    <div *ngFor="let store of stores" class="store-card">
      <span class="fontRegular10 fontColorPrimary">{{ store.name }}</span>
      <div style="display: flex; gap: 3px">
        <span class="fontColorMediumDarkGray fontRegular10">{{ store.contact.phone }}</span>
        <span class="fontColorMediumDarkGray fontRegular10">|</span>
        <span class="fontColorMediumDarkGray fontRegular10">{{ store.state | storeState | translate }}</span>
      </div>
    </div>
  </div>
</div>
