import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "examRequestGroupState",
})
export class ExamRequestGroupStatePipe implements PipeTransform {
  transform(examGroupReqState?: number): string {
    if (examGroupReqState == 1) {
      return "Por imprimir";
    } else if (examGroupReqState == 2) {
      return "Imprimir de novo";
    }
    return "";
  }
}
