import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { HeaderInfoWarning } from '../models/header-info-warning'
import { ExamRequest } from '../models/exam-request'
import { ExamRequestGroup } from '../models/exam-request-group'

@Injectable({
  providedIn: 'root'
})
export class GenericRealTimeService {
  constructor() { }

  newNotificationsCount = new BehaviorSubject<number>(0)
  examsToRepeatCountSub = new BehaviorSubject<number>(0)
  newTicketsCount = new BehaviorSubject<number>(0)
  warningInfo = new BehaviorSubject<HeaderInfoWarning[]>([])

  emitNewExamToRepeat(count: number) {
    this.examsToRepeatCountSub.next(count)
  }

  examRequestInfoUpdated(updatedRequestInfo: ExamRequest) {
    const currentWarnings = this.warningInfo.getValue()
    const foundReqGroup = currentWarnings
      .find(warning => warning.examRequestGroupId == updatedRequestInfo.exam_request_group_id)?.examRequestGroup

    if (foundReqGroup) {
      // find exam request within the group
      const foundReq = foundReqGroup.exam_requests
        .find(req => req.id == updatedRequestInfo.id)

      if (foundReq) {
        Object.assign(foundReq, updatedRequestInfo)

        this.warningInfo.next(currentWarnings)
      }
    }
  }

  examRequestGroupUpdated(updatedRequestGroup: ExamRequestGroup) {
    const currentWarnings = this.warningInfo.getValue()
    const foundReqGroup = currentWarnings
      .find(warning => warning.examRequestGroupId == updatedRequestGroup.id)?.examRequestGroup

    if (foundReqGroup) {
      // if updatedRequestGroup state is === 3, remove it from the list. else, update it
      if (updatedRequestGroup.state == 3) {
        const index = currentWarnings.findIndex(warning => warning.examRequestGroupId == updatedRequestGroup.id)
        currentWarnings.splice(index, 1)
      } else {
        Object.assign(foundReqGroup, updatedRequestGroup)
      }

      this.warningInfo.next(currentWarnings)
    }
  }
}
