export enum ExamTypes {
  ECG = 1,
  MAPA24 = 2,
  MAPA48 = 3,
  HOLTER24 = 4,
  HOLTER48 = 5,
  ECO = 6,
  ECODOPPLER = 7,
  PROVA = 8
}
