import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { AuthService } from './services/auth.service'
import { ConnectionService } from './services/connection.service'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { UserOfflineModalComponent } from './shared/modals/user-offline-modal/user-offline-modal.component'
import { FeathersjsService } from './services/feathersjs.service'
import { NavigationEnd, Router } from '@angular/router'
import { Subscription, filter, map } from 'rxjs'
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker'
import { environment } from '../environments/environment'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, OnInit {
  title = 'frontend-portal2'
  modalRef!: NgbModalRef

  ngOnDestroy(): void {
    if (this.modalRef) {
      this.modalRef.dismiss()
    }
  }

  constructor(
    private translateService: TranslateService,
    private authService: AuthService,
    private connectionService: ConnectionService,
    private modalService: NgbModal,
    private feathersService: FeathersjsService,
    private router: Router,
    private readonly swUpdate: SwUpdate,
    private zone: NgZone
  ) {
    // Check if the browser supports notifications
    if (!('Notification' in window)) {
      console.log('This browser does not support notifications.')
    } else {
      // Request permission to display notifications
      Notification.requestPermission().then(permission => { })
    }

    //set default language to localstorage preference
    this.translateService.setDefaultLang('pt')
    const lang = localStorage.getItem('defaultLang')
    if (lang) {
      const langObj = JSON.parse(lang)
      this.translateService.use(langObj.prefix)
    } else {
      this.translateService.use('pt')
    }

    // init listening to connection
    this.connectionService
      .getConnectionStatus()
      .asObservable()
      .subscribe(res => {
        if (!res) {
          if (!this.modalRef) {
            this.openOfflineModal()
          }
        } else {
          try {
            this.modalRef.componentInstance.dismissModal()
          } catch (error) { }
        }
      })

    const currentPageSize = localStorage.getItem('pagination_page_size')

    if (!currentPageSize) {
      localStorage.setItem('pagination_page_size', '40')
    }

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (event.url && event.url.includes('dashboard')) {
          this.authService.updateAccountScreenState(event.url)
        }
      })
  }

  ngOnInit(): void {

    this.feathersService.initFeathersSocketConnection()
      .then((accountData) => {
        // console.log('accountData: ', accountData);
        this.authService.initData(accountData)
      })
      .catch((error) => {
        console.error('error on init feathers socket connection', error)
        this.authService.logout()
      })


    if (!environment.production) {
      return
    }

    const tenMinutes = 1000 * 60 * 10
    this.zone.runOutsideAngular(() => {
      setInterval(() => {
        this.swUpdate.checkForUpdate()
      }, tenMinutes)
    })

    this.swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map((evt: VersionReadyEvent) => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion
        }))
      )
      .subscribe(async event => {
        // ask if user wants to update with the new version with window.confirm
        const result = window.confirm(
          `Atenção: Existe uma nova versão do Portal 2!
          Para garantir o seu funcionamento ideal, por favor, faça a atualização o mais breve possível.
          Deseja atualizar agora?`
        )

        if (!result) {
          return
        }

        // trigger the update - this will download all changed files
        await this.swUpdate.activateUpdate()
        // refresh the page so that the new files become active
        document.location.reload()
      })
  }

  openOfflineModal() {
    this.modalRef = this.modalService.open(UserOfflineModalComponent, {
      backdrop: 'static',
      centered: true,
      size: 'md',
      scrollable: true
    })
  }
}