import { AbstractControl, ValidatorFn } from '@angular/forms'

export class CustomValidators {
  confirmPassword(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const password = control.get('password')?.value
      const confirmPassword = control.get('confirmPassword')?.value
      if (password != confirmPassword) {
        return { confirmPasswordError: true }
      }
      return null
    }
  }

  minAgeValidator(minAge: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const selected = new Date(control.value)
      const today = new Date()
      const diffInYears = (today.getTime() - selected.getTime()) / (1000 * 3600 * 24 * 365)

      return diffInYears >= minAge ? null : { minAge: true }
    }
  }

  ageRangeValidator(minAge: number, maxAge: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const selectedDate = new Date(control.value)
      const today = new Date()
      const ageInYears = (today.getTime() - selectedDate.getTime()) / (1000 * 3600 * 24 * 365)

      if (ageInYears >= minAge && ageInYears <= maxAge) {
        return null // Valid age range
      } else {
        return { ageRange: true } // Age outside the specified range
      }
    }
  }

  validateNIF(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const nif = control.get('patientTaxNumber')?.value as string

      if (!nif) {
        return null
      }

      if (nif.length == 0) {
        return null
      }

      if (
        !['1', '2', '3', '5', '6', '8'].includes(nif.slice(0, 1)) &&
        !['45', '70', '71', '72', '77', '79', '90', '91', '98', '99'].includes(nif.slice(0, 2))
      ) {
        return { invalidNif: true }
      }

      let total =
        Number(nif[0]) * 9 +
        Number(nif[1]) * 8 +
        Number(nif[2]) * 7 +
        Number(nif[3]) * 6 +
        Number(nif[4]) * 5 +
        Number(nif[5]) * 4 +
        Number(nif[6]) * 3 +
        Number(nif[7]) * 2

      let modulo11 = total - Math.floor(total / 11) * 11
      let comparador = modulo11 == 1 || modulo11 == 0 ? 0 : 11 - modulo11

      return Number(nif[8]) == comparador ? null : { invalidNif: true }
    }
  }
}
