import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { firstValueFrom } from 'rxjs'
import { BlobServiceClient, BlockBlobClient } from '@azure/storage-blob'
import { DocumentService } from './document.service'
import { AuthService } from './auth.service'
import { CommonUtils } from '../utils/commonUtils'
import { environment } from 'src/environments/environment'
import { LoaderService } from './loader.service'
import { TeleheartAuthService } from './teleheart-auth.service'
import { GenerateTokenBody } from '../models/generateTokenBody'

@Injectable({
  providedIn: 'root'
})
export class AzureService {
  private url = `${environment.url}/files/generate_azure_token/` //server
  private containerGroup = 'portal2'
  private azureToken!: string
  private azureTokenIssueDate!: Date

  constructor(
    private http: HttpClient,
    private documentService: DocumentService,
    private authService: AuthService,
    private loaderService: LoaderService,
    private teleheartAuthService: TeleheartAuthService
  ) {}

  async getAzureToken(): Promise<string> {
    if (this.azureTokenIssueDate && this.azureToken) {
      const diffInMinutes = Math.round(
        (new Date().getTime() - this.azureTokenIssueDate.getTime()) / (1000 * 60)
      )

      if (diffInMinutes < 5) {
        return await Promise.resolve(this.azureToken)
      }
    }

    await this.generateAzureToken().then(async res => {
      const token = res.result.token
      this.azureToken = token

      this.azureTokenIssueDate = new Date()
    })

    return await Promise.resolve(this.azureToken)
  }

  async patientGetAzureToken(): Promise<string> {
    if (this.azureTokenIssueDate && this.azureToken) {
      const diffInMinutes = Math.round(
        (new Date().getTime() - this.azureTokenIssueDate.getTime()) / (1000 * 60)
      )

      if (diffInMinutes < 5) {
        return await Promise.resolve(this.azureToken)
      }
    }

    await this.teleheartAuthService.patientGenerateAzureToken().then(async res => {
      const token = res.result.token
      this.azureToken = token

      this.azureTokenIssueDate = new Date()
    })

    return await Promise.resolve(this.azureToken)
  }

  async pause() {
    await new Promise(resolve => setTimeout(resolve, 1000))
  }

  generateAzureToken(): Promise<GenerateTokenBody> {
    return firstValueFrom(this.http.get<GenerateTokenBody>(this.url))
  }

  getAzureBlobServiceClient(SASToken: string): BlobServiceClient {
    const blobServiceClient = new BlobServiceClient(
      `https://machadolemosb1.blob.core.windows.net${SASToken}`
    )
    return blobServiceClient
  }

  async uploadToAzure(
    SASToken: string,
    filename: string,
    content: ArrayBuffer,
    showLoader?: boolean
  ): Promise<any | undefined> {
    if (showLoader) {
      this.loaderService.show()
    }

    const blobServiceClient = this.getAzureBlobServiceClient(SASToken)
    const date = new Date().toISOString().split('T')[0]
    const dateLong = new Date().getTime()
    const containerName = this.containerGroup
    let containerGroupClient = await blobServiceClient.getContainerClient(containerName)

    const filePath = `${date}\\${dateLong}_${filename}`
    let documentTypeId

    const fileExtension = (filename.match(/\.([^.]*?)(?=\?|#|$)/) || [])[1]
    const documentTypes = CommonUtils.getDocumentTypes()

    if (documentTypes) {
      for (let i in documentTypes) {
        if (fileExtension.toLowerCase() == documentTypes[i].extension) {
          documentTypeId = documentTypes[i].id
          break
        }
      }
    }

    // if no document type is found, exit function
    if (!documentTypeId) {
      return undefined
    }

    if (!(await containerGroupClient.exists())) {
      containerGroupClient = (await blobServiceClient.createContainer(containerName))
        .containerClient
    }

    const blockBlobClient = containerGroupClient.getBlockBlobClient(filePath)

    // Use the onProgress callback to track the upload progress
    const uploadBlobResponse = await blockBlobClient.upload(content, content.byteLength)

    const newDocument = await this.documentService.createDocumentViaSockets(
      filePath,
      content.byteLength,
      documentTypeId ? documentTypeId : 4,
      this.authService.getLoggedInUserId()
    )

    // this.loaderService.hide()

    return {
      ...uploadBlobResponse,
      size: content.byteLength,
      filename: filename,
      path: filePath,
      documentId: newDocument.id
    }
  }

  async patientUploadToAzure(
    SASToken: string,
    filename: string,
    content: ArrayBuffer,
    onProgressCallback?: (progress: number) => void,
    showLoader?: boolean
  ): Promise<any | undefined> {
    if (showLoader) {
      this.loaderService.show()
    }

    const blobServiceClient = this.getAzureBlobServiceClient(SASToken)
    const date = new Date().toISOString().split('T')[0]
    const dateLong = new Date().getTime()
    const containerName = this.containerGroup
    let containerGroupClient = await blobServiceClient.getContainerClient(containerName)

    const filePath = `${date}\\${dateLong}_${filename}`
    let documentTypeId

    const fileExtension = (filename.match(/\.([^.]*?)(?=\?|#|$)/) || [])[1]
    const documentTypes = CommonUtils.getDocumentTypes()

    if (documentTypes) {
      for (let i in documentTypes) {
        if (fileExtension.toLowerCase() == documentTypes[i].extension) {
          documentTypeId = documentTypes[i].id
          break
        }
      }
    }

    // if no document type is found, exit function
    if (!documentTypeId) {
      return undefined
    }

    if (!(await containerGroupClient.exists())) {
      containerGroupClient = (await blobServiceClient.createContainer(containerName))
        .containerClient
    }

    const blockBlobClient = containerGroupClient.getBlockBlobClient(filePath)

    // Use the onProgress callback to track the upload progress
    const uploadBlobResponse = await blockBlobClient.upload(content, content.byteLength, {
      onProgress: ({ loadedBytes }) => {
        const progress = (loadedBytes / content.byteLength) * 100
        console.log('upload progress: ', progress)
        if (onProgressCallback) {
          onProgressCallback(progress)
        }
      }
    })

    const newDocument = await this.teleheartAuthService.createDocument(
      filePath,
      content.byteLength,
      documentTypeId ? documentTypeId : 4
    )

    return {
      ...uploadBlobResponse,
      size: content.byteLength,
      filename: filename,
      path: filePath,
      documentId: newDocument.result.id
    }
  }

  async downloadFromAzure(SASToken: string, path: string, containerGroup: string | undefined = undefined): Promise<BlockBlobClient | null> {
    const blobServiceClient = this.getAzureBlobServiceClient(SASToken)
    const containerName = containerGroup ?? this.containerGroup
    const containerClient = await blobServiceClient.getContainerClient(containerName)
    if (!(await containerClient.exists())) {
      throw new Error('Filepath does not exist')
    }

    const blockBlobClient = containerClient.getBlockBlobClient(`${path}`)

    const resultOfAwait = await blockBlobClient.exists()
    if (resultOfAwait) {
      return blockBlobClient
    } else {
      return null
    }
  }
}
