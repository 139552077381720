import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { DefaultLayoutComponent } from './default-layout/default-layout.component'
import { AuthGuard } from './guards/auth-guard'
import { RoleGuard } from './guards/role-guard'
import { FormGuard } from './guards/dirtyFormGuard'

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./custom-views/authentication/authentication.module').then(
        m => m.AuthenticationModule
      )
  },
  {
    path: 'patient',
    loadChildren: () =>
      import('./custom-views/patient-teleheart/patient-exams-module.module').then(
        m => m.PatientExamsModule
      )
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./custom-views/dashboard/dashboard.module').then(m => m.DashboardModule)
      }
    ]
  },
  { path: '**', redirectTo: 'login' }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard, RoleGuard, FormGuard]
})
export class AppRoutingModule {}
