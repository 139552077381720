import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FileService } from "src/app/services/file.service";
import { CommonUtils } from "../../../utils/commonUtils";

@Component({
  selector: "app-image-preview",
  templateUrl: "./image-preview.component.html",
  styleUrls: ["./image-preview.component.scss"],
})
export class ImagePreviewComponent implements OnInit {
  source = "";
  imageSrc!: string;

  constructor(public modalService: NgbActiveModal, private fileService: FileService) {}

  ngOnInit(): void {
    this.fileService.downloadFile(this.imageSrc).then(async (res) => {
      this.source = (await CommonUtils.blobToBase64(res)) as string;
    });
  }
}
