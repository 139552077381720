import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'gender'
})
export class GenderPipe implements PipeTransform {
  transform(value: number | undefined): string {
    if (value == undefined) {
      return 'Não informado'
    }

    if (value == 0) {
      return 'Masculino'
    } else if (value == 1) {
      return 'Feminino'
    } else {
      return 'Não informado'
    }
  }
}
