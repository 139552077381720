import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'

import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

import * as Sentry from '@sentry/angular-ivy'

// if (environment.production) {
//   enableProdMode()
//   window.console.log = function () {}
// }

Sentry.init({
  dsn: 'https://44eb259d68a3413a8d9651d50d531200@o1035392.ingest.sentry.io/4505187908583424',
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    new Sentry.BrowserTracing({
      tracePropagationTargets: ['localhost', 'https://portal2.machadolemos.com'],
      routingInstrumentation: Sentry.routingInstrumentation
    }),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    new Sentry.Replay()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
})

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err))
