import { Issue } from 'src/app/models/issue'
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { SidebarLoaderService } from 'src/app/services/sidebar-loader.service'
import { FileService } from '../../../services/file.service'
import { DocumentService } from '../../../services/document.service'
import { IssueComment } from '../../../models/issue-comment'
import { AuthService } from '../../../services/auth.service'
import { TicketsService } from '../../../services/tickets.service'
import { IssueStateModes } from 'src/app/utils/issueStates'
import { PdfPreviewModalComponent } from '../../modals/pdf-preview-modal/pdf-preview-modal.component'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { DocumentFileTypes } from '../../../utils/documentFileTypes'
import { ImagePreviewComponent } from '../../modals/image-preview/image-preview.component'
import { NotificationService } from 'src/app/services/notifications.service'
import { AppToastService, ToastType } from 'src/app/services/app-toast-service.service'
import { CommonUtils } from 'src/app/utils/commonUtils'
import { IssueState } from 'src/app/models/issue-state'
import { Account } from 'src/app/models/account'
import { UserRoles } from 'src/app/utils/userRoles'
import { CloseTicketReasonModalComponent } from '../../modals/close-ticket-reason-modal/close-ticket-reason-modal.component'
import { ReopenTicketReasonModalComponent } from '../../modals/reopen-ticket-reason-modal/reopen-ticket-reason-modal.component'
import { TicketsRealTimeService } from 'src/app/services/tickets-real-time.service'
import { Subscription } from 'rxjs'
import { GenericModalComponent } from '../../modals/generic-modal/generic-modal.component'
import { AccountService } from 'src/app/services/account.service'
import { IssueDepartment } from 'src/app/models/issue-department'

@Component({
  selector: 'app-simplified-ticket-sidebar',
  templateUrl: './simplified-ticket-sidebar.component.html',
  styleUrls: ['./simplified-ticket-sidebar.component.scss']
})
export class SimplifiedTicketSidebarComponent implements OnInit, OnDestroy {
  activeTab = 1
  issuerImgFile: String = ''
  assigneeImgFile: String = ''
  issueFile: String = ''
  modalRef!: NgbModalRef
  allUsers: Account[] = []
  selectedAccountId!: number
  issueDepartments: IssueDepartment[] = []
  selectedDepartmentId!: number
  showChangeDepartmentDropdown = false

  ticketUpdatesSubs!: Subscription
  newTicketCommentSubs!: Subscription
  accountRemovedFromTicketSubs!: Subscription

  newCommentAttachedFiles: File[] = []
  newCommentMessage = ''
  currentLoggedInUserId!: number
  showAddUser = false

  issueStates = IssueStateModes
  userRoles = UserRoles
  selectedUsers: Account[] = []

  @Input()
  ticketInfo!: Issue

  constructor(
    private sidebarService: SidebarLoaderService,
    private fileService: FileService,
    private documentService: DocumentService,
    private ticketsService: TicketsService,
    private authService: AuthService,
    private modalService: NgbModal,
    private toastService: AppToastService,
    private ticketsRealTimeService: TicketsRealTimeService,
    private accountsService: AccountService
  ) {}

  @ViewChild('fileInput') fileInput: any

  openFileInput() {
    // Programmatically click the hidden file input element
    this.fileInput.nativeElement.click()
  }

  async ngOnInit(): Promise<void> {
    this.currentLoggedInUserId = this.authService.getLoggedInUserId()

    this.ticketUpdatesSubs = this.ticketsRealTimeService.newTicketInfoSub.subscribe(ticket => {
      // replace ticket if it's the same
      if (ticket.id == this.ticketInfo.id) {
        console.log('ticket is open on the sidebar: ', ticket)
        this.ticketInfo = ticket
      }
    })

    this.newTicketCommentSubs = this.ticketsRealTimeService.newCommentSub.subscribe(newComment => {
      console.log('new comment id received on sidebar: ', newComment.id)
      // if new comment issue is in list, add comment to that issue
      if (newComment.issue_id == this.ticketInfo.id) {
        // if issue comment id doesn't exist, then add it
        if (
          !this.ticketInfo.issue_comments?.find(issueComment => issueComment.id == newComment.id)
        ) {
          this.ticketInfo.issue_comments?.push(newComment)
        }
      }
    })

    this.accountRemovedFromTicketSubs =
      this.ticketsRealTimeService.accountWasRemovedFromTicket.subscribe(accountAndIssueId => {
        // if ticket_id is the one opened at the moment, close the sidebar
        if (accountAndIssueId.issue_id == this.ticketInfo.id) {
          this.toastService.show(ToastType.INFO, 'Aviso', 'Foi removido desta situação.')
          this.sidebarService.closeSidebar()
        }
      })

    this.ticketsService
      .getIssueDepartments()
      .then(res => {
        this.issueDepartments = res
      })
      .catch(err => {
        console.log('err: ', err)
        this.toastService.show(
          ToastType.ERROR,
          'Erro',
          'Ocorreu um erro ao carregar os departamentos.'
        )
      })
  }

  ngOnDestroy(): void {
    if (this.ticketUpdatesSubs) this.ticketUpdatesSubs.unsubscribe()
    if (this.newTicketCommentSubs) this.newTicketCommentSubs.unsubscribe()
  }

  changeDepartment() {
    const department = this.issueDepartments.find(
      department => department.id == this.selectedDepartmentId
    )

    this.modalRef = this.modalService.open(GenericModalComponent, {
      backdrop: 'static',
      centered: true,
      size: 'md',
      scrollable: true
    })

    this.modalRef.componentInstance.title = 'warning'
    this.modalRef.componentInstance.body = `Tem a certeza que pretende mudar o departamento para ${department?.name}?`

    this.modalRef.componentInstance.cancelButtonLabel = 'cancel'
    this.modalRef.componentInstance.confirmButtonLabel = 'confirm'

    this.modalRef.result
      .then(async (result: boolean) => {
        if (result) {
          const updatedIssue = await this.ticketsService.updateIssueDepartment(
            this.ticketInfo.id,
            this.selectedDepartmentId
          )

          this.ticketsRealTimeService.newTicketInfoSub.next(updatedIssue)

          this.showChangeDepartmentDropdown = false
          this.toastService.show(
            ToastType.SUCCESS,
            'Sucesso',
            'Departamento modificado com sucesso.'
          )
        }
      })
      .catch(err => {})
  }

  async toggleAddUser() {
    let fetchedUsers: Account[] = []
    if (this.accountsService.mlAccountsList.length <= 0) {
      fetchedUsers = (await this.accountsService.searchAccountByOrgId(1, 2)).rows
      this.accountsService.mlAccountsList = this.allUsers
    } else {
      fetchedUsers = this.accountsService.mlAccountsList
    }

    this.allUsers = fetchedUsers.filter(
      user =>
        user.id != this.ticketInfo.created_by &&
        this.ticketInfo.issue_has_accounts?.find(
          issueHasAccount => issueHasAccount.account_id == user.id
        ) == undefined
    )

    this.showAddUser = true
  }

  confirmAddUser() {
    this.modalRef = this.modalService.open(GenericModalComponent, {
      backdrop: 'static',
      centered: true,
      size: 'md',
      scrollable: true
    })

    this.modalRef.componentInstance.title = 'warning'
    this.modalRef.componentInstance.body = 'Tem a certeza que pretende adicionar o utilizador?'

    this.modalRef.componentInstance.cancelButtonLabel = 'cancel'
    this.modalRef.componentInstance.confirmButtonLabel = 'confirm'

    this.modalRef.result
      .then(async (result: boolean) => {
        if (result) {
          // add users to issue
          let addedUsers = await this.ticketsService.addAccountToIssue(
            this.ticketInfo.id,
            Number(this.selectedAccountId)
          )

          addedUsers.account = this.allUsers.find(
            account => account.id == addedUsers.account_id
          ) as Account

          // add users to the list
          this.ticketInfo.issue_has_accounts?.push(addedUsers)

          this.allUsers = this.allUsers.filter(user => user.id != addedUsers.account_id)

          this.selectedAccountId = undefined as any
          this.showAddUser = false

          this.toastService.show(ToastType.SUCCESS, 'Sucesso', 'Conta adicionada com sucesso.')
        }
      })
      .catch(err => {})
  }

  async removeIssueAccount(accountId: number, issueId: number) {
    // ask for confirmation
    this.modalRef = this.modalService.open(GenericModalComponent, {
      backdrop: 'static',
      centered: true,
      size: 'md',
      scrollable: true
    })
    this.modalRef.componentInstance.title = 'warning'
    this.modalRef.componentInstance.body =
      'Tem a certeza que pretende remover esta conta da situação?'
    this.modalRef.componentInstance.cancelButtonLabel = 'cancel'
    this.modalRef.componentInstance.confirmButtonLabel = 'confirm'
    let modalResult: boolean

    try {
      modalResult = await this.modalRef.result
    } catch (err) {
      modalResult = false
    }

    // if modal is dismissed, then return
    if (!modalResult) return

    try {
      const removedAccount = await this.ticketsService.removeIssueHasAccount(issueId, accountId)

      if (removedAccount) {
        this.ticketInfo.issue_has_accounts = this.ticketInfo.issue_has_accounts?.filter(
          issueHasAccount => issueHasAccount.account_id != accountId
        )

        this.allUsers.push(removedAccount.account as Account)
      } else {
        throw new Error('Error removing account from issue')
      }

      this.toastService.show(ToastType.SUCCESS, 'Sucesso', 'Conta removida com sucesso')
    } catch (error) {
      this.toastService.show(ToastType.ERROR, 'Erro', 'Erro ao remover conta')
    }
  }

  async addNewComment() {
    if (!this.newCommentMessage) {
      this.toastService.show(ToastType.ERROR, 'Erro', 'O comentário não pode estar vazio')
      return
    }

    let newComment: IssueComment = {
      issue_id: this.ticketInfo.id,
      account_id: this.authService.getLoggedInUserId(),
      message: this.newCommentMessage
    }

    let addedComment = await this.ticketsService.addNewComment(
      newComment.message!.toString(),
      newComment.account_id,
      newComment.issue_id,
      this.newCommentAttachedFiles
    )

    // add comment to the list, if list doesn't have the comment id
    if (!this.ticketInfo.issue_comments?.find(issueComment => issueComment.id == addedComment.id)) {
      addedComment.createdAt = new Date()
      addedComment.account = { name: this.authService.getUserInfo().name } as Account
      this.ticketInfo.issue_comments?.push(addedComment)
    }

    this.toastService.show(ToastType.SUCCESS, 'Sucesso', 'Comentário adicionado com sucesso')

    // // add comment only if the comment is not from the current user
    // if (addedComment.account_id != this.ticketInfo.created_by) {
    //   this.notificationService.addNotificationByAccountId(
    //     this.ticketInfo.created_by,
    //     'Novo Comentário',
    //     'Tem um novo comentário na situação: #' + this.ticketInfo.id
    //   )
    // }

    this.newCommentAttachedFiles = []
    this.newCommentMessage = ''
  }

  onFileSelected(event: any) {
    if (event.target.files[0]) {
      this.newCommentAttachedFiles.push(event.target.files[0])
    }
    // this.selectedFile = event.target.files[0]

    // this.selectedFileName = this.selectedFile!.name
  }

  removeSelectedFile(file: File) {
    this.newCommentAttachedFiles = this.newCommentAttachedFiles.filter(f => f != file)
  }

  async closeTicketBtn() {
    this.modalRef = this.modalService.open(CloseTicketReasonModalComponent)

    await this.modalRef.result
      .then(async (result: string) => {
        // add comment saying that the issue was closed
        let newComment: IssueComment = {
          issue_id: this.ticketInfo.id,
          account_id: this.authService.getLoggedInUserId(),
          message: result
        }

        await this.ticketsService.addNewComment(
          newComment.message!,
          newComment.account_id,
          newComment.issue_id
        )

        const updatedIssue = await this.ticketsService.updateTicketState(
          this.ticketInfo.id,
          IssueStateModes.CLOSED,
          this.currentLoggedInUserId
        )

        this.toastService.show(ToastType.SUCCESS, 'Sucesso', 'Situação fechada com sucesso')

        this.sidebarService.closeSidebar()
      })
      .catch(err => {})
  }

  async reopenTicketBtn() {
    this.modalRef = this.modalService.open(ReopenTicketReasonModalComponent)

    await this.modalRef.result.then(async (result: string) => {
      // add comment saying that the issue was closed
      let newComment: IssueComment = {
        issue_id: this.ticketInfo.id,
        account_id: this.authService.getLoggedInUserId(),
        message: result
      }

      let addedComment = await this.ticketsService.addNewComment(
        newComment.message!,
        newComment.account_id,
        newComment.issue_id
      )

      const updatedIssue = await this.ticketsService.updateTicketState(
        this.ticketInfo.id,
        IssueStateModes.OPEN,
        this.currentLoggedInUserId
      )

      this.toastService.show(ToastType.SUCCESS, 'Sucesso', 'Situação reaberta com sucesso')
    })
  }

  async downloadAttachment(path: string) {
    const fileBlob = await this.fileService.downloadFile(path)
    if (fileBlob) {
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(fileBlob)
      link.download = CommonUtils.fileNameFromFilePath(path)
      link.click()
    }
  }

  async previewAttachment(documentId: number) {
    let documentInfo = await this.documentService.getDocumentById(documentId)
    console.log('doc info: ', documentInfo)

    if (documentInfo.document_type_id == DocumentFileTypes.pdf) {
      this.modalRef = this.modalService.open(PdfPreviewModalComponent, {
        backdrop: 'static',
        centered: true,
        size: 'md',
        scrollable: true
      })
      this.modalRef.componentInstance.pdfSrc = documentInfo.path
    } else {
      this.modalRef = this.modalService.open(ImagePreviewComponent, {
        backdrop: 'static',
        centered: true,
        size: 'md',
        scrollable: true
      })
      this.modalRef.componentInstance.imageSrc = documentInfo.path
    }
  }

  closeSidebar() {
    this.sidebarService.closeSidebar()
  }
}
