import { Injectable } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { GenericModalComponent } from '../shared/modals/generic-modal/generic-modal.component'

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(private modalService: NgbModal) {}

  openGenericModal(
    options: any = {
      backdrop: 'static',
      centered: true,
      size: 'lg',
      scrollable: true
    }
  ) {
    return this.modalService.open(GenericModalComponent, options)
  }

  openSpecificModal(
    component: any,
    options: any = {
      backdrop: 'static',
      centered: true,
      size: 'lg',
      scrollable: true
    }
  ) {
    return this.modalService.open(component, options)
  }
}
