import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { Appointment } from '../models/appointment'

@Injectable({
  providedIn: 'root'
})
export class AppointmentsRealTimeService {
  constructor() {}
  currentAppointmentsList: Appointment[] = []

  newAppointmentInfoSub = new Subject<Appointment>()
  newAppointmentCreatedSub = new Subject<Appointment>()
  appointmentWasDeleted = new Subject<Appointment>()

  emitNewAppointmentCreated(newAppointment: Appointment) {
    this.newAppointmentCreatedSub.next(newAppointment)
  }

  emitUpdatedAppointmentInfo(updatedInfo: Appointment) {
    this.newAppointmentInfoSub.next(updatedInfo)
  }

  emitDeletedAppointment(deletedAppointment: Appointment) {
    this.appointmentWasDeleted.next(deletedAppointment)
  }
}
