<div class="fontLight12 tab-header">
  <div class="item" [class.active]="activeTab == 1" (click)="changeTab(1)">
    <a>{{ 'information' | translate }}</a>
  </div>
  <div class="item" [class.active]="activeTab == 2" (click)="changeTab(2)">
    <a>{{ 'exams' | translate }}</a>
  </div>
</div>

<div class="main-content-sidebar">
  <div class="main-content">
    <!-- INFORMATIONS TAB -->
    <ng-container *ngIf="activeTab == 1">
      <div class="header">
        <span class="title">{{ 'information' | translate }}</span>
        <img
          class="close-button"
          src="../../../../assets/custom-icons/dark_close_icon.svg"
          (click)="closeSidebar()"
        />
      </div>
      <div class="rows">
        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ 'name' | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
            patientInfo.name
          }}</span>
        </div>

        <div class="info-single-row">
          <div class="rounded-white-background15 item">
            <span class="fontRegular10 fontColorMediumDarkGray">{{ 'birthdate' | translate }}</span>
            <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
              patientInfo.dateOfBirth | date : 'dd-MM-yyyy'
            }}</span>
          </div>

          <div class="rounded-white-background15 item">
            <span class="fontRegular10 fontColorMediumDarkGray">{{ 'gender' | translate }}</span>
            <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
              patientInfo.gender | gender
            }}</span>
          </div>
        </div>
        <div class="info-single-row">
          <div class="rounded-white-background15 item">
            <span class="fontRegular10 fontColorMediumDarkGray">{{
              'health_number' | translate
            }}</span>
            <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
              patientInfo.healthNumber
            }}</span>
          </div>

          <div class="rounded-white-background15 item">
            <span class="fontRegular10 fontColorMediumDarkGray">{{
              'tax_number' | translate
            }}</span>
            <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
              patientInfo.taxNumber
            }}</span>
          </div>
        </div>
        <div class="info-single-row">
          <div class="rounded-white-background15 item">
            <span class="fontRegular10 fontColorMediumDarkGray">{{ 'weight' | translate }}</span>
            <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer"
              >{{ patientInfo.weight }}kg</span
            >
          </div>

          <div class="rounded-white-background15 item">
            <span class="fontRegular10 fontColorMediumDarkGray">{{ 'height' | translate }}</span>
            <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer"
              >{{ patientInfo.height }}cm</span
            >
          </div>
        </div>

        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ 'address' | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
            patientInfo.address?.street || ''
          }}</span>
        </div>

        <div class="info-single-row">
          <div class="rounded-white-background15 item">
            <span class="fontRegular10 fontColorMediumDarkGray">{{ 'city' | translate }}</span>
            <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
              patientInfo.address?.city || ''
            }}</span>
          </div>

          <div class="rounded-white-background15 item">
            <span class="fontRegular10 fontColorMediumDarkGray">{{ 'post_code' | translate }}</span>
            <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
              patientInfo.address?.zip || ''
            }}</span>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- EXAMS TAB -->
    <ng-container *ngIf="activeTab == 2">
      <div class="header">
        <span class="title">{{ 'exams' | translate }}</span>
        <img
          class="close-button"
          src="../../../../assets/custom-icons/dark_close_icon.svg"
          (click)="closeSidebar()"
        />
      </div>
      <div *ngFor="let exam of patientInfo.exams">
        <div class="previous-exam">
          <div class="top">
            <span class="fontSemiBold10 fontColorPrimary">Exame nº {{ exam.id }}</span>
            <span class="fontRegular10 fontColorMediumDarkGray">{{
              exam.exam_type_id | examType
            }}</span>
          </div>

          <div class="bottom">
            <div class="left">
              <span class="fontRegular10 fontColorMediumLightGray">Estado</span>
              <ng-container *ngIf="exam.reports.length">
                <span class="fontRegular10 fontColorMediumDarkGray">{{
                  exam.reports[exam.reports.length - 1].status_id | reportState
                }}</span>
              </ng-container>
              <ng-container *ngIf="exam.external">
                <span class="fontRegular10 fontColorMediumDarkGray">Exame Externo</span>
              </ng-container>
            </div>
            <img
              *ngIf="exam.reports?.[exam.reports.length - 1]?.status_id == 4 || exam.external"
              class="preview-pdf"
              src="../../../../../assets/custom-icons/exam_report_sidebar_file.svg"
              (click)="previewReport(exam)"
            />
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
