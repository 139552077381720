import { Component, OnInit } from '@angular/core'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { Exam } from 'src/app/models/exam'
import { SidebarLoaderService } from 'src/app/services/sidebar-loader.service'
import { PdfPreviewModalComponent } from 'src/app/shared/modals/pdf-preview-modal/pdf-preview-modal.component'
import { CommonUtils } from 'src/app/utils/commonUtils'
import { ExamReportUtils } from 'src/app/utils/examReportUtils'
import { Person } from '../../../../models/person'
import { ReportService } from '../../../../services/report.service'
import { GenerateReportService } from 'src/app/services/generate-report.service'

@Component({
  selector: 'app-exam-report-sidebar',
  templateUrl: './exam-report-sidebar.component.html',
  styleUrls: ['./exam-report-sidebar.component.scss']
})
export class ExamReportSidebarComponent implements OnInit {
  examInfo!: Exam
  patientInfo!: Person
  exams: Exam[] = []
  activeTab = 1
  examMedication!: string
  examSymptoms!: string
  examNotes!: string
  modalRef!: NgbModalRef

  constructor(
    private sidebarLoaderService: SidebarLoaderService,
    private reportService: ReportService,
    private modalService: NgbModal,
    private generateReportService: GenerateReportService
  ) {}

  ngOnInit(): void {
    this.examMedication = ExamReportUtils.findExamPropertyValueByName(
      'medication',
      this.examInfo.exam_proprieties
    )
    this.examSymptoms = ExamReportUtils.findExamPropertyValueByName(
      'symptoms',
      this.examInfo.exam_proprieties
    )
    this.examNotes = ExamReportUtils.findExamPropertyValueByName(
      'notes',
      this.examInfo.exam_proprieties
    )

    this.exams = this.patientInfo.exams
  }

  closeSidebar() {
    this.sidebarLoaderService.closeSidebar()
  }

  changeTab(tab: number) {
    if (tab != this.activeTab) {
      this.activeTab = tab
    }
  }

  async previewReport(patientExam: Exam) {
    await this.generateReportService.downloadSignedReport(patientExam.id)
  }
}
