export type BodyParams = Record<string, any>

export function createHttpBody(params: Record<string, any>, allowNull: Boolean = false): Record<string, any> {
  const httpBodyParams: Record<string, any> = {}

  Object.keys(params).forEach((param) => {
    // Check if the value is not undefined or null (includes falsy values like 0)
    if ((params[param] !== undefined && params[param] !== null) || allowNull) {
      httpBodyParams[param] = params[param]
    }
  })

  return httpBodyParams
}
