import { Injectable } from '@angular/core'
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse
} from '@angular/common/http'

import { Observable, tap } from 'rxjs'
import { AuthService } from './auth.service'
import { AppToastService, ToastType } from './app-toast-service.service'

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService, private toastService: AppToastService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('feathers-jwt')

    if (token) {
      const clonedRequest = req.clone({
        headers: req.headers.set('authorization', token)
      })

      return next.handle(clonedRequest).pipe(
        tap({
          next: event => {
            if (event instanceof HttpResponse) {
              // Handle successful responses here
            }
          },
          error: error => {
            if (error.status == 401) {
              this.toastService.show(ToastType.ERROR, 'Erro', 'Token Inválido.')
              this.authService.logout()
            } else if (error.status == 405) {
              this.toastService.show(ToastType.ERROR, 'Erro', 'Não tem permissão para aceder.')
              this.authService.logout()
            }
          }
        })
      )
    } else {
      return next.handle(req)
    }
  }
}
