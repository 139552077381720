<div class="modal-header">
  <span>{{ 'close_ticket' | translate }}</span>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="modalService.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body">
  <div class="flexColumn5">
    <span class="fontRegular12 fontColorMediumGray">{{ 'reason' | translate }}</span>
    <select class="form-input-10" [(ngModel)]="message">
      <option *ngFor="let reason of reasons" [ngValue]="reason">{{ reason }}</option>
    </select>
    <div *ngIf="message == 'Outro'">
      <span class="fontRegular12 fontColorMediumGray">{{ 'other_reason' | translate }}</span>
      <input class="form-input-10" type="text" [(ngModel)]="customMessage" />
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="secondary-button" (click)="dismissAction()">
    {{ 'cancel' | translate }}
  </button>
  <button type="button" class="primary-button" (click)="confirmAction()">
    {{ 'confirm' | translate }}
  </button>
</div>
