import { Component, OnInit } from "@angular/core";
import { Store } from "src/app/models/store";
import { SidebarLoaderService } from "src/app/services/sidebar-loader.service";

@Component({
  selector: "app-associated-stores-sidebar",
  templateUrl: "./associated-stores-sidebar.component.html",
  styleUrls: ["./associated-stores-sidebar.component.scss"],
})
export class AssociatedStoresSidebarComponent implements OnInit {
  stores: Store[] = [];

  constructor(private sidebarLoaderService: SidebarLoaderService) {}

  ngOnInit(): void {}

  closeSidebar() {
    this.sidebarLoaderService.closeSidebar();
  }
}
