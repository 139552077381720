<div class="modal-header">
  <span>{{ "no_internet" | translate }}</span>
  <!-- <button type="button" class="btn-close" aria-label="Close" (click)="modalService.dismiss()"></button> -->
</div>
<div class="modal-body">
  <p>Neste momento não tem acesso à internet. Reconecte-se e tente novamente.</p>
</div>
<div class="modal-footer">
  <button class="fontRegular10 fontColorDarkGray" type="button" class="secondary-button">
    {{ "try_again" | translate }}
  </button>
</div>
