export const ExamTypesEditUrl = {
  1: "ecg-report",
  2: "map-report",
  3: "map-report",
  4: "holter-report",
  5: "holter-report",
  6: "eco-report",
  7: "eco-doppler-report",
  8: "prova-report",
} as const;
