import { ExamState } from './examStates'
import { ReportState } from './reportStates'

export class VerifyExamState {
  static receptionExams(examInfo: any): boolean {
    return (
      (examInfo.status_id === ExamState.NEW || examInfo.status_id === ExamState.CONVERTED) &&
      examInfo.report_status_id === ReportState.NEW &&
      examInfo.repeat === 0
    )
  }

  static examsNoFile(examInfo: any): boolean {
    return examInfo.status_id === ExamState.NOFILE
  }

  static examsToRepeat(examInfo: any): boolean {
    return examInfo.repeat == 1
  }

  static reportExams(examInfo: any): boolean {
    return (
      examInfo.status_id === ExamState.REPORTED &&
      (examInfo.report_status_id === ReportState.NEW ||
        examInfo.report_status_id === ReportState.ONGOING ||
        examInfo.report_status_id === ReportState.WAITINGAPPROVAL) &&
      examInfo.repeat === 0
    )
  }

  static signExams(examInfo: any): boolean {
    return examInfo.report_status_id == ReportState.WAITINGAPPROVAL && examInfo.repeat === 0
  }

  static finalizedExams(examInfo: any): boolean {
    return examInfo.report_status_id == ReportState.SIGNED
  }

  static requestExamForExternalTech(examInfo: any): boolean {
    return (
      !examInfo.tech_id &&
      (examInfo.status_id === ExamState.CONVERTED ||
        examInfo.status_id === ExamState.NEW ||
        examInfo.status_id === ExamState.REPORTED) &&
      examInfo.repeat === 0 &&
      examInfo.report_status_id === ReportState.NEW
    )
  }
}
