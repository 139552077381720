import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { firstValueFrom, first } from 'rxjs'
import { environment } from 'src/environments/environment'
import { Document } from '../models/document'
import { SocketInterceptorService } from './socket-interceptor.service'

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  private url = `${environment.url}/documents/` //server

  private httpOptions: { headers: HttpHeaders } = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(
    private http: HttpClient,
    private socketInterceptorService: SocketInterceptorService
  ) {}

  getDocumentById(documentId: number): Promise<Document> {
    return firstValueFrom(this.http.get<Document>(`${this.url}${documentId}`))
  }

  createDocument(
    path: string,
    size: number,
    document_type_id: number,
    account_id: number
  ): Promise<Document> {
    return firstValueFrom(
      this.http.post<Document>(
        `${this.url}`,
        { path, size, document_type_id, account_id },
        this.httpOptions
      )
    )
  }

  createDocumentViaSockets(
    path: string,
    size: number,
    document_type_id: number,
    account_id: number
  ): Promise<Document> {
    return this.socketInterceptorService.makeCreateRequest('documents', {
      path,
      size,
      document_type_id,
      account_id
    })
  }

  updateDocument(
    documentId: number,
    path: string,
    size: number,
    document_type_id: number,
    account_id: number
  ): Promise<Document> {
    return firstValueFrom(
      this.http.put<Document>(
        `${this.url}${documentId}`,
        { path, size, document_type_id, account_id },
        this.httpOptions
      )
    )
  }

  updateDocumentPath(documentId: number, newDocumentPath: string): Promise<Document> {
    return firstValueFrom(
      this.http.put<Document>(`${this.url}${documentId}`, { path: newDocumentPath })
    )
  }

  deleteDocument(documentId: number): Promise<Document> {
    return firstValueFrom(this.http.delete<Document>(`${this.url}${documentId}`))
  }
}
