import { Injectable, EventEmitter } from '@angular/core'
import { Exam } from '../models/exam'
import { Issue } from '../models/issue'
import { Account } from '../models/account'
import { Person } from '../models/person'
import { InvoiceInfo } from '../models/invoice-info'
import { Store } from '../models/store'
import { ComplaintBook } from '../models/complaint-book'
import { ComplaintBookLog } from '../models/complaint-book-log'
import { Code } from '../models/code'
import { MaterialLog } from '../models/material-log'
import { MaterialList } from '../custom-views/management/material/material.component'

@Injectable({
  providedIn: 'root'
})
export class SidebarLoaderService {
  constructor() {}

  sidebarVisibility = new EventEmitter<boolean>()
  showNotificationsSidebar = new EventEmitter<null>()
  showWarningsSidebar = new EventEmitter<null>()
  showUserSettingsSidebar = new EventEmitter<null>()
  showExamInfoSidebar = new EventEmitter<Exam>()
  showExamReportSidebar = new EventEmitter<{ examInfo: Exam; patientInfo: Person }>()
  showSimplifiedTicketInfoSidebar = new EventEmitter<Issue>()
  showRecentTicketsSidebar = new EventEmitter<null>()
  showAssociatedStoresSidebar = new EventEmitter<Store[]>()
  showInvoiceInfoSidebar = new EventEmitter<InvoiceInfo>()
  showComplaintsBooksInfoSidebar = new EventEmitter<{
    currentLog: ComplaintBookLog
    historyLog: ComplaintBookLog[]
    number: string
  }>()
  showStoreInfoSidebar = new EventEmitter<Store>()
  showMaterialInfoSidebar = new EventEmitter<{
    currentLog: MaterialList
    historyLog: MaterialLog[]
  }>()
  showPatientInfoSidebar = new EventEmitter<Person>()
  showPersonInfoSidebar = new EventEmitter<Account>()
  showCodeInfoSidebar = new EventEmitter<Code>()

  closeSidebar() {
    this.sidebarVisibility.emit(false)
  }

  toggleNotificationsSidebar() {
    this.sidebarVisibility.emit(true)
    this.showNotificationsSidebar.emit()
  }

  toggleWarningsSidebar() {
    this.sidebarVisibility.emit(true)
    this.showWarningsSidebar.emit()
  }

  toggleUserSettingsSidebar() {
    this.sidebarVisibility.emit(true)
    this.showUserSettingsSidebar.emit()
  }

  toggleExamInfoSidebar(examInfo: Exam) {
    this.sidebarVisibility.emit(true)
    this.showExamInfoSidebar.emit(examInfo)
  }

  toggleExamReportSidebar(examInfo: Exam, patientInfo: Person) {
    this.sidebarVisibility.emit(true)
    this.showExamReportSidebar.emit({ examInfo: examInfo, patientInfo: patientInfo })
  }

  toggleSimplifiedTicketInfoSidebar(ticketInfo: Issue) {
    this.sidebarVisibility.emit(true)
    this.showSimplifiedTicketInfoSidebar.emit(ticketInfo)
  }

  toggleRecentTicketsSidebar(tickets: any) {
    this.sidebarVisibility.emit(true)
    this.showRecentTicketsSidebar.emit(tickets)
  }

  toggleAssociatedStoresSidebar(stores: Store[]) {
    this.sidebarVisibility.emit(true)
    this.showAssociatedStoresSidebar.emit(stores)
  }

  toggleInvoiceInfoSidebar(invoiceInfo: InvoiceInfo) {
    this.sidebarVisibility.emit(true)
    this.showInvoiceInfoSidebar.emit(invoiceInfo)
  }

  toggleComplaintsBookInfoSidebar(
    currentLog: ComplaintBookLog,
    historyLog: ComplaintBookLog[],
    number: string
  ) {
    this.sidebarVisibility.emit(true)
    this.showComplaintsBooksInfoSidebar.emit({
      currentLog: currentLog,
      historyLog: historyLog,
      number: number
    })
  }

  toggleStoreInfoSidebar(storeInfo: Store) {
    this.sidebarVisibility.emit(true)
    this.showStoreInfoSidebar.emit(storeInfo)
  }

  toggleMaterialInfoSidebar(currentLog: MaterialList, historyLog: MaterialLog[]) {
    this.sidebarVisibility.emit(true)
    this.showMaterialInfoSidebar.emit({ currentLog: currentLog, historyLog: historyLog })
  }

  togglePatientInfoSidebar(patientInfo: Person) {
    this.sidebarVisibility.emit(true)
    this.showPatientInfoSidebar.emit(patientInfo)
  }

  togglePersonInfoSidebar(personInfo: Account) {
    this.sidebarVisibility.emit(true)
    this.showPersonInfoSidebar.emit(personInfo)
  }

  toggleCodeInfoSidebar(codeInfo: Code) {
    this.sidebarVisibility.emit(true)
    this.showCodeInfoSidebar.emit(codeInfo)
  }
}
