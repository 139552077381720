import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core'
import { HeaderInfoWarning } from 'src/app/models/header-info-warning'
import { ExamRequestGroup } from 'src/app/models/exam-request-group'
import { GeneratePdfService } from 'src/app/services/generate-pdf.service'
import { ExamRequestService } from 'src/app/services/exam-request.service'
import { RequestExamsGroupState } from 'src/app/utils/requestExamsGroupState'
import { ModalService } from 'src/app/services/modal.service'
import { ExamType } from 'src/app/models/exam-type'
import { AppointmentsService } from 'src/app/services/appointments.service'
import { ActivatedRoute, Router } from '@angular/router'
import { ExamRequest } from 'src/app/models/exam-request'

@Component({
  selector: 'app-exam-request-warning-item',
  templateUrl: './exam-request-warning-item.component.html',
  styleUrls: ['./exam-request-warning-item.component.scss']
})
export class ExamRequestWarningItemComponent implements OnInit, OnDestroy {

  @Input() item!: HeaderInfoWarning

  // @Output() removeExamGroup = this.removeExamGroupFromList.bind(this)
  @Output() removeExamGroup = new EventEmitter<number>();
  @Output() updatedExamGroup = new EventEmitter<ExamRequestGroup>();

  examsToBeScheduled: ExamRequest[] = []

  constructor(private generatePdfService: GeneratePdfService, private examRequestService: ExamRequestService, private modalService: ModalService, private appointmentService: AppointmentsService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.examsToBeScheduled = this.item.examRequestGroup?.exam_requests.filter(er => er.state != 1) || []
  }


  clickedWarning(warning: HeaderInfoWarning) {
    console.log('clickLink', warning);
    // console.log('inHouseExamTypeIds', this.inHouseExamTypeIds);
  }

  async openReqPdf(examRequestGroup: ExamRequestGroup) {
    if (!examRequestGroup) {
      return
    }

    await this.generatePdfService.generateRequestedExamsPdf(examRequestGroup)

    this.examRequestService.updateExamReqGroupState(examRequestGroup.id, RequestExamsGroupState.PRINTED)
      .then(() => {
        examRequestGroup.state = RequestExamsGroupState.PRINTED
        this.updatedExamGroup.emit(examRequestGroup)
      })
      .catch(err => {
        console.log('Error updating exam request group state', err)
      })
  }

  removeExamGroupFromList() {
    const groupId = this.item.examRequestGroup!.id

    if (!groupId) {
      return
    }

    this.examRequestService.updateExamReqGroupState(groupId, RequestExamsGroupState.FINISHED)
      .then(res => {
        this.removeExamGroup.emit(groupId)
        console.log('Exam request group state updated', res)
      })
      .catch(err => {
        console.log('Error updating exam request group state', err)
      })
  }

  scheduleExam(examTypeId?: number) {
    const requestGroup = this.item.examRequestGroup
    if (!requestGroup) {
      return
    }

    const examToScheduleIds = examTypeId ? [examTypeId] : this.examsToBeScheduled.filter(req => req.state === 2 && req.exam_request_type?.exam_type_id).map(req => req.exam_request_type!.exam_type_id!) || []

    if (this.appointmentService.prefillStepOneDateFromExamGroup(requestGroup, examToScheduleIds)) {

      this.router.navigateByUrl('/dashboard/appointments/schedule-appointment')

      // console.log('prefillStepOneDateFromExamGroup', this.appointmentService.stepOneData);
    }
  }

  ngOnDestroy(): void {
  }
}
