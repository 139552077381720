import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "age",
})
export class AgePipe implements PipeTransform {
  transform(birth?: Date): string {
    let birthdate = new Date(birth!);

    let d1 = birthdate!.getDate();
    let m1 = birthdate!.getMonth() + 1;
    let y1 = birthdate!.getFullYear();

    let date = new Date();
    let d2 = date.getDate();
    let m2 = 1 + date.getMonth();
    let y2 = date.getFullYear();
    let month = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    if (d1 > d2) {
      d2 = d2 + month[m2 - 1];
      m2 = m2 - 1;
    }
    if (m1 > m2) {
      m2 = m2 + 12;
      y2 = y2 - 1;
    }
    let y = y2 - y1;

    return y + " Anos";
  }
}
