import { Component, OnInit } from "@angular/core";
import { SidebarLoaderService } from "src/app/services/sidebar-loader.service";
import { MaterialLog } from "../../../models/material-log";
import { MaterialList } from "../../../custom-views/management/material/material.component";

@Component({
  selector: "app-material-info-sidebar",
  templateUrl: "./material-info-sidebar.component.html",
  styleUrls: ["./material-info-sidebar.component.scss"],
})
export class MaterialInfoSidebarComponent implements OnInit {
  materialNumber!: string;
  currentLog!: MaterialList;
  historyLog!: MaterialLog[];

  constructor(private sidebarLoaderService: SidebarLoaderService) {}

  ngOnInit(): void {}

  closeSidebar() {
    this.sidebarLoaderService.closeSidebar();
  }
}
