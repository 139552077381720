import { Component, Input, OnInit } from "@angular/core";
import { SidebarLoaderService } from "src/app/services/sidebar-loader.service";

@Component({
  selector: "app-recent-tickets-sidebar",
  templateUrl: "./recent-tickets-sidebar.component.html",
  styleUrls: ["./recent-tickets-sidebar.component.scss"],
})
export class RecentTicketsSidebarComponent implements OnInit {
  @Input()
  recentTickets = new Array(5);

  constructor(private sidebarLoaderService: SidebarLoaderService) {}

  ngOnInit(): void {}

  closeSidebar() {
    this.sidebarLoaderService.closeSidebar();
  }
}
