// Role / id in database
export enum ExamComponents {
  RECEPTION_EXAMS = 'reception_exams',
  REPORT_EXAMS = 'report_exams',
  SIGN_EXAMS = 'sign_exams',
  FINALIZED_EXAMS = 'finalized_exams',
  ARQUIVED_EXAMS = 'arquived_exams',
  EXAMS_NO_FILE = 'exams_no_file',
  EXAMS_TO_REPEAT = 'exams_to_repeat',
  STORE_SUBMITTED_EXAMS = 'store_submitted_exams'
}
