<div class="main-content-sidebar">
  <div class="main-content" #notificationsSidebarComponent>
    <div class="flexColumn5" style="margin-bottom: 2em">
      <div class="header" style="margin-bottom: 0">
        <span class="title">{{ 'warnings' | translate }}</span>
        <img class="close-button" src="../../../../assets/custom-icons/dark_close_icon.svg" (click)="closeSidebar()" />
      </div>
    </div>

    <ng-container *ngIf="warningInfoArray.length > 0">
      <div *ngFor="let item of warningInfoArray">
        <ng-container *ngIf="item.appointmentId">
          <app-appointment-warning-item [item]="item"></app-appointment-warning-item>
        </ng-container>

        <ng-container *ngIf="item.examRequestGroup">
          <app-exam-request-warning-item [item]="item" (removeExamGroup)="onRemoveExamGroup($event)"
            (updatedExamGroup)="onUpdateExamGroupInfo($event)"></app-exam-request-warning-item>
        </ng-container>
      </div>

    </ng-container>
    <ng-container *ngIf="!warningInfoArray.length">
      <div style="display: flex; justify-content: center">
        <span class="fontColorMediumDarkGray fontRegular10">Nenhum aviso.</span>
      </div>
    </ng-container>
  </div>
</div>