import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { firstValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'
import { createHttpBody } from '../utils/http-body'
import { DownloadLog, DownloadLogType } from '../models/exam-download-log'

@Injectable({
  providedIn: 'root'
})
export class DownloadLogService {
  private url = `${environment.url}/download-log/` //server

  private httpOptions: { headers: HttpHeaders } = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(private http: HttpClient) {}

  async createLog(
    exam_id: number,
    download_type: DownloadLogType,
    account_id?: number
  ): Promise<DownloadLog> {
    const httpBody = createHttpBody({
      exam_id,
      download_type,
      account_id
    })

    return firstValueFrom(this.http.post<DownloadLog>(`${this.url}`, httpBody, this.httpOptions))
  }
}
