import { Component, Input, OnInit, OnDestroy } from '@angular/core'
import { HeaderInfoWarning } from 'src/app/models/header-info-warning'
import { ExamRequestGroup } from 'src/app/models/exam-request-group'
import { GeneratePdfService } from 'src/app/services/generate-pdf.service'
import { ExamRequestService } from 'src/app/services/exam-request.service'
import { Router } from '@angular/router'

@Component({
  selector: 'app-appointment-warning-item',
  templateUrl: './appointment-warning-item.component.html',
  styleUrls: ['./appointment-warning-item.component.scss']
})
export class AppointmentWarningItemComponent implements OnInit, OnDestroy {

  @Input() item!: HeaderInfoWarning

  constructor(private router: Router) { }

  ngOnInit() { }

  clickedWarning(warning: HeaderInfoWarning) {
    // this.sidebarLoaderService.closeSidebar()
    // this.genericRealTimeService.warningInfo.next([])
    // this.genericRealTimeService.warningInfo.next([warning])
  }

  clickLink(warning: HeaderInfoWarning) {
    if (warning.link) {
      // remove warning from array
      // const currentWarningInfos = this.genericRealTimeService.warningInfo.getValue()
      // const newWarningInfos = currentWarningInfos.filter(w => w.link != warning.link)
      // this.genericRealTimeService.warningInfo.next(newWarningInfos)
      this.router.navigateByUrl(warning.link)
    }
  }

  ngOnDestroy(): void { }
}
