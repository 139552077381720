export const ExamTypesArray = [
  'ECG',
  'MAPA24',
  'MAPA48',
  'HOLTER24',
  'HOLTER48',
  'ECO',
  'ECODOPPLER',
  'PROVA'
]
