<div class="main-content-sidebar">
  <div class="main-content">
    <div class="header">
      <span class="title">{{ "settings" | translate }}</span>
      <img class="close-button" src="../../../../assets/custom-icons/dark_close_icon.svg" (click)="closeSidebar()" />
    </div>

    <div class="rounded-white-background15 item">
      <span class="fontRegular10 fontColorMediumDarkGray">{{ "user_data" | translate }}</span>
      <span class="fontRegular10 fontColorPrimary" style="cursor: pointer" (click)="goToEditProfile()">{{ "access" | translate }}</span>
    </div>

    <div class="rounded-white-background15 item">
      <span class="fontRegular10 fontColorMediumDarkGray">{{ "change_password" | translate }}</span>
      <span class="fontRegular10 fontColorPrimary" style="cursor: pointer" (click)="goToEditProfile()">{{ "access" | translate }}</span>
    </div>

    <div class="rounded-white-background15 item">
      <span class="fontRegular10 fontColorMediumDarkGray">{{ "notifications" | translate }}</span>
      <div class="switch">
        <input id="switch-1" type="checkbox" class="switch-input" />
        <label for="switch-1" class="switch-label"></label>
      </div>
      <!-- <span class="fontRegular10 fontColorPrimary" style="cursor: pointer" (click)="goToEditProfile()">Aceder</span> -->
    </div>
  </div>
</div>
