import { Component, OnInit } from '@angular/core'
import { SidebarLoaderService } from '../../../services/sidebar-loader.service'
import { Account } from '../../../models/account'

@Component({
  selector: 'app-person-info-sidebar',
  templateUrl: './person-info-sidebar.component.html',
  styleUrls: ['./person-info-sidebar.component.scss']
})
export class PersonInfoSidebarComponent implements OnInit {
  accountInfo!: Account

  constructor(private sidebarLoaderService: SidebarLoaderService) {}

  ngOnInit(): void {}

  closeSidebar() {
    this.sidebarLoaderService.closeSidebar()
  }

  getStores() {
    return this.accountInfo.account_has_accesses.map(access => access.store.name).join(', ')
  }
}
