import { Component, Input, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-close-ticket-reason-modal',
  templateUrl: './close-ticket-reason-modal.component.html',
  styleUrls: ['./close-ticket-reason-modal.component.scss']
})
export class CloseTicketReasonModalComponent implements OnInit {
  message!: string
  reasons = ['Resolvido', 'Não Aplicável', 'Duplicado', 'Material Enviado', 'Outro']
  customMessage = ''

  constructor(public modalService: NgbActiveModal) { }

  ngOnInit(): void { }

  confirmAction() {
    if (!this.message?.length) return

    if (this.message == 'Outro') {
      if (!this.customMessage?.length) return
      this.modalService.close('Situação Fechada com o motivo: ' + this.customMessage)
    } else {
      this.modalService.close('Situação Fechada com o motivo: ' + this.message)
    }
  }

  dismissAction() {
    this.modalService.dismiss()
  }
}
