import { Component, OnInit } from "@angular/core";
import { SidebarLoaderService } from "../../../services/sidebar-loader.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-user-settings-sidebar",
  templateUrl: "./user-settings-sidebar.component.html",
  styleUrls: ["./user-settings-sidebar.component.scss"],
})
export class UserSettingsSidebarComponent implements OnInit {
  constructor(private sidebarLoaderService: SidebarLoaderService, private router: Router) {}

  ngOnInit(): void {}

  closeSidebar() {
    this.sidebarLoaderService.closeSidebar();
  }

  goToEditProfile() {
    this.router.navigateByUrl("dashboard/edit-profile");
  }
}
