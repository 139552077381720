import { Injectable } from "@angular/core"
import { BehaviorSubject, Subject } from "rxjs"
import { AuthService } from "./auth.service"

@Injectable({
  providedIn: "root",
})
export class CommonService {
  userProfilePic: BehaviorSubject<string> = new BehaviorSubject("")
  public showTutorial = new BehaviorSubject<boolean>(false)

  constructor() {}

  emitProfilePic(profilePicBase64: string) {
    if (profilePicBase64) {
      this.userProfilePic.next(profilePicBase64)
    }
  }
}
