<div class="notification-card" (click)="clickedWarning(item)">
  <ng-container *ngIf="item.appointmentId">
    <span class="fontColorMediumGray fontRegular9">Agendamento nº: {{ item.appointmentId }}</span>
  </ng-container>
  <ng-container *ngIf="item.appointmentDate">
    <span class="fontColorMediumGray fontRegular9">{{
      item.appointmentDate | date : 'dd-MM-yyyy - HH:mm'
      }}</span>
  </ng-container>
  <ng-container *ngIf="item.appointmentTypeId">
    <span class="fontColorMediumGray fontRegular9">{{
      item.appointmentTypeId | appointmentType
      }}</span>
  </ng-container>
  <span class="fontColorMediumDarkGray fontSemiBold10">{{ item.name }}</span>
  <span class="fontRegular10 fontColorDefaultGray">{{ item.message }}</span>
  <ng-container *ngIf="item.type === 'link'">
    <span class="fontColorMediumDarkGray fontRegular10" (click)="clickLink(item)">Clique aqui</span>
  </ng-container>
</div>