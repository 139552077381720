import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "storeState",
})
export class StoreStatePipe implements PipeTransform {
  transform(state: number): string {
    if (state == 1) {
      return "active";
    } else if (state == 2) {
      return "inactive";
    } else {
      return "suspended";
    }
  }
}
