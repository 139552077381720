import { Component, OnInit } from "@angular/core";
import { SidebarLoaderService } from "src/app/services/sidebar-loader.service";
import { Code } from "../../../models/code";

@Component({
  selector: "app-code-info-sidebar",
  templateUrl: "./code-info-sidebar.component.html",
  styleUrls: ["./code-info-sidebar.component.scss"],
})
export class CodeInfoSidebarComponent implements OnInit {
  codeInfo!: Code;

  constructor(private sidebarLoaderService: SidebarLoaderService) {}

  ngOnInit(): void {}

  closeSidebar() {
    this.sidebarLoaderService.closeSidebar();
  }
}
