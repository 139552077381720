<div class="main-content-sidebar">
  <div class="main-content">
    <div class="header">
      <span class="title">{{ 'information' | translate }}</span>
      <img
        class="close-button"
        src="../../../../assets/custom-icons/dark_close_icon.svg"
        (click)="closeSidebar()"
      />
    </div>

    <div class="rows">
      <div class="info-single-row">
        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ 'exam_nr' | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
            invoiceInfo.exam_id
          }}</span>
        </div>

        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ 'date' | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
            invoiceInfo.createdAt | date : 'dd-MM-yyyy'
          }}</span>
        </div>
      </div>
      <div class="info-single-row">
        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ 'hour' | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
            invoiceInfo.createdAt | date : 'H:mm'
          }}</span>
        </div>

        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ 'gender' | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
            invoiceInfo.exam.patient.gender | gender
          }}</span>
        </div>
      </div>

      <div class="rounded-white-background15 item">
        <span class="fontRegular10 fontColorMediumDarkGray">{{ 'patient_name' | translate }}</span>
        <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
          invoiceInfo.exam.patient.name
        }}</span>
      </div>

      <div class="rounded-white-background15 item">
        <span class="fontRegular10 fontColorMediumDarkGray">{{ 'simplified' | translate }}</span>
        <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
          invoiceInfo.invoice_type_id == 2 ? 'sim' : 'não'
        }}</span>
      </div>
      <button
        *ngIf="invoiceInfo.docLink"
        class="primary-button"
        style="margin-top: 2em"
        (click)="openLinkInNewTab(invoiceInfo.docLink)"
      >
        {{ 'access_invoice' | translate }}
      </button>
    </div>
  </div>
</div>
