import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { firstValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'
import { Report } from '../models/report'
import { createHttpParams } from '../utils/http-params'
import { SocketInterceptorService } from './socket-interceptor.service'

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private url = `${environment.url}/reports/` //server
  private urlReportFile = `${environment.reportUrl}` //server

  constructor(
    private http: HttpClient,
    private socketInterceptorService: SocketInterceptorService
  ) {}

  getReportById(reportId: number, associatedAttributesType?: number): Promise<Report> {
    const httpParams = createHttpParams({
      associatedAttributes: associatedAttributesType
    })

    return firstValueFrom(this.http.get<Report>(`${this.url}${reportId}`, { params: httpParams }))
  }

  getReportByIdViaSockets(reportId: number, associatedAttributes?: number): Promise<Report> {
    return this.socketInterceptorService.makeGetRequest('reports', reportId, {
      associatedAttributes
    })
  }

  addReport(exam_id: number, status_id: number): Promise<Report> {
    // await this.examsService.
    return firstValueFrom(this.http.post<Report>(`${this.url}`, { exam_id, status_id }))
  }

  updateReport(
    report_id: number,
    status_id?: number,
    doctor_id?: number,
    tech_id?: number,
    delete_reason?: string
  ): Promise<Report> {
    const params: HttpParams = createHttpParams({
      status_id: status_id,
      doctor_id: doctor_id,
      tech_id: tech_id,
      delete_reason: delete_reason
    })
    return firstValueFrom(this.http.put<Report>(`${this.url}${report_id}`, params))
  }

  updateReportStatusId(reportId: number, status_id: number): Promise<Report> {
    return firstValueFrom(this.http.put<Report>(`${this.url}${reportId}`, { status_id }))
  }

  updateDoctorId(reportId: number, doctor_id: number): Promise<Report> {
    return firstValueFrom(this.http.put<Report>(`${this.url}${reportId}`, { doctor_id }))
  }

  async generateReport(examId: number, plain?: string): Promise<ArrayBuffer | null> {
    let params = new HttpParams().set('examId', examId.toString())

    if (plain !== undefined) {
      params = params.set('plain', plain)
    }

    return firstValueFrom(
      this.http.get(`${this.urlReportFile}report`, { params, responseType: 'arraybuffer' })
    ).then((response: ArrayBuffer) => {
      if (!response) {
        throw new Error('No response received')
      }
      return response
    }).catch((error) => {
      //throw new Error(error)
      return null;
    })
  }
}
