import { Pipe, PipeTransform } from '@angular/core'
import { AppointmentTypes } from '../utils/appointmentType'
import { ExamTypesArray } from '../utils/examTypesArray'
import { Appointment } from '../models/appointment'
import { AppointmentUtils } from '../utils/appointmentUtils'

@Pipe({
  name: 'appointmentType'
})
export class AppointmentTypePipe implements PipeTransform {
  transform(appointmentTypeId: number, examTypeId?: number): string {
    if (appointmentTypeId == AppointmentTypes.EXAM) {
      if (examTypeId) return 'Exame ' + ExamTypesArray[examTypeId - 1]
      return 'Exame'
    } else if (appointmentTypeId === AppointmentTypes.OFFLINE) {
      return 'Consulta Presencial'
    } else if (appointmentTypeId === AppointmentTypes.ONLINE) {
      return 'Consulta Online'
    } else if (appointmentTypeId === AppointmentTypes.CONTROL) {
      return 'Consulta Controlo'
    } else {
      return 'Consulta'
    }
  }
}

@Pipe({
  name: 'appointmentTypeExtraInfo'
})
export class AppointmentTypeWithExtraInfoPipe implements PipeTransform {
  transform(appointment: Appointment, examTypeId?: number): string {
    let appointmentTypeId = appointment.appointment_type_id
    if (appointmentTypeId == AppointmentTypes.EXAM) {
      if (examTypeId) return 'Exame ' + ExamTypesArray[examTypeId - 1]
      return 'Exame'
    } else if (appointmentTypeId === AppointmentTypes.OFFLINE) {
      return 'Consulta Presencial' + (AppointmentUtils.isAppointmentFirstAppointment(appointment) ? ' (1ª Consulta)' : '')
    } else if (appointmentTypeId === AppointmentTypes.ONLINE) {
      return 'Consulta Online' + (AppointmentUtils.isAppointmentFirstAppointment(appointment) ? ' (1ª Consulta)' : '')
    } else if (appointmentTypeId === AppointmentTypes.CONTROL) {
      return 'Consulta Controlo'
    } else {
      return 'Consulta'
    }
  }
}