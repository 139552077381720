<div class="toast-container">
  <ngb-toast
    *ngFor="let toast of toastService.toasts"
    [autohide]="true"
    [delay]="toast.delay || 5000"
    (hidden)="toastService.remove(toast)"
    (click)="toastService.clickedToast(toast)"
    [style.background-color]="toast.toastStyle?.color"
  >
    <div class="icon-body">
      <img [src]="toast.toastStyle?.icon" alt="" />
      <div class="description-body">
        <span class="fontSemiBold18 fontColorWhite">
          {{ toast.header }}
        </span>
        <div class="fontLight15 fontColorWhite" style="white-space: pre-wrap">
          {{ toast.body }}
        </div>
      </div>
    </div>
  </ngb-toast>
</div>
