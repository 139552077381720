import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core'
import { AuthService } from 'src/app/services/auth.service'

@Directive({
  selector: '[appShowForRoles]'
})
export class ShowForRoleDirective {
  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private authService: AuthService
  ) {}

  @Input() set appShowForRoles(showForRoles: number[]) {
    const allowedRoles = showForRoles

    // Current user roles
    const token = this.authService.getUserToken()
    const userRoles = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString('binary')).roleIds

    const shouldShow = allowedRoles.some((role) => userRoles.includes(role))

    if (!shouldShow) {
      this.viewContainer.clear() // hide the element
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef) // show the element
    }
  }
}
