<div class="main-content-sidebar">
  <div class="main-content">
    <div class="header">
      <span class="title">{{ "information" | translate }}</span>
      <img class="close-button" src="../../../../assets/custom-icons/dark_close_icon.svg" (click)="closeSidebar()" />
    </div>

    <div class="rows">
      <div class="info-single-row">
        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ "id_number" | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{ complaintBookNumber || "" }}</span>
        </div>

        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ "current_location" | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{ currentLog.store?.name || "" }}</span>
        </div>
      </div>

      <div class="rounded-white-background15 item">
        <span class="fontRegular10 fontColorMediumDarkGray">{{ "change_reason" | translate }}</span>
        <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{ currentLog.reason || "" }}</span>
      </div>

      <div class="rounded-white-background15 item">
        <span class="fontRegular10 fontColorMediumDarkGray">{{ "history" | translate }}</span>
        <div class="timeline-container">
          <div class="timeline">
            <div *ngFor="let log of historyLog">
              <div class="container">
                <div class="left">
                  <p class="fontRegular10 fontColorMediumGray">{{ log.createdAt | date : "d MMM" }}</p>
                  <p class="fontRegular10 fontColorMediumGray">{{ log.createdAt | date : "y" }}</p>
                </div>
                <div class="right">
                  <p class="fontRegular10 fontColorDefaultGray">{{ log.previous_store?.name || "Sem loja anterior" }}</p>
                  <p class="fontRegular10 fontColorMediumGray">{{ log.reason || "Primeiro registo" }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
