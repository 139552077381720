import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { firstValueFrom } from 'rxjs'
import { environment } from 'src/environments/environment'
import { ApiResponse } from '../models/apiResponse'
import { Code } from '../models/code'
import { BodyParams, createHttpBody } from '../utils/http-body'
import { ApiExpressResponse } from '../models/apiExpressResponse'
import { Appointment } from '../models/appointment'
import { Person } from '../models/person'
import { GenerateTokenBody } from '../models/generateTokenBody'
import { Document } from '../models/document'
import { AppointmentHasDocument } from '../models/appointment-has-document'

@Injectable({
  providedIn: 'root'
})
export class TeleheartAuthService {
  private url = `${environment.url}/patient-authentication` //server

  constructor(private http: HttpClient) { }

  getAuthenticationTokenAsHeader(): HttpHeaders {
    const jwtToken = localStorage.getItem('patientToken')

    if (!jwtToken) {
      return new HttpHeaders()
    }

    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: jwtToken
    })
  }

  createDocument(
    path: string,
    size: number,
    document_type_id: number
  ): Promise<ApiExpressResponse<Document>> {
    return firstValueFrom(
      this.http.post<ApiExpressResponse<Document>>(
        `${this.url}/document-appointment/`,
        { path, size, document_type_id },
        { headers: this.getAuthenticationTokenAsHeader() }
      )
    )
  }

  addDocumentToAppointment(
    document_id: number,
    appointment_id: number
  ): Promise<ApiExpressResponse<AppointmentHasDocument>> {
    return firstValueFrom(
      this.http.post<ApiExpressResponse<AppointmentHasDocument>>(
        `${this.url}/add-document-appointment/`,
        { document_id, appointment_id },
        { headers: this.getAuthenticationTokenAsHeader() }
      )
    )
  }

  getPatientInfo(): Person | undefined {
    let resultPatientInfo = JSON.parse(localStorage.getItem('patientInfo')!)

    if (!resultPatientInfo) {
      return undefined
    }

    return resultPatientInfo as Person
  }

  updatePatientInfo(updatedPatientInfo: any) {
    localStorage.setItem('patientToken', updatedPatientInfo.extra)
    localStorage.setItem('patientInfo', JSON.stringify(updatedPatientInfo.result.patient))
  }

  updateAppointmentInfo(updatedAppointmentInfo: any) {
    localStorage.setItem('appointmentInfo', JSON.stringify(updatedAppointmentInfo))
  }

  searchForCode(code: string): Promise<ApiExpressResponse<Appointment>> {
    return firstValueFrom(
      this.http.post<ApiExpressResponse<Appointment>>(`${this.url}/code-login`, { code })
    )
  }

  getAppointmentDocuments(appointmentId: number): Promise<ApiExpressResponse<AppointmentHasDocument[]>> {
    return firstValueFrom(
      this.http.get<ApiExpressResponse<AppointmentHasDocument[]>>(
        `${this.url}/appointment-documents/${appointmentId}`,
        { headers: this.getAuthenticationTokenAsHeader() }
      )
    )
  }

  patientCheckIn(appointmentCode: string): Promise<ApiExpressResponse<Appointment>> {
    return firstValueFrom(
      this.http.post<ApiExpressResponse<Appointment>>(
        `${this.url}/patient-check-in`,
        {
          appointmentCode
        },
        { headers: this.getAuthenticationTokenAsHeader() }
      )
    )
  }

  getAppointmentInfoForPatientByCode(code: string): Promise<ApiExpressResponse<Appointment>> {
    return firstValueFrom(
      this.http.get<ApiExpressResponse<Appointment>>(`${this.url}/appointment-info/${code}`, {
        headers: this.getAuthenticationTokenAsHeader()
      })
    )
  }

  patientGenerateAzureToken(): Promise<GenerateTokenBody> {
    return firstValueFrom(
      this.http.get<GenerateTokenBody>(`${this.url}/generate_azure_token`, {
        headers: this.getAuthenticationTokenAsHeader()
      })
    )
  }

  getAgoraToken(appointmentId: number | string): Promise<any> {
    return firstValueFrom(
      this.http.get<any>(`${this.url}/get_agora_token/${appointmentId}`, {
        headers: this.getAuthenticationTokenAsHeader()
      })
    )
  }

  getAppointmentState(appointmentId: number): Promise<ApiExpressResponse<Appointment>> {
    return firstValueFrom(
      this.http.get<ApiExpressResponse<Appointment>>(
        `${this.url}/appointment-state/${appointmentId}`,
        { headers: this.getAuthenticationTokenAsHeader() }
      )
    )
  }

  confirmPatientInfo(
    patientId: number,
    contact: string,
    healthNumber: string,
    dateOfBirth: string,
    appointmentCode: string
  ): Promise<ApiExpressResponse<Appointment>> {
    // Include JWT token in the headers
    const jwtToken = localStorage.getItem('patientToken')

    if (!jwtToken) {
      // return an error if the JWT token is not found
      return Promise.reject('JWT token not found')
    }

    return firstValueFrom(
      this.http.post<ApiExpressResponse<Appointment>>(
        `${this.url}/confirm-patient-info`,
        { patientId, contact, healthNumber, dateOfBirth, appointmentCode },
        { headers: this.getAuthenticationTokenAsHeader() }
      )
    )
  }
}
