<div class="main-content-sidebar">
  <div class="main-content">
    <div class="header">
      <span class="title">{{ "notifications" | translate }}</span>
      <img class="close-button" src="../../../../assets/custom-icons/dark_close_icon.svg" (click)="closeSidebar()" />
    </div>
    <div *ngFor="let ticket of recentTickets" class="ticket-card">
      <span class="fontRegular10 fontColorPrimary">Título</span>
      <div style="display: flex; gap: 3px">
        <span class="fontColorMediumDarkGray fontRegular10">0000000</span>
        <span class="fontColorMediumDarkGray fontRegular10">|</span>
        <span class="fontColorMediumDarkGray fontRegular10">5 min atrás</span>
      </div>
    </div>
  </div>
</div>
