import { Component, OnDestroy, OnInit } from '@angular/core'
import { environment } from 'src/environments/environment'
import * as Sentry from '@sentry/angular-ivy'
import { AuthService } from 'src/app/services/auth.service'

@Component({
  selector: 'app-default-footer',
  templateUrl: './default-footer.component.html',
  styleUrls: ['./default-footer.component.scss']
})
export class DefaultFooterComponent implements OnInit {
  version = environment.appVersion
  footerText = 'Todos os direitos reservados © 2024 Clínica de Cardiologia Drº Machado Lemos'

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    Sentry.setTag('accountId', this.authService.getLoggedInUserId().toString())
  }
}
