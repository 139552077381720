<div class="main-content-sidebar">
  <div class="main-content" #notificationsSidebarComponent>
    <div class="flexColumn5" style="margin-bottom: 2em">
      <div class="header" style="margin-bottom: 0">
        <span class="title">{{ 'notifications' | translate }}</span>
        <img
          class="close-button"
          src="../../../../assets/custom-icons/dark_close_icon.svg"
          (click)="closeSidebar()"
        />
      </div>
      <span class="pointer fontColorMediumDarkGray fontRegular10" (click)="markAllAsRead()">{{
        'mark_all_read' | translate
      }}</span>
    </div>

    <div
      *ngFor="let item of notificationsArray"
      class="notification-card"
      (click)="clickedNotification(item)"
    >
      <div style="width: 100%; display: flex; justify-content: space-between">
        <span class="fontColorMediumDarkGray fontSemiBold10">{{ item.title }}</span>
        <div [class]="item.seen ? 'dot-seen' : 'dot-unseen'"></div>
      </div>
      <span class="fontRegular10 fontColorDefaultGray">{{ item.description }}</span>
      <div style="display: flex; justify-content: space-between">
        <span class="fontColorMediumDarkGray fontRegular10">{{ item.id }}</span>
        <span class="fontColorMediumDarkGray fontRegular10">{{
          item.createdAt | date : 'dd-MM-yyyy - H:mm'
        }}</span>
      </div>
    </div>
    <div
      style="display: flex; justify-content: center; margin: 1em"
      class="fontSemiBold12 fontColorMediumDarkGray pointer"
    >
      <span *ngIf="isButtonShowMoreVisible" (click)="loadMoreNotifications()"
        >Carregar mais...</span
      >
      <span *ngIf="!isButtonShowMoreVisible">Todas as notificações carregadas</span>
    </div>
  </div>
</div>
