import { Component, Input, OnInit } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-reopen-ticket-reason-modal',
  templateUrl: './reopen-ticket-reason-modal.component.html',
  styleUrls: ['./reopen-ticket-reason-modal.component.scss']
})
export class ReopenTicketReasonModalComponent implements OnInit {
  message!: string
  reasons = ['Não foi Resolvido', 'Outro']
  customMessage = ''

  constructor(public modalService: NgbActiveModal) {}

  ngOnInit(): void {}

  confirmAction() {
    if (!this.message?.length) return

    if (this.message == 'Outro') {
      if (!this.customMessage?.length) return
      this.modalService.close('Situação Reaberta com o motivo: ' + this.customMessage)
    } else {
      this.modalService.close('Situação Reaberta com o motivo: ' + this.message)
    }
  }

  dismissAction() {
    this.modalService.dismiss()
  }
}
