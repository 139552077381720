import { Injectable } from '@angular/core'
import { CommonUtils } from 'src/app/utils/commonUtils'
import { FileService } from './file.service'
import { PDFDocument, PDFImage, PDFPage, StandardFonts, TextAlignment, layoutMultilineText, rgb } from 'pdf-lib'
import { ExamRequestGroup } from '../models/exam-request-group'
import { Person } from '../models/person'
import { AppToastService, ToastType } from './app-toast-service.service'

@Injectable({
  providedIn: 'root'
})
export class GeneratePdfService {
  constructor(
    private fileService: FileService,
    private toastService: AppToastService
  ) { }

  async generateDeclarationPresence(imagePath: string, title: string, text: string, scheduledDate?: any) {
    const pdfDoc = await PDFDocument.create()
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
    const titleTextSize = 18
    const bodyTextSize = 12

    const page = pdfDoc.addPage()

    const margin = 80

    const { width, height } = page.getSize()

    if (!imagePath) {
      return
    }

    const imageExtension = CommonUtils.fileExtensionFromFileName(CommonUtils.fileNameFromFilePath(imagePath))

    const imageBlob = await this.fileService.downloadFile(imagePath)

    let pdfImage: PDFImage | undefined = undefined

    if (imageExtension == 'png') {
      pdfImage = await pdfDoc.embedPng(
        (await CommonUtils.blobToArrayBuffer(imageBlob)) as ArrayBuffer
      )

    } else if (imageExtension == 'jpg' || imageExtension == 'jpeg') {
      pdfImage = await pdfDoc.embedJpg(
        (await CommonUtils.blobToArrayBuffer(imageBlob)) as ArrayBuffer
      )
    }

    if (!pdfImage) {
      return
    }

    const imageDim = pdfImage.scaleToFit(200, 100)

    page.drawImage(pdfImage, {
      x: margin,
      y: height - margin / 2 - imageDim.height,
      width: imageDim.width,
      height: imageDim.height
    })

    const titleWidth = helveticaFont.widthOfTextAtSize(title, titleTextSize)

    page.drawText(title, {
      x: width / 2 - titleWidth / 2,
      y: height - 250,
      size: titleTextSize,
      font: helveticaFont,
    })

    page.drawText(text, {
      x: margin,
      y: height - 350,
      size: bodyTextSize,
      font: helveticaFont,
      maxWidth: width - margin * 2
    })

    const friendlyDate = CommonUtils.extendedFriendlyDateFormat(new Date(scheduledDate) || new Date())

    const dateWidth = helveticaFont.widthOfTextAtSize(friendlyDate, bodyTextSize)

    page.drawText(friendlyDate, {
      x: width - margin - dateWidth,
      y: height - 550,
      size: 12,
      font: helveticaFont,
    })

    page.drawLine({
      start: { x: 300, y: height - 650 },
      end: { x: width - margin, y: height - 650 },
      thickness: 1
    })

    const blob = new Blob([await pdfDoc.save()], { type: 'application/pdf' })
    CommonUtils.openPdfInTab(blob)
  }

  async generateRequestedExamsPdf(reqExamsGroup: ExamRequestGroup, patientInfo?: Person, openInTab: boolean = true): Promise<Blob | undefined> {
    const pdfDoc = await PDFDocument.create()
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
    const titleTextSize = 25
    const bodyTextSize = 12
    const spacingSize = 20
    patientInfo = patientInfo || reqExamsGroup.patient

    console.log('doctor: ', reqExamsGroup.doctor);

    const page = pdfDoc.addPage()

    const margin = 80

    const { width, height } = page.getSize()
    let currentheight = height

    const textHeight = helveticaFont.sizeAtHeight(bodyTextSize)

    const imageBlob = await CommonUtils.getImageAsBlob('assets/custom-icons/ml_logo.jpg')

    let pdfImage: PDFImage | undefined = undefined

    pdfImage = await pdfDoc.embedJpg(
      (await CommonUtils.blobToArrayBuffer(imageBlob)) as ArrayBuffer
    )

    if (!pdfImage) {
      return
    }

    const imageDim = pdfImage.scaleToFit(300, 200)

    currentheight = currentheight - imageDim.height - spacingSize * 2
    page.drawImage(pdfImage, {
      x: width / 2 - imageDim.width / 2,
      y: currentheight,
      width: imageDim.width,
      height: imageDim.height
    })

    currentheight -= spacingSize * 2
    const title = 'Pedido de Exames'
    const titleWidth = helveticaFont.widthOfTextAtSize(title, titleTextSize)
    page.drawText(title, {
      x: width / 2 - titleWidth / 2,
      y: currentheight,
      size: titleTextSize,
      font: helveticaFont,
      color: rgb(0, 0.56, 0.77)
    })

    currentheight -= spacingSize * 2
    const friendlyDate = CommonUtils.extendedFriendlyDateFormat(new Date())
    const dateWidth = helveticaFont.widthOfTextAtSize(friendlyDate, bodyTextSize)
    page.drawText(friendlyDate, {
      x: width / 2 - dateWidth / 2,
      y: currentheight,
      size: bodyTextSize,
      font: helveticaFont,
    })

    currentheight -= spacingSize
    const patientName = `Paciente: ${patientInfo.name}${patientInfo.dateOfBirth ? `, ${CommonUtils.getAge(patientInfo.dateOfBirth)}` : ''}`
    const patientNameWidth = helveticaFont.widthOfTextAtSize(patientName, bodyTextSize)
    page.drawText(patientName, {
      x: width / 2 - patientNameWidth / 2,
      y: currentheight,
      size: bodyTextSize,
      font: helveticaFont,
      color: rgb(0, 0, 0)
    })

    // add convention name / number
    if (patientInfo.conventionName && patientInfo.conventionNumber) {
      currentheight -= spacingSize
      const conventionInfo = `Convenção: ${patientInfo.conventionName} / ${patientInfo.conventionNumber}`
      const conventionMl = layoutMultilineText(conventionInfo, {
        font: helveticaFont,
        fontSize: bodyTextSize,
        alignment: TextAlignment.Center,
        bounds: {
          x: margin,
          y: currentheight,
          width: width - margin * 2,
          height: currentheight - spacingSize
        }
      })

      conventionMl.lines.forEach(line => {
        page.drawText(line.text, {
          x: line.x,
          y: currentheight,
          size: bodyTextSize,
          font: helveticaFont,
          color: rgb(0, 0, 0)
        })
      })
    }

    if (reqExamsGroup.motive) {
      currentheight -= spacingSize
      const motiveInfo = `Motivo da requisição: ${reqExamsGroup.motive}`
      const motiveMl = layoutMultilineText(motiveInfo, {
        font: helveticaFont,
        fontSize: bodyTextSize,
        alignment: TextAlignment.Center,
        bounds: {
          x: margin,
          y: currentheight,
          width: width - margin * 2,
          height: currentheight - spacingSize
        }
      })

      motiveMl.lines.forEach(line => {
        page.drawText(line.text, {
          x: line.x,
          y: currentheight,
          size: bodyTextSize,
          font: helveticaFont,
          color: rgb(0, 0, 0)
        })

        currentheight -= line.height + 5
      })
    }

    currentheight -= spacingSize * 2
    const text = reqExamsGroup.exam_requests.map(req => req.exam_request_type.name)
    text.forEach((exam, index) => {
      page.drawCircle({
        x: margin,
        y: currentheight + 6,
        size: 3,
        color: rgb(0, 0, 0)
      })

      page.drawText(exam, {
        x: margin + 15,
        y: currentheight,
        size: bodyTextSize,
        font: helveticaFont,
        maxWidth: width - margin * 2,
        lineHeight: 15
      })

      // check how many times 60 characters fit inside the exam string
      const examLength = exam.length
      const maxChars = 60
      const lines = Math.ceil(examLength / maxChars)
      currentheight -= lines * textHeight
      currentheight -= 10
    })

    let docBarCodeImage: PDFImage | undefined
    try {
      await this.fileService.downloadFile(reqExamsGroup.doctor?.request_barcode?.path!).then(async (res) => {
        // if is png, use embedPng, if is jpg, use embedJpg
        const imageExtension = CommonUtils.fileExtensionFromFileName(CommonUtils.fileNameFromFilePath(reqExamsGroup.doctor?.request_barcode?.path!))
        if (imageExtension == 'png') {
          docBarCodeImage = await pdfDoc.embedPng(
            (await CommonUtils.blobToArrayBuffer(res)) as ArrayBuffer
          )
        } else {
          docBarCodeImage = await pdfDoc.embedJpg(
            (await CommonUtils.blobToArrayBuffer(res)) as ArrayBuffer
          )
        }
      })
    } catch (error) {
      this.toastService.show(ToastType.ERROR, "Erro ao gerar o PDF", "Código de barras do médico não encontrado")
      return
    }

    if (!docBarCodeImage) {
      this.toastService.show(ToastType.ERROR, "Erro ao gerar o PDF", "Código de barras do médico não encontrado")
      return
    }

    const docBarCodeImageDim = docBarCodeImage.scaleToFit(100, 50)

    page.drawImage(docBarCodeImage, {
      x: width - margin - docBarCodeImageDim.width,
      y: margin,
      width: docBarCodeImageDim.width,
      height: docBarCodeImageDim.height
    })

    const blob = new Blob([await pdfDoc.save()], { type: 'application/pdf' })

    if (openInTab) {
      CommonUtils.openPdfInTab(blob)
      return
    }

    return blob
  }

}
