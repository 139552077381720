import { Injectable } from '@angular/core'

import { FormCanDeactivate } from '../utils/formCanDeactivate'
import { FeathersjsService } from '../services/feathersjs.service'

@Injectable()
export class FormGuard {
  constructor(private featherService: FeathersjsService) {}

  async canDeactivate(component: FormCanDeactivate) {
    if (component.canDeactivate()) {
      if (confirm('Pretende sair da página sem guardar as mudanças?')) {
        await this.unlockExam(component.examIdNumber)
        return true
      } else {
        return false
      }
    }
    await this.unlockExam(component.examIdNumber)
    return true
  }

  async unlockExam(examId?: number) {
    console.log('unlocking exam: ', examId)

    if (examId) {
      this.featherService.socket.emit(
        'unlockExam',
        'exams',
        { examId: examId },
        {},
        (error: any, data: any) => {
          console.log('Called unlock exam', data)
        }
      )
    }
  }
}
