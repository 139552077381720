<div class="main-content-sidebar">
  <div class="main-content">
    <div class="header">
      <span class="title">{{ 'information' | translate }}</span>
      <img
        class="close-button"
        src="../../../../assets/custom-icons/dark_close_icon.svg"
        (click)="closeSidebar()"
      />
    </div>
    <div class="rows">
      <div class="rounded-white-background15 item">
        <span class="fontRegular10 fontColorMediumDarkGray">{{ 'name' | translate }}</span>
        <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
          accountInfo.name
        }}</span>
      </div>

      <div class="rounded-white-background15 item">
        <span class="fontRegular10 fontColorMediumDarkGray">{{ 'email' | translate }}</span>
        <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
          accountInfo.email
        }}</span>
      </div>

      <div class="info-single-row">
        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{
            'phone_number' | translate
          }}</span>
          <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
            accountInfo.contact.phone
          }}</span>
        </div>

        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ 'tax_number' | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{ '' }}</span>
        </div>
      </div>

      <!-- <div class="rounded-white-background15 item" *ngIf="accountInfo.account_has_accesses.length > 0">
        <span class="fontRegular10 fontColorMediumDarkGray">{{ "store" | translate }}</span>
        <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{ getStores() }}</span>
      </div> -->

      <div class="rounded-white-background15 item">
        <span class="fontRegular10 fontColorMediumDarkGray">{{ 'address' | translate }}</span>
        <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
          accountInfo.address?.street || 'Não tem'
        }}</span>
      </div>

      <div class="info-single-row">
        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ 'city' | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
            accountInfo.address?.city || 'Não tem'
          }}</span>
        </div>

        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ 'post_code' | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray" style="cursor: pointer">{{
            accountInfo.address?.zip || 'Não tem'
          }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
