import { Injectable } from '@angular/core'
import { AzureService } from './azure.service'
import { CommonUtils } from '../utils/commonUtils'
import { LoaderService } from './loader.service'
import { AppToastService, ToastType } from './app-toast-service.service'

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(
    private azureService: AzureService,
    private loaderService: LoaderService,
    private toastService: AppToastService
  ) {}

  async downloadFile(filePath: string, showLoader = true, containerGroup: string | undefined = undefined): Promise<Blob> {
    return new Promise((resolve, reject) => {
      // get azure token before download
      this.azureService
        .getAzureToken()
        .then(async res => {
          if (showLoader) {
            this.loaderService.show()
          }
          // Download and convert a blob to a string
          // const downloadBlockBlobResponse = await (await this.azureService.downloadFromAzure(res, filePath)).download();

          await this.azureService
            .downloadFromAzure(res, filePath, containerGroup)
            .then(async res => {
              const downloadBlockBlobResponse = await res?.download()

              let blobBody = await downloadBlockBlobResponse!.blobBody!

              resolve(blobBody)
            })
            .catch(err => {
              this.toastService.show(ToastType.ERROR, 'Erro', 'Erro ao descarregar ficheiro')
              console.log('error download file: ', err)
            })

          this.loaderService.hide()
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  async patientDownloadFile(filePath: string, showLoader = true): Promise<Blob> {
    return new Promise((resolve, reject) => {
      // get azure token before download
      this.azureService
        .patientGetAzureToken()
        .then(async res => {
          if (showLoader) {
            this.loaderService.show()
          }

          await this.azureService
            .downloadFromAzure(res, filePath)
            .then(async res => {
              const downloadBlockBlobResponse = await res?.download()

              let blobBody = await downloadBlockBlobResponse!.blobBody!

              resolve(blobBody)
            })
            .catch(err => {
              this.toastService.show(ToastType.ERROR, 'Erro', 'Erro ao descarregar ficheiro')
              console.log('error download file: ', err)
            })

          this.loaderService.hide()
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  async uploadFile(selectedFile: File, selectedFileName: string, showLoader = true): Promise<any> {
    return new Promise((resolve, reject) => {
      this.azureService
        .getAzureToken()
        .then(async res => {
          const byteFile = await CommonUtils.getAsByteArray(selectedFile!)

          //upload file to azure
          this.azureService
            .uploadToAzure(res, selectedFileName, byteFile, showLoader)
            .then(res => {
              resolve(res)
            })
            .catch(err => {
              reject(err)
            })
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  async patientUploadFile(
    selectedFile: File,
    selectedFileName: string,
    onProgressCallback?: (progress: number) => void,
    showLoader = true
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.azureService
        .patientGetAzureToken()
        .then(async res => {
          const byteFile = await CommonUtils.getAsByteArray(selectedFile!)

          //upload file to azure
          this.azureService
            .patientUploadToAzure(res, selectedFileName, byteFile, onProgressCallback, showLoader)
            .then(res => {
              resolve(res)
            })
            .catch(err => {
              reject(err)
            })
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}
