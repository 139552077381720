import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, UrlTree, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { AuthService } from '../services/auth.service'
import { UserRoles } from '../utils/userRoles'

@Injectable()
export class RoleGuard {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const allowedRoles: any[] = next.data['roles']

    // Current user roles
    const token = this.authService.getUserToken()
    const userRoles = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString('binary')).roleIds

    const shouldShow = allowedRoles.some((role) => userRoles.includes(role))

    return shouldShow
  }
}
