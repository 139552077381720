<div class="main-content-sidebar">
  <div class="main-content">
    <div class="header">
      <div>
        <span class="title">{{ 'information' | translate }}</span>
        <p class="fontColorDarkGray fontRegular10">{{ 'for_more_data_edit_store' | translate }}</p>
      </div>
      <img
        class="close-button"
        src="../../../../assets/custom-icons/dark_close_icon.svg"
        (click)="closeSidebar()"
      />
    </div>
    <div class="rows">
      <div class="rounded-white-background15 item">
        <span class="fontRegular10 fontColorMediumDarkGray">{{ 'store_name' | translate }}</span>
        <span class="fontRegular10 fontColorDefaultGray">{{ storeInfo.name }}</span>
      </div>

      <div class="rounded-white-background15 item">
        <span class="fontRegular10 fontColorMediumDarkGray">{{ 'address' | translate }}</span>
        <span class="fontRegular10 fontColorDefaultGray">{{
          storeInfo.address?.street || 'Sem morada'
        }}</span>
      </div>

      <div class="info-single-row">
        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ 'city' | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray">{{
            storeInfo.address?.city || '-'
          }}</span>
        </div>

        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ 'post_code' | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray">{{
            storeInfo.address?.zip || '-'
          }}</span>
        </div>
      </div>

      <div class="info-single-row">
        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{
            'phone_number' | translate
          }}</span>
          <span class="fontRegular10 fontColorDefaultGray">{{ storeInfo.contact.phone }}</span>
        </div>
      </div>

      <div class="info-single-row">
        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ 'responsible' | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray">??</span>
        </div>

        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ 'manager' | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray">??</span>
        </div>
      </div>

      <div class="rounded-white-background15 item">
        <span class="fontRegular10 fontColorMediumDarkGray">{{ 'email' | translate }}</span>
        <span class="fontRegular10 fontColorDefaultGray">{{ storeInfo.email || 'Sem email' }}</span>
      </div>

      <div class="rounded-white-background15 item">
        <span class="fontRegular10 fontColorMediumDarkGray">{{ 'company' | translate }}</span>
        <span class="fontRegular10 fontColorDefaultGray">{{ storeInfo.organization?.name }}</span>
      </div>

      <div class="info-single-row">
        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ 'price' | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray">??</span>
        </div>

        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ 'invoicing' | translate }}</span>
          <span class="fontRegular10 fontColorDefaultGray">??</span>
        </div>
      </div>
    </div>
  </div>
</div>
