<div class="main-content-sidebar">
  <div class="main-content">
    <div class="header">
      <span class="title">{{ "information" | translate }}</span>
      <img
        class="close-button"
        src="../../../../assets/custom-icons/dark_close_icon.svg"
        (click)="closeSidebar()"
      />
    </div>

    <div class="rows">
      <div class="info-single-row">
        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{
            "id_number" | translate
          }}</span>
          <span
            class="fontRegular10 fontColorDefaultGray"
            style="cursor: pointer"
            >{{ currentLog.number }}</span
          >
        </div>

        <div class="rounded-white-background15 item">
          <span class="fontRegular10 fontColorMediumDarkGray">{{
            "material_type" | translate
          }}</span>
          <span
            class="fontRegular10 fontColorDefaultGray"
            style="cursor: pointer"
            >{{ currentLog.materialType }}</span
          >
        </div>
      </div>

      <div class="rounded-white-background15 item">
        <span class="fontRegular10 fontColorMediumDarkGray">{{
          "current_location" | translate
        }}</span>
        <span
          class="fontRegular10 fontColorDefaultGray"
          style="cursor: pointer"
          >{{ currentLog.currentLocation }}</span
        >
      </div>

      <div class="rounded-white-background15 item">
        <span class="fontRegular10 fontColorMediumDarkGray">{{
          "history" | translate
        }}</span>
        <div class="timeline-container">
          <div class="timeline">
            <div class="container" *ngFor="let log of historyLog">
              <div class="left">
                <p class="fontRegular10 fontColorMediumGray">
                  {{ log.createdAt | date : "d MMM" }}
                </p>
                <p class="fontRegular10 fontColorMediumGray">
                  {{ log.createdAt | date : "y" }}
                </p>
              </div>
              <div class="right">
                <p class="fontRegular10 fontColorDefaultGray">
                  {{ log.current_store?.name || "Sem loja anterior" }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
