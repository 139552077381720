import { Component, Input, OnInit } from "@angular/core";
import { SidebarLoaderService } from "../../../services/sidebar-loader.service";
import { Exam } from "../../../models/exam";
import { CommonUtils } from "../../../utils/commonUtils";
import { ExamReportUtils } from "../../../utils/examReportUtils";
import { ReportState } from "../../../utils/reportStates";
import { Router } from "@angular/router";

@Component({
  selector: "app-exam-info-sidebar",
  templateUrl: "./exam-info-sidebar.component.html",
  styleUrls: ["./exam-info-sidebar.component.scss"],
})
export class ExamInfoSidebarComponent implements OnInit {
  @Input()
  examInfo!: Exam;
  examMedication!: string;
  examSymptoms!: string;
  examNotes!: string;
  examIsReported = false;
  examPlacementDate!: string;
  examWakeUpTime!: string;
  examSleepTime!: string;

  constructor(private sidebarLoaderService: SidebarLoaderService, private router: Router) {}

  ngOnInit(): void {
    this.examMedication = ExamReportUtils.findExamPropertyValueByName("medication", this.examInfo.exam_proprieties);
    this.examSymptoms = ExamReportUtils.findExamPropertyValueByName("symptoms", this.examInfo.exam_proprieties);
    this.examNotes = ExamReportUtils.findExamPropertyValueByName("notes", this.examInfo.exam_proprieties);
    this.examPlacementDate = CommonUtils.friendlyDateFormat(
      new Date(ExamReportUtils.findExamPropertyValueByName("examPlacementDateTime", this.examInfo.exam_proprieties))
    );
    this.examWakeUpTime = ExamReportUtils.findExamPropertyValueByName("patientWakeUpTime", this.examInfo.exam_proprieties);
    this.examSleepTime = ExamReportUtils.findExamPropertyValueByName("patientSleepTime", this.examInfo.exam_proprieties);

    if (this.examInfo.reports.length > 0) {
      if (
        this.examInfo.reports[this.examInfo.reports.length - 1].status_id == ReportState.SIGNED ||
        this.examInfo.reports[this.examInfo.reports.length - 1].status_id == ReportState.WAITINGAPPROVAL
      ) {
        this.examIsReported = true;
      }
    }
  }

  seeReport() {
    this.router.navigate(["/dashboard/exams/see-report/" + this.examInfo.id]);
  }

  closeSidebar() {
    this.sidebarLoaderService.closeSidebar();
  }
}
