import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { Exam } from '../models/exam'
import { firstValueFrom } from 'rxjs'
import { createHttpParams } from '../utils/http-params'

@Injectable({
  providedIn: 'root'
})
export class RequestExamService {
  private url = `${environment.url}/request-exam/`

  constructor(private httpClient: HttpClient) {}

  async requestExam(examTypeId?: number): Promise<Exam[]> {
    const httpParams = createHttpParams({
      exam_type_id: examTypeId
    })
    return firstValueFrom(this.httpClient.get<Exam[]>(`${this.url}`, { params: httpParams }))
  }
}
