import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ConnectionService } from "src/app/services/connection.service";

@Component({
  selector: "app-user-offline-modal",
  templateUrl: "./user-offline-modal.component.html",
  styleUrls: ["./user-offline-modal.component.scss"],
})
export class UserOfflineModalComponent {
  constructor(public modalService: NgbActiveModal, private connectionService: ConnectionService) {}

  dismissModal() {
    this.modalService.dismiss();
  }

  tryAgain() {
    if (this.connectionService.getConnectionStatus()) {
    }
  }
}
