import { Component, OnInit } from "@angular/core";
import { SidebarLoaderService } from "src/app/services/sidebar-loader.service";
import { ComplaintBook } from "../../../models/complaint-book";
import { ComplaintBookLog } from "../../../models/complaint-book-log";

@Component({
  selector: "app-complaints-book-info-sidebar",
  templateUrl: "./complaints-book-info-sidebar.component.html",
  styleUrls: ["./complaints-book-info-sidebar.component.scss"],
})
export class ComplaintsBookInfoSidebarComponent implements OnInit {
  complaintBookNumber!: string;
  currentLog!: ComplaintBookLog;
  historyLog!: ComplaintBookLog[];

  constructor(private sidebarLoaderService: SidebarLoaderService) {}

  ngOnInit(): void {}

  closeSidebar() {
    this.sidebarLoaderService.closeSidebar();
  }
}
