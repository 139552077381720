import { Component, OnInit } from '@angular/core'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { Exam } from 'src/app/models/exam'
import { SidebarLoaderService } from 'src/app/services/sidebar-loader.service'
import { CommonUtils } from 'src/app/utils/commonUtils'
import { Person } from '../../../models/person'
import { ReportService } from '../../../services/report.service'
import { PdfPreviewModalComponent } from '../../modals/pdf-preview-modal/pdf-preview-modal.component'
import { FileService } from 'src/app/services/file.service'
import { GenerateReportService } from 'src/app/services/generate-report.service'

@Component({
  selector: 'app-patient-info-modal',
  templateUrl: './patient-info-sidebar.component.html',
  styleUrls: ['./patient-info-sidebar.component.scss']
})
export class PatientInfoSidebarComponent implements OnInit {
  patientInfo!: Person
  activeTab = 1
  modalRef!: NgbModalRef

  constructor(
    private sidebarLoaderService: SidebarLoaderService,
    private fileService: FileService,
    private generateReportService: GenerateReportService
  ) {}

  ngOnInit(): void {}

  closeSidebar() {
    this.sidebarLoaderService.closeSidebar()
  }

  changeTab(tab: number) {
    if (tab != this.activeTab) {
      this.activeTab = tab
    }
  }

  async previewReport(patientExam: Exam) {
    if (patientExam.external && patientExam.document_id_document_exam_has_documents.length) {
      const examDocument = patientExam.document_id_document_exam_has_documents![0]

      const name = CommonUtils.fileNameFromFilePath(examDocument.path)
      const type = CommonUtils.getFileTypeFromTypeId(examDocument.document_type_id)

      this.fileService.downloadFile(examDocument.path).then(res => {
        if (res.type == 'application/pdf') {
          CommonUtils.openPdfInTab(res)
        } else {
          CommonUtils.openImageInNewTab(res, name, type)
        }
      })
    } else if (patientExam) {
      await this.generateReportService.downloadSignedReport(patientExam.id)
    }
  }
}
