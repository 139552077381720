import { HttpClient, HttpEvent, HttpRequest, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, Subject, delay, filter, fromEvent, map, mapTo, merge, of, take } from 'rxjs'

type OfflineRequest = {
  url: string
  method: string
  body: any
}

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {
  private connectionStatus = new Subject<boolean>()

  constructor(private http: HttpClient) {
    merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(map((value: Event, index: number) => true)),
      fromEvent(window, 'offline').pipe(map((value: Event, index: number) => false))
    )
      .pipe(delay(0))
      .subscribe((isOnline) => {
        this.connectionStatus.next(isOnline)
        if (isOnline) {
          this.retryOfflineRequests()
        }
      })
  }

  private retryOfflineRequests(): void {
    const offlineRequest = localStorage.getItem('offlineRequest')
    if (offlineRequest) {
      const request = JSON.parse(offlineRequest)
      this.http.request(request.method, request.url, { body: request.body }).subscribe(() => {
        localStorage.removeItem('offlineRequest')
        this.retryOfflineRequests()
      })
    }
  }

  getConnectionStatus(): Subject<boolean> {
    return this.connectionStatus
  }

  public cacheOfflineRequest(request: () => Observable<HttpRequest<any>>): void {
    console.log('Caching offline request')
    const offlineRequest = request().pipe(
      filter((event: HttpRequest<any>) => event instanceof HttpRequest),
      take(1),
      map((event: HttpRequest<any>) => {
        return {
          url: event.url,
          method: 'GET',
          body: event.body
        } as OfflineRequest
      })
    )
    offlineRequest.subscribe((data) => {
      localStorage.setItem('offlineRequest', JSON.stringify(data))
    })
  }
}
