import { ReportPropriety } from '../models/report-propriety'
import { ExamPropriety } from '../models/exam-propriety'
import { CommonUtils } from './commonUtils'
import { Exam } from '../models/exam'
import { Document } from '../models/document'
import { DocumentFileTypes } from './documentFileTypes'

export interface EcoFormInputSelectValue {
  name: string
}

export enum EcoCustomInputName {
  VALVESTRUCTURES = 'valveStructuresInputValues',
  RIGHTVENTRICLE = 'rightVentricleInputValues',
  ACCOUSTICWINDOW = 'accousticWindowInputValues',
  RHYTHM = 'windowInputValues'
}

export class ExamReportUtils {
  static checkIfSimilarExamExists(
    patientIdentNumber: string,
    examTypeId: number,
    examList: Exam[]
  ): Exam | null {
    for (const exam of examList) {
      if (exam.patient.identNumber == patientIdentNumber && exam.exam_type_id == examTypeId) {
        return exam
      }
    }
    return null
  }

  static findReportPropertyValueByName(
    name: string,
    reportProperties: ReportPropriety[]
  ): string | null {
    for (const property of reportProperties) {
      if (property.name == name) {
        return property.value!
      }
    }
    return null
  }

  static getReportPropertyByName(
    name: string,
    reportProperties: ReportPropriety[]
  ): ReportPropriety | null {
    for (const property of reportProperties) {
      if (property.name == name) {
        return property
      }
    }
    return null
  }

  static addOrUpdateExamPropertyLocally(
    examProperty: ExamPropriety,
    examProperties: ExamPropriety[]
  ): ExamPropriety[] {
    let searchExamProperty = ExamReportUtils.getExamPropertyByName(
      examProperty.name,
      examProperties
    )

    // if property already exists, update it, else, create new one
    if (searchExamProperty) {
      for (const property of examProperties) {
        if (property.id == examProperty.id) {
          property.value = examProperty.value
        }
      }
    } else {
      examProperties.push(examProperty)
    }

    return examProperties
  }

  static findExamPropertyValueByName(name: string, examProperties: ExamPropriety[]): string {
    for (const property of examProperties) {
      if (property.name == name) {
        return property.value!
      }
    }
    return ''
  }

  static getExamPropertyByName(
    name: string,
    examProperties: ExamPropriety[]
  ): ExamPropriety | null {
    for (const property of examProperties) {
      if (property.name == name) {
        return property
      }
    }
    return null
  }

  static transformRegularTimeToReportTime(time: string): string {
    if (time) {
      const splittedTime = time.split(':')
      const hours = splittedTime[0]
      const minutes = splittedTime[1]

      return `${hours}h${minutes}`
    }
    return ''
  }

  static transformDateInputFormatToReportDateFormat(date: string): string {
    if (date) {
      let splittedDate = date.split('-')
      return `${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`
    }
    return ''
  }

  static transformDateTimeInputFormatToReportDateTimeFormat(dateTime: string): string {
    if (dateTime) {
      let splittedDateTimeArr = dateTime.split('T')
      let splittedDateTime =
        splittedDateTimeArr.length > 1 ? splittedDateTimeArr : dateTime.split(' ')
      let splittedDate = splittedDateTime[0].split('-')
      let splittedTime = splittedDateTime[1].split(':')
      return `${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]} - ${splittedTime[0]}h${splittedTime[1]}`
    }
    return ''
  }

  static transformDateTimeInputFormatToReportDateFormat(dateTime: string): string {
    if (dateTime) {
      let splittedDateTime = dateTime.split('T')
      let splittedDate = splittedDateTime[0].split('-')
      return `${splittedDate[2]}/${splittedDate[1]}/${splittedDate[0]}`
    }
    return ''
  }

  static generateReportNumber(examId: string): string {
    let randomNumber = Math.round(Math.random() * 100000)

    return randomNumber + new Array(6 - examId.length).fill('0').join('') + examId
  }

  static getEcoCustomInputValues(inputName: EcoCustomInputName): EcoFormInputSelectValue[] {
    let values = localStorage.getItem(inputName)

    if (values) {
      return JSON.parse(values) as EcoFormInputSelectValue[]
    } else {
      return []
    }
  }

  static addOrRemoveEcoInputValue(
    name: string,
    inputName: EcoCustomInputName
  ): EcoFormInputSelectValue[] {
    let values = this.getEcoCustomInputValues(inputName)
    let submittedValue = { name: name } as EcoFormInputSelectValue

    const isFound = values.some(element => {
      if (element.name === submittedValue.name) {
        return true
      }
      return false
    })

    if (isFound) {
      values = this.deleteItemFromEcoValuesArray<EcoFormInputSelectValue>(values, submittedValue)
    } else {
      values.push(submittedValue)
    }

    localStorage.setItem(inputName, JSON.stringify(values))
    return values
  }

  static valueAlreadyExistsInEcoValuesArray<T extends { name: string }>(
    arr: T[],
    value: string
  ): boolean {
    const isFound = arr.some(element => {
      if (element.name === value) {
        return true
      }
      return false
    })

    return isFound
  }

  static deleteItemFromEcoValuesArray<T extends { name: string }>(arr: T[], value: T): T[] {
    return arr.filter((val: T) => val.name !== value.name)
  }

  static addOrUpdateReportProperty(
    property: ReportPropriety,
    reportProperties: ReportPropriety[]
  ): ReportPropriety[] {
    let searchReportProperty = ExamReportUtils.getReportPropertyByName(
      property.name!,
      reportProperties
    )

    // if property already exists, update it, else, create new one
    if (searchReportProperty) {
      for (const reportProperty of reportProperties) {
        if (reportProperty.id == property.id) {
          reportProperty.value = property.value
        }
      }
    } else {
      reportProperties.push(property)
    }

    return reportProperties
  }

  static insertExamInfoExamsList(
    currentExamsList: Exam[],
    receivedExam: Exam,
    listOrder: string,
    considerPriority: boolean
  ): { index: number; replace: boolean } {
    // Find the index where the new exam should be inserted
    const foundIndex = currentExamsList.findIndex(exam => exam.id == receivedExam.id)

    if (foundIndex >= 0) {
      return { index: foundIndex, replace: true }
    }

    for (let i = 0; i < currentExamsList.length; i++) {
      const currentExam = currentExamsList[i]

      if (considerPriority) {
        if (currentExam.priority === 0) {
          return { index: i, replace: false }
        } else if (receivedExam.priority! >= currentExam.priority!) {
          return { index: i, replace: false }
        }
      } else if (currentExam.priority == 0) {
        if (listOrder === 'ASC') {
          if (receivedExam.exam_date! < currentExam.exam_date!) {
            return { index: i, replace: false }
          }
        } else {
          if (receivedExam.exam_date! > currentExam.exam_date!) {
            return { index: i, replace: false }
          }
        }
      }
    }
    return { index: currentExamsList.length, replace: false }
  }

  static checkIfExamFilesAreAllPdf(examAttachments: Document[]): boolean {
    let allPdf = true

    for (let examFile of examAttachments) {
      if (examFile.document_type_id != DocumentFileTypes.pdf) {
        allPdf = false
      }
    }

    return allPdf
  }
}

export const deleteFromArray = <T>(arr: T[], value: T): T[] => {
  return arr.filter((val: T) => val !== value)
}
