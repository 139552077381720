// Role / id in database
export enum UserRoles {
  BASIC = 1,
  STORELEVEL1 = 2,
  STORELEVEL2 = 3,
  SALES = 4,
  TECHLEVEL1 = 5,
  TECHLEVEL2 = 6,
  EXTERNALTECH = 7,
  SUPERVISOR = 8,
  MANAGER = 9,
  DOCTOR = 10,
  ADMIN = 11,
  ESP = 12,
  PATIENT = 13,
  VIDEOCONSULTATION = 14
}
