import { environment } from './../../environments/environment'
import { Injectable } from '@angular/core'
import { BehaviorSubject, firstValueFrom } from 'rxjs'
import { NotificationModel } from '../models/notification-model'
import { HttpClient } from '@angular/common/http'
import { ApiResponse } from '../models/apiResponse'
import { createHttpBody } from '../utils/http-body'
import { createHttpParams } from '../utils/http-params'
import { GenericRealTimeService } from './generic-real-time.service'

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private url = `${environment.url}/notifications/`
  updatedNotifications = new BehaviorSubject<NotificationModel[]>([])
  newNotificationsCount = new BehaviorSubject<number>(0)
  showGetMoreNotificationsButton = new BehaviorSubject<boolean>(true)
  allNotifications: NotificationModel[] = []
  currentPage = 0
  allNotificationsCount!: number
  fetchedCount = 0
  notificationServiceTimeout!: NodeJS.Timeout

  constructor(
    private httpClient: HttpClient,
    private genericRealTimeService: GenericRealTimeService
  ) {}

  addNotificationByAccountId(
    account_id: number,
    title: string,
    description?: string
  ): Promise<NotificationModel> {
    const body: any = createHttpBody({
      account_id: account_id,
      title: title,
      description: description,
      date: new Date().toISOString()
    })

    body['seen'] = 0
    return firstValueFrom(this.httpClient.post<NotificationModel>(this.url, body))
  }

  async initNotificationsObservables(accountId: number) {
    this.allNotifications = (
      await this.getNotificationsByAccountId(accountId, this.currentPage)
    ).rows
    this.updatedNotifications.next(this.allNotifications)
  }

  async loadMoreNotifications(accountId: number) {
    this.currentPage++
    const moreNotifications = await this.getNotificationsByAccountId(accountId, this.currentPage)
    if (moreNotifications.rows.length) {
      this.allNotifications = [...this.allNotifications, ...moreNotifications.rows]
      this.updatedNotifications.next(this.allNotifications)
    }
  }

  async getNotificationsByAccountId(
    accountId: number,
    currentPage: number
  ): Promise<ApiResponse<NotificationModel>> {
    let httpParams = createHttpParams({
      page: currentPage,
      size: 40,
      account_id: accountId
    })

    try {
      const observable = this.httpClient.get<ApiResponse<NotificationModel>>(`${this.url}`, {
        params: httpParams
      })
      const response = await firstValueFrom(observable)
      this.allNotificationsCount = response.count
      this.fetchedCount += response.rows.length
      if (this.fetchedCount >= this.allNotificationsCount) {
        this.showGetMoreNotificationsButton.next(false)
      }
      return response
    } catch (error) {
      throw error
    }
  }

  async newNotificationAdded(newNotificaion: NotificationModel) {
    this.allNotifications = [newNotificaion, ...this.allNotifications]
    this.updatedNotifications.next(this.allNotifications)

    this.allNotificationsCount++
    const currentValue = this.genericRealTimeService.newNotificationsCount.getValue()
    const updatedValue = currentValue + 1
    this.genericRealTimeService.newNotificationsCount.next(updatedValue)
  }

  async updateNotification(notificationId: number, seen: number): Promise<NotificationModel> {
    return firstValueFrom(
      this.httpClient.put<NotificationModel>(`${this.url}${notificationId}`, { seen: seen })
    )
  }
}
