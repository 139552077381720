import { Injectable } from '@angular/core'
import { LoaderService } from './loader.service' // Import your loader service here
import { FeathersjsService } from './feathersjs.service'
import { SocketRequestBody } from '../models/socket-request-body'

@Injectable()
export class SocketInterceptorService {
  constructor(private loaderService: LoaderService, private feathersService: FeathersjsService) {}
  makeCustomRequest(
    resourceName: string,
    customMethod: string,
    body: Object = {},
    query?: Object
  ): Promise<any> {
    this.loaderService.show() // Show the loading bar
    return new Promise((resolve, reject) => {
      this.feathersService.socket.emit(
        customMethod,
        resourceName,
        body,
        query,
        (error: any, result: any) => {
          if (error) {
            reject(error)
          } else {
            resolve(result)
          }

          this.loaderService.hide()
        }
      )
    })
  }

  makeGetRequest(resourceName: string, resourceId: number, queryParams?: Object): Promise<any> {
    this.loaderService.show() // Show the loading bar
    return new Promise((resolve, reject) => {
      this.feathersService.socket.emit(
        'get',
        resourceName,
        resourceId,
        queryParams,
        (error: any, result: any) => {
          if (error) {
            reject(error)
          } else {
            resolve(result)
          }

          this.loaderService.hide()
        }
      )
    })
  }

  makeFindRequest(resourceName: string, queryParams?: Object): Promise<any> {
    this.loaderService.show() // Show the loading bar
    return new Promise((resolve, reject) => {
      this.feathersService.socket.emit(
        'find',
        resourceName,
        queryParams,
        (error: any, result: any) => {
          if (error) {
            reject(error)
          } else {
            resolve(result)
          }

          this.loaderService.hide()
        }
      )
    })
  }

  makeCreateRequest(resourceName: string, body?: Object): Promise<any> {
    this.loaderService.show() // Show the loading bar
    return new Promise((resolve, reject) => {
      this.feathersService.socket.emit('create', resourceName, body, (error: any, result: any) => {
        if (error) {
          reject(error)
        } else {
          resolve(result)
        }

        this.loaderService.hide()
      })
    })
  }

  private shouldShowLoader(eventType: string, resourceName: string): boolean {
    return true
  }

  private shouldHideLoader(eventType: string, resourceName: string): boolean {
    // Add logic to determine when to hide the loading bar for socket requests
    // For example, return true if eventType is 'get' for reports
    return true
  }
}
