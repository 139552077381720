import { Component, OnInit } from "@angular/core";
import { Store } from "src/app/models/store";
import { SidebarLoaderService } from "src/app/services/sidebar-loader.service";

@Component({
  selector: "app-store-info-sidebar",
  templateUrl: "./store-info-sidebar.component.html",
  styleUrls: ["./store-info-sidebar.component.scss"],
})
export class StoreInfoSidebarComponent implements OnInit {
  storeInfo!: Store;

  constructor(private sidebarLoaderService: SidebarLoaderService) {}

  ngOnInit(): void {}

  closeSidebar() {
    this.sidebarLoaderService.closeSidebar();
  }
}
