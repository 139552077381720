import { Component, OnInit } from '@angular/core'
import { InvoiceInfo } from 'src/app/models/invoice-info'
import { SidebarLoaderService } from 'src/app/services/sidebar-loader.service'

@Component({
  selector: 'app-invoice-info-sidebar',
  templateUrl: './invoice-info-sidebar.component.html',
  styleUrls: ['./invoice-info-sidebar.component.scss']
})
export class InvoiceInfoSidebarComponent implements OnInit {
  invoiceInfo!: InvoiceInfo

  constructor(private sidebarLoaderService: SidebarLoaderService) {}

  ngOnInit(): void {}

  closeSidebar() {
    this.sidebarLoaderService.closeSidebar()
  }

  openLinkInNewTab(docLink: string | undefined) {
    window.open(docLink, '_blank')
  }
}
