<div class="modal-header">
  <span>{{ title | translate }}</span>
  <button *ngIf="closeButton" type="button" class="btn-close" aria-label="Close"
    (click)="modalService.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <p class="fontRegular12 fontColorDarkGray" style="white-space: pre-line;" [innerHTML]="body"></p>
</div>
<div class="modal-footer">
  <button *ngIf="cancelButtonLabel" type="button" class="secondary-button" (click)="modalService.close(false)">
    {{ cancelButtonLabel | translate }}
  </button>
  <button *ngIf="confirmButtonLabel" type="button" class="primary-button" (click)="modalService.close(true)">
    {{ confirmButtonLabel | translate }}
  </button>
</div>