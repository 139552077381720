import { Pipe, PipeTransform } from '@angular/core'
import { AuthService } from '../services/auth.service'
import { UserRoles } from '../utils/userRoles'

@Pipe({
  name: 'userRole'
})
export class UserRolePipe implements PipeTransform {
  constructor(private authService: AuthService) {}

  transform(allowedRoles: UserRoles[]): boolean {
    const payload = JSON.parse(
      Buffer.from(this.authService.getUserToken().split('.')[1], 'base64').toString('binary')
    ).roleIds as number[]
    const value = allowedRoles.some((role) => payload.includes(role))
    return value
  }
}
