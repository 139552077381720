<div class="main-content-sidebar">
  <div class="header-container">
    <div class="header-info">
      <div style="display: flex; justify-content: space-between">
        <div class="input-icon-container">
          <div class="ticket-state">
            <span class="fontSemiBold11 fontColorDefaultGray"
              >{{ 'ticket_state' | translate }}:
              {{ ticketInfo.issue_state.name || 'unknown' | translate }}</span
            >
            <span
              class="fontSemiBold11 fontColorDefaultGray"
              *ngIf="([userRoles.SUPERVISOR] | userRole) && ticketInfo?.closed_by_account?.name"
            >
              {{ 'closed_by' | translate }}:
              {{ ticketInfo.closed_by_account.name || 'unknown' | translate }}
            </span>
          </div>
          <div
            *ngIf="
              ticketInfo.issue_state_id == issueStates.NEW ||
              ticketInfo.issue_state_id == issueStates.OPEN
            "
            (click)="closeTicketBtn()"
            class="option-button"
          >
            <span class="fontRegular10">{{ 'close_ticket' | translate }}</span>
          </div>
          <div
            *ngIf="
              (ticketInfo.issue_state_id == issueStates.CLOSED &&
                this.ticketInfo.created_by == currentLoggedInUserId) ||
              (ticketInfo.issue_state_id == issueStates.CLOSED &&
                ([userRoles.SUPERVISOR] | userRole))
            "
            (click)="reopenTicketBtn()"
            class="option-button"
          >
            <span class="fontRegular10">{{ 'reopen_ticket' | translate }}</span>
          </div>
        </div>
        <img
          src="../../../../assets/custom-icons/dark_close_icon.svg"
          style="cursor: pointer"
          (click)="closeSidebar()"
          alt=""
        />
      </div>
      <!-- department info -->
      <div style="display: flex; flex-direction: column; margin: 1em 0">
        <span class="fontSemiBold16 fontColorDefaultGray"> {{ ticketInfo.title }} </span>
        <div>
          <ng-container *ngIf="[userRoles.SUPERVISOR, userRoles.MANAGER] | userRole">
            <img
              src="../../../../assets/custom-icons/edit_pencil_blue.svg"
              alt="Mudar Departamento"
              class="pointer"
              (click)="showChangeDepartmentDropdown = !showChangeDepartmentDropdown"
            />
          </ng-container>
          <span class="fontRegular12 fontColorMediumDarkGray">
            {{ ticketInfo.issue_department.name | translate }} - {{ ticketInfo.id }}
          </span>
        </div>
        <ng-container *ngIf="([userRoles.SUPERVISOR, userRoles.MANAGER] | userRole) && showChangeDepartmentDropdown">
          <div class="flexRow5">
            <select class="form-input-whitebg" [(ngModel)]="selectedDepartmentId">
              <option value="" disabled selected>{{ 'change_department' | translate }}</option>
              <ng-container *ngFor="let department of issueDepartments">
                <option
                  [value]="department.id"
                  *ngIf="department.id != ticketInfo.issue_department_id"
                >
                  {{ department.name | translate }}
                </option>
              </ng-container>
            </select>
            <button *ngIf="selectedDepartmentId" class="button-with-img">
              <img
                src="../../../../assets/custom-icons/check_icon.svg"
                alt="Confirmar"
                (click)="changeDepartment()"
              />
            </button>
          </div>
        </ng-container>
      </div>
      <div style="display: flex; justify-content: space-between; align-items: center; width: 100%">
        <div style="display: flex; gap: 10px; align-items: center">
          <img src="../../../../assets/custom-icons/sample_avatar.svg" alt="" />
          <span class="fontRegular10 fontColorMediumDarkGray">{{
            ticketInfo.created_by_account.name
          }}</span>
        </div>
        <span class="fontRegular10 fontColorMediumDarkGray">{{
          ticketInfo.createdAt | date : 'dd-MM-yyyy, HH:mm:ss'
        }}</span>
      </div>
      <div style="display: flex; justify-content: space-between; align-items: center; width: 100%">
        <div style="display: flex; gap: 10px; align-items: center">
          <span class="fontRegular10 fontColorMediumDarkGray">{{ ticketInfo.store?.name }}</span>
        </div>
        <span class="fontRegular10 fontColorMediumDarkGray">{{
          ticketInfo.store?.contact?.phone ?? ticketInfo.store?.responsibleContact
        }}</span>
      </div>
      <div style="display: flex; justify-content: space-between; align-items: center; width: 100%">
        <div style="display: flex; gap: 10px; align-items: center">
          <span class="fontRegular10 fontColorMediumDarkGray"
            >{{ ticketInfo.store?.address?.street }} {{ ticketInfo.store?.address?.zip }}
            {{ ticketInfo.store?.address?.city }}</span
          >
        </div>
      </div>
      <ng-container class="accounts-associated-ticket" *ngIf="[userRoles.SUPERVISOR] | userRole">
        <span class="fontSemiBold12 fontColorDarkGray">{{
          'accounts_associated_with_ticket' | translate
        }}</span>
        <div class="flexColumn5">
          <div *ngFor="let issueHasAccount of ticketInfo.issue_has_accounts">
            <div
              *ngIf="issueHasAccount.account_id != ticketInfo.created_by"
              class="flexRow5"
              style="align-items: center"
            >
              <div class="avatar-size">
                <span class="fontColorDarkGray">
                  {{ issueHasAccount?.account?.name?.length || 0 > 0 ? issueHasAccount.account.name?.[0] : '?' }}
                </span>
              </div>
              <span class="fontColorDefaultGray">{{ issueHasAccount.account.name || '' }}</span>
              <button
                class="btn"
                style="padding: 0"
                (click)="removeIssueAccount(issueHasAccount.account_id, issueHasAccount.issue_id)"
              >
                <img
                  style="height: 100%; width: 100%"
                  src="../../../../assets/custom-icons/delete_icon.svg"
                  alt=""
                />
              </button>
            </div>
          </div>
          <div class="add-user">
            <div *ngIf="showAddUser" class="flexRow5" style="align-items: center">
              <select class="form-input-whitebg" [(ngModel)]="selectedAccountId">
                <option value="" disabled selected>{{ 'select_user' | translate }}</option>
                <ng-container *ngFor="let user of allUsers">
                  <option *ngIf="user.id != ticketInfo.created_by" [value]="user.id">
                    {{ user.name }}
                  </option>
                </ng-container>
              </select>
              <div *ngIf="selectedAccountId">
                <img
                  (click)="confirmAddUser()"
                  class="pointer"
                  src="../../../../assets/custom-icons/check_icon.svg"
                  alt=""
                />
              </div>
            </div>
            <div *ngIf="!showAddUser">
              <img
                style="cursor: pointer; height: 30px"
                src="../../../../../assets/custom-icons/exam_form_add_language.svg"
                alt=""
                (click)="toggleAddUser()"
              />
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="main-content-sidebar">
    <div class="main-content">
      <!-- INFORMATION TAB -->
      <div>
        <div class="sub-header">
          <span class="fontSemiBold12 fontColorDarkGray">{{ 'description' | translate }}</span>
          <hr />
        </div>
        <div class="ticket-description">
          <p class="fontRegular12 fontColorDarkGray">
            {{ ticketInfo.description }}
          </p>
        </div>
      </div>
      <!-- Show ticket attachment -->
      <div class="ticket-attachments" *ngIf="ticketInfo.issue_has_documents?.length || 0 > 0">
        <div class="sub-header">
          <span class="fontSemiBold12 fontColorDarkGray">{{
            'ticket_attachments' | translate
          }}</span>
          <hr />
        </div>
        <ng-container *ngFor="let attachment of ticketInfo.issue_has_documents">
          <div class="single-attachment">
            <span class="fontRegular9 fontColorMediumDarkGray">{{
              attachment.document.path | fileNameFromDocumentPath
            }}</span>
            <button (click)="downloadAttachment(attachment.document.path)" class="button-with-img">
              <img src="../../../../assets/custom-icons/donwload_icon.svg" />
            </button>
          </div>
        </ng-container>
      </div>
      <!-- Comments tab -->
      <div>
        <div class="sub-header">
          <span class="fontSemiBold12 fontColorDarkGray">{{ 'comments' | translate }}</span>
          <hr />
        </div>
        <div class="comments-container">
          <div *ngIf="!ticketInfo.issue_comments?.length" style="width: 100%">
            <p class="fontRegular12 fontColorDarkGray" style="text-align: center">
              {{ 'no_comments' | translate }}
            </p>
          </div>
          <div
            class="comment"
            [ngClass]="{
              'message-right': comment.account_id === currentLoggedInUserId,
              'message-left': comment.account_id !== currentLoggedInUserId
            }"
            *ngFor="let comment of ticketInfo.issue_comments"
          >
            <div
              class="avatar-position avatar-size"
              [placement]="comment.account_id === currentLoggedInUserId ? 'top-right' : 'top-left'"
              [ngbTooltip]="comment.account?.name"
              tooltipClass="blue-bg-tooltip"
            >
              <span>
                {{ comment.account?.name?.length ? comment.account?.name?.[0] : '?' }}
              </span>
            </div>
            <span class="fontRegular9">{{ comment.createdAt | date : 'dd-MM, HH:mm' }}</span>
            <span class="fontRegular12">{{ comment.message }}</span>
            <div *ngFor="let file of comment.document_id_document_issue_comment_has_documents">
              <div style="display: flex; justify-content: space-between">
                <span class="fontRegular9">{{ file.path | fileNameFromDocumentPath }}</span>
                <button (click)="downloadAttachment(file.path)" class="button-with-img">
                  <img src="../../../../assets/custom-icons/donwload_icon.svg" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- add new comment -->
        <div
          class="flexColumn5"
          style="gap: 10px"
          *ngIf="
            ticketInfo.issue_state_id == issueStates.OPEN ||
            ticketInfo.issue_state_id == issueStates.NEW
          "
        >
          <div style="display: flex; justify-content: space-between; align-items: center">
            <div>
              <img
                src="../../../../assets/custom-icons/attach-button.svg"
                (click)="openFileInput()"
                class="pointer"
                alt=""
              />
              <input
                type="file"
                #fileInput
                (change)="onFileSelected($event)"
                style="display: none"
              />
            </div>

            <textarea
              [(ngModel)]="newCommentMessage"
              rows="2"
              class="fontRegular12 fontColorDarkGray"
              placeholder="{{ 'write_a_comment' | translate }}"
              style="
                border-radius: 5px;
                padding: 5px;
                border: none;
                border-bottom: 1px solid #869798;
                width: -webkit-fill-available;
                margin: 0px 10px;
                min-height: 150px;
              "
            ></textarea>

            <button (click)="addNewComment()" class="button-with-img">
              <img src="../../../../assets/custom-icons/send-button.svg" alt="" />
            </button>
          </div>
          <div class="attached-file" *ngIf="newCommentAttachedFiles.length">
            <div class="fontRegular12 fontColorDarkGray">
              {{ 'new_comment_attached_files' | translate }}
            </div>
            <div *ngFor="let file of newCommentAttachedFiles">
              <div style="display: flex; justify-content: space-between">
                <span class="fontRegular10">{{ file.name }}</span>
                <button (click)="removeSelectedFile(file)" class="button-with-img">
                  <img src="../../../../assets/custom-icons/delete_icon.svg" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
