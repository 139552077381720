import * as moment from 'moment';
import { Appointment } from '../models/appointment'
import { AppointmentProperty } from '../models/appointment-property'
import { HeaderInfoWarning } from '../models/header-info-warning'
import { ExamTypes } from './examTypes'
import { Timeslot } from '../models/timeslot'
import { Account } from '../models/account'
import { Exam } from '../models/exam'
import { ExamCount } from '../services/exams-real-time.service'
import { ExamRequestGroup } from '../models/exam-request-group'
import { AppointmentAvailability } from '../models/appointment-availability';

export class AppointmentUtils {
  static orderReceivedAppointments(appointments: Appointment[]): Appointment[] {
    return appointments.sort((a, b) => {
      // Move appointments with appointment_state_id of 5, 6, or 7 to the end
      const aState = [5, 6, 7].includes(a.appointment_state_id)
      const bState = [5, 6, 7].includes(b.appointment_state_id)

      if (aState && !bState) {
        return 1
      }
      if (!aState && bState) {
        return -1
      }

      // If both appointments have the same appointment_state_id, sort by scheduled_date
      if (a.scheduled_date && b.scheduled_date) {
        return new Date(a.scheduled_date).getTime() - new Date(b.scheduled_date).getTime()
      } else if (a.scheduled_date) {
        return -1
      } else if (b.scheduled_date) {
        return 1
      } else {
        return 0
      }
    })
  }

  static filterAppointmentsOfDay(appointments: Appointment[], day: Date): any[] {
    let filteredAppointments: any[] = []

    day.setHours(0, 0, 0, 0)

    const dateWithoutTime = day.getTime()

    filteredAppointments = appointments.filter(appointment => {
      try {
        const appointmentDate = new Date(appointment.scheduled_date!.toString().split('T')[0])

        return dateWithoutTime === appointmentDate.setHours(0, 0, 0, 0)
      } catch (error) {
        console.log('error filtering on appointment: ', appointment.id)
        return false
      }
    })

    return filteredAppointments
  }

  static checkAppointmentAvailabilitiesForExamType(examTypeId: number, appointmentAvailabilities: AppointmentAvailability[]): boolean {

    for (const availability of appointmentAvailabilities) {
      if (availability.exam_type) {
        const booleanArray = this.convertNumberToBooleanArray(availability.exam_type)
        if (booleanArray[examTypeId - 1]) {
          return true
        }
      }
    }

    return false
  }

  static convertNumberToBooleanArray(number: number): boolean[] {
    let result: boolean[] = []

    while (number > 0) {
      result.push(number % 2 === 1)
      number = Math.floor(number / 2)
    }

    return result
  }

  static getAllAppointmentDaysFromMonth(
    appointments: Appointment[],
    year: number,
    month: number
  ): any[] {
    let days: any[] = []

    appointments.forEach(appointment => {
      const appointmentObj = new Date(appointment.scheduled_date!)
      if (appointmentObj.getMonth() + 1 == month && appointmentObj.getFullYear() == year) {
        if (!days.includes(appointmentObj.getDate())) {
          days.push(appointmentObj.getDate())
        }
      }
    })

    return days
  }

  static infoMsgFromAppt(appointment: Appointment): HeaderInfoWarning {
    let warningInfo!: HeaderInfoWarning

    if (appointment.appointment_type_id < 3) {
      warningInfo = {
        name: `O paciente ${appointment.patient?.name ? appointment.patient?.name : ''
          } fez check-in.`,
        message: 'Clique no link para entrar na consulta.',
        type: 'link',
        appointmentDate: appointment.scheduled_date,
        appointmentId: appointment.id,
        appointmentTypeId: appointment.appointment_type_id,
        link:
          appointment.appointment_type_id == 1
            ? `/dashboard/appointments/offline-appointment/${appointment.id}`
            : `/dashboard/appointments/online-appointment?doctor=true&code=${appointment.code}`
      }
    } else {
      const appointmentTypeId = appointment.appointment_type_id
      const examType = ExamTypes[appointmentTypeId]
      const examId = appointment.exam_id
      const message = examId
        ? `Clique no link para ser redirecionado para o exame do tipo ${examType}.`
        : 'O paciente fez check in mas não tem um exame associado ao agendamento.'
      warningInfo = {
        name: `O paciente ${appointment.patient?.name ? appointment.patient?.name : ''
          } fez check-in.`,
        message: message,
        appointmentDate: appointment.scheduled_date,
        appointmentId: appointment.id,
        appointmentTypeId: appointmentTypeId,
        type: 'link',
        link: examId
          ? `/dashboard/submissions/store-exams-nofile?appointmentId=${appointment.exam_id}`
          : '/dashboard/submissions/store-exams-nofile'
      }
    }

    return warningInfo
  }

  static newExamReqInfo(reqGroup: ExamRequestGroup): HeaderInfoWarning {
    return {
      name: `Novo pedido requisiçao de exames`,
      message: `Paciente: ${reqGroup.patient.name}`,
      type: 'examRequestGroup',
      examRequestGroupId: reqGroup.id,
      examRequestGroup: reqGroup
    }
  }

  static getAllAppointmentFromDay(
    appointments: Appointment[],
    year: number,
    month: number,
    day: number
  ): Appointment[] {
    let filteredAppointments: Appointment[] = []

    if (appointments) {
      appointments.forEach(appointment => {
        const appointmentObj = new Date(appointment.scheduled_date!)
        if (
          appointmentObj.getMonth() + 1 == month &&
          appointmentObj.getFullYear() == year &&
          appointmentObj.getDate() == day
        ) {
          if (!filteredAppointments.includes(appointment)) {
            filteredAppointments.push(appointment)
          }
        }
      })
    }

    return filteredAppointments
  }

  static mergeAppointments(
    storeAppointments: any[],
    appointmentsToMerge: any[],
    date: Date
  ): any[] {
    let mergedAppointments: any[] = []

    storeAppointments.forEach(appointment => {
      if (appointment.doctor_id) {
        appointment.accountName = appointment.doctor.name
      } else {
        appointment.accountName = appointment.tech.name
      }
      mergedAppointments.push(appointment)
    })

    appointmentsToMerge.forEach(appointment => {
      if (new Date(appointment.scheduled_date!).toDateString() === date.toDateString()) {
        mergedAppointments.push(appointment)
      }
    })

    mergedAppointments.sort((a, b) => {
      const dateA = new Date(a.scheduled_date!)
      const dateB = new Date(b.scheduled_date!)

      return dateA.getTime() - dateB.getTime()
    })

    return mergedAppointments
  }

  static getAllAvailableAppointmentDates(
    scheduledAppointments: Appointment[],
    selectedDate: Date,
    accounts: Account[],
    blockDates = true,
    translateExceptionType: (type: string) => string
  ): Timeslot[] {
    const intervalBetweenAppointments = 15; // Interval in minutes

    // Create a map to store unique timeslots with account information
    const timeslotMap = new Map<number, Timeslot>();

    // Iterate over all accounts and their available timeslots
    accounts.forEach(account => {
      // Determine if the account has any exceptions on the selected date for the store
      const exceptionsForStore = account.appointment_availability_exceptions || [];

      // Separate full-day exceptions and timeslot exceptions
      const fullDayExceptions = exceptionsForStore.filter(exception => {
        return !exception.start_time && !exception.end_time;
      });

      const timeslotExceptions = exceptionsForStore.filter(exception => {
        return exception.start_time && exception.end_time;
      });

      // If there's a full-day exception, mark all timeslots as exceptions for this account
      const hasFullDayException = fullDayExceptions.length > 0;

      account.appointment_availabilities.forEach(availability => {

        // Create start and end times for the availability using Moment.js
        const timeslotStart = moment(selectedDate)
          .hour(parseInt(availability.start_time.split(':')[0], 10))
          .minute(parseInt(availability.start_time.split(':')[1], 10))
          .second(0)
          .millisecond(0);

        const timeslotEnd = moment(selectedDate)
          .hour(parseInt(availability.end_time.split(':')[0], 10))
          .minute(parseInt(availability.end_time.split(':')[1], 10))
          .second(0)
          .millisecond(0);

        let controlTime = timeslotStart.clone();

        // Create all available times within each timeslot's range
        while (controlTime <= timeslotEnd) { // Changed to < to prevent overlapping
          const timeKey = controlTime.valueOf(); // Unix timestamp in milliseconds

          if (!timeslotMap.has(timeKey)) {
            timeslotMap.set(timeKey, {
              time: controlTime.toDate(),
              scheduled: false,
              details: [],
              availableAccounts: [],
              onExceptionAccounts: []
            });
          }

          const currentTimeslot = timeslotMap.get(timeKey)!;

          if (hasFullDayException) {
            // Add to onExceptionAccounts if not already present
            if (!currentTimeslot.onExceptionAccounts.some(acc => acc.account.id === account.id)) {
              const translatedType = translateExceptionType(fullDayExceptions[0].type);
              currentTimeslot.onExceptionAccounts.push({ account, exceptionReason: translatedType });
            }
          } else {
            // Check for timeslot-specific exceptions
            const timeslotException = timeslotExceptions.find(exception => {
              const exceptionStart = moment(selectedDate)
                .hour(parseInt(exception.start_time!.split(':')[0], 10))
                .minute(parseInt(exception.start_time!.split(':')[1], 10))
                .second(0)
                .millisecond(0);

              const exceptionEnd = moment(selectedDate)
                .hour(parseInt(exception.end_time!.split(':')[0], 10))
                .minute(parseInt(exception.end_time!.split(':')[1], 10))
                .second(0)
                .millisecond(0);

              return controlTime.isSameOrAfter(exceptionStart) && controlTime.isSameOrBefore(exceptionEnd);
            });

            if (timeslotException) {
              // Add to onExceptionAccounts if not already present
              if (!currentTimeslot.onExceptionAccounts.some(acc => acc.account.id === account.id)) {
                const translatedType = translateExceptionType(timeslotException.type);
                currentTimeslot.onExceptionAccounts.push({ account, exceptionReason: translatedType });
              }
            } else {
              // Add to availableAccounts if not already present
              if (!currentTimeslot.availableAccounts.some(acc => acc.id === account.id)) {
                currentTimeslot.availableAccounts.push(account);
              }
            }
          }

          // Increment the control time by the interval
          controlTime.add(intervalBetweenAppointments, 'minutes');
        }
      });
    });

    // Mark slots that are already scheduled
    scheduledAppointments.forEach((scheduledAppointment: Appointment) => {
      const scheduledDate = moment(scheduledAppointment.scheduled_date);

      const timeKey = scheduledDate.valueOf();

      if (timeslotMap.has(timeKey)) {
        const timeSlot = timeslotMap.get(timeKey)!;
        timeSlot.scheduled = blockDates;
        timeSlot.details.push(scheduledAppointment);
      } else {
        // If the scheduled time is outside the defined timeslots, add it
        timeslotMap.set(timeKey, {
          time: scheduledDate.toDate(),
          scheduled: blockDates,
          details: [scheduledAppointment],
          availableAccounts: [],
          onExceptionAccounts: []
        });
      }
    });

    // Convert the map to an array and sort by time
    return Array.from(timeslotMap.values()).sort((a, b) => a.time.getTime() - b.time.getTime());
  }

  static getAppointmentPropertyByName(
    name: string,
    appointmentProperties?: AppointmentProperty[]
  ): AppointmentProperty | null {
    if (!appointmentProperties) {
      return null
    }

    for (const property of appointmentProperties) {
      if (property.name == name) {
        return property
      }
    }
    return null
  }

  static countExams(res: any) {
    if (res && res.length > 0) {
      let availableExamsCount: ExamCount[] = []
      res.forEach((exam: Exam) => {
        if (availableExamsCount[exam.exam_type_id]) {
          const currentExamCount = availableExamsCount[exam.exam_type_id]?.count || 0
          const currentExamExams = availableExamsCount[exam.exam_type_id]?.exams || []

          availableExamsCount[exam.exam_type_id] = {
            count: currentExamCount + 1,
            exams: [...currentExamExams, exam]
          }
        } else {
          availableExamsCount[exam.exam_type_id] = {
            count: 1,
            exams: [exam]
          }
        }
      })

      return availableExamsCount
    }

    return []
  }

  static isAppointmentFirstAppointment(appointment: Appointment): boolean {
    let fA = AppointmentUtils.getAppointmentPropertyByName('firstAppointment', appointment.appointment_properties)?.value
    return fA === 'true'
  }
}
