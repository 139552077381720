<nav class="navbar-mobile">
  <div class="notifications" (click)="showNotificationsSidebar()">
    <img src="../../../assets/custom-icons/notification_bell_icon.svg" />
    <div class="notifications-counter">
      <span class="fontSemiBold10 fontColorWhite">{{ numOfNotifications }}</span>
    </div>
  </div>

  <a routerLink="/dashboard" class="logo">
    <img src="../../../assets/custom-icons/logo.svg" alt="" />
  </a>

  <div class="rightSideContainer">
    <a (click)="toggleSearchInput()">
      <img style="height: 20px" src="../../../assets/custom-icons/search_icon.svg" alt=""
    /></a>
    <div class="hamburger" [class.active]="classApplied" (click)="toggleClass()">
      <span class="bar"></span>
      <span class="bar"></span>
      <span class="bar" *ngIf="!classApplied"></span>
    </div>
  </div>
</nav>

<ul class="nav-menu" [class.active]="classApplied">
  <div class="profile-container">
    <img
      [src]="userProfilePic | safeHtml"
      style="width: 50px; height: 50px; border-radius: 50px; cursor: pointer"
    />
    <div class="user-info" (click)="toggleUserMenu()">
      <div style="display: flex; flex-direction: column">
        <span class="fontSemiBold13 fontColorDefaultGray">{{ userName }}</span>
        <span class="fontRegular10 fontColorDarkGray">{{ userRolesTranslated }}</span>
      </div>
      <img style="height: 10px" src="../../../assets/custom-icons/dropdown_icon.svg" alt="" />
    </div>
    <div class="user-settings-popover" *ngIf="showUserMenu">
      <span class="fontRegular12 fontColorMediumDarkGray" (click)="showUserSettingsSidebar()">{{
        'settings' | translate
      }}</span>
      <hr />
      <span class="fontRegular12 fontColorMediumDarkGray" routerLink="dashboard/edit-profile">{{
        'user_profile' | translate
      }}</span>
      <hr />
      <!-- <span class="fontRegular12 fontColorMediumDarkGray">Português 🇵🇹</span> -->
      <div class="user-selected-language" (click)="toggleAllLanguages()">
        <div style="display: flex; gap: 5px">
          <img [src]="selectedLanguage.flag" alt="" />
          <span class="fontRegular12 fontColorMediumDarkGray">{{
            selectedLanguage.description
          }}</span>
        </div>
        <img
          [src]="
            languagesCollapsed
              ? '../../../assets/custom-icons/arrow_down_icon.svg'
              : '../../../assets/custom-icons/arrow_up_icon.svg'
          "
        />
      </div>
      <div class="all-languages" *ngIf="!languagesCollapsed">
        <div *ngFor="let language of languagesList">
          <div
            *ngIf="language.prefix != selectedLanguage.prefix"
            class="language"
            (click)="newLanguageSelected(language)"
          >
            <img src="{{ language.flag }}" alt="" />
            <span class="fontRegular12 fontColorMediumDarkGray">{{ language.description }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <hr />

  <!-- EXAMS TAB -->
  <li
    class="sidebar-nav-item"
    (click)="toggleExamsSubMenu()"
    *appShowForRoles="roleGroups['examsTab']"
  >
    <div class="sidebar-nav-link" [class.item-active]="router.isActive('/dashboard/exams', false)">
      <div class="nav-link-text">
        <img src="../../../assets/custom-icons/sidebar_exams_icon.svg" alt="Exames" />
        <span class="fontSemiBold13 fontColorDefaultGray">{{ 'exams' | translate }}</span>
      </div>
      <img class="dropdown-icon" src="../../../assets/custom-icons/dropdown_icon.svg" />
    </div>
    <ul class="sidebar-sub-menu" *ngIf="showExamsSubMenu">
      <li
        *appShowForRoles="roleGroups['receptionExams']"
        class="sidebar-sub-menu-item"
        routerLink="dashboard/exams/reception-exams"
        routerLinkActive="sub-item-active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        {{ 'reception_exams' | translate }}
      </li>
      <li
        *appShowForRoles="roleGroups['reportExams']"
        class="sidebar-sub-menu-item"
        routerLink="dashboard/exams/report-exams"
        routerLinkActive="sub-item-active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        {{ 'report_exams' | translate }}
      </li>
      <li
        class="sidebar-sub-menu-item"
        routerLink="dashboard/exams/sign-exams"
        routerLinkActive="sub-item-active"
        *appShowForRoles="roleGroups['signExams']"
      >
        {{ 'sign_exams' | translate }}
      </li>
      <li
        *appShowForRoles="roleGroups['finalizedExams']"
        class="sidebar-sub-menu-item"
        routerLink="dashboard/exams/finalized-exams"
        routerLinkActive="sub-item-active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        {{ 'finalized_exams' | translate }}
      </li>

      <li
        *appShowForRoles="roleGroups['arquivedExams']"
        class="sidebar-sub-menu-item"
        routerLink="dashboard/exams/arquived-exams"
        routerLinkActive="sub-item-active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        {{ 'arquived_exams' | translate }}
      </li>
    </ul>
  </li>

  <!-- SUBMITTED EXAMS TAB FOR STORE WITHOUT ATTACHED FILE -->
  <li
    *appShowForRoles="roleGroups['storeSubmittedExams']"
    class="sidebar-nav-item"
    routerLink="dashboard/submissions/store-exams-nofile"
    (click)="closeAllSubMenus()"
  >
    <div class="sidebar-nav-link" routerLinkActive="item-active">
      <div class="nav-link-text">
        <img src="../../../assets/custom-icons/exams_no_file.svg" alt="Submissões" />
        <span class="fontSemiBold13 fontColorDefaultGray">{{
          'submitted_exams_no_file' | translate
        }}</span>
      </div>
    </div>
  </li>

  <!-- SUBMITTED EXAMS THAT NEED ATTENTION TAB FOR STORE -->
  <li
    class="sidebar-nav-item"
    routerLink="dashboard/submissions/store-exams-attention"
    (click)="closeAllSubMenus()"
    *appShowForRoles="roleGroups['storeSubmittedExamsAttention']"
  >
    <div class="sidebar-nav-link" routerLinkActive="item-active">
      <div class="nav-link-text">
        <img src="../../../assets/custom-icons/exams_to_repeat.svg" alt="Submissões" />
        <span class="fontSemiBold13 fontColorDefaultGray">{{
          'submitted_exams_attention' | translate
        }}</span>
      </div>
    </div>
  </li>

  <!-- SUBMITTED EXAMS TAB FOR STORE -->
  <li
    class="sidebar-nav-item"
    routerLink="dashboard/submissions/store-exams"
    (click)="closeAllSubMenus()"
    *appShowForRoles="roleGroups['storeSubmittedExams']"
  >
    <div class="sidebar-nav-link" routerLinkActive="item-active">
      <div class="nav-link-text">
        <img src="../../../assets/custom-icons/submitted_exams.svg" alt="Submissões" />
        <span class="fontSemiBold13 fontColorDefaultGray">{{ 'submitted_exams' | translate }}</span>
      </div>
    </div>
  </li>

  <!-- SUBMISSIONS TAB -->
  <li
    class="sidebar-nav-item"
    routerLink="dashboard/submissions/submit-exam"
    (click)="closeAllSubMenus()"
    *appShowForRoles="roleGroups['submitExam']"
  >
    <div class="sidebar-nav-link" routerLinkActive="item-active">
      <div class="nav-link-text">
        <img src="../../../assets/custom-icons/sidebar_submission_icon.svg" alt="Submissões" />
        <span class="fontSemiBold13 fontColorDefaultGray">{{ 'submit_exam' | translate }}</span>
      </div>
    </div>
  </li>

  <!-- APPOINTMENTS TAB -->
  <ng-container *ngIf="loggedInUserInfo.organization_id == 1">
    <li
      class="sidebar-nav-item"
      (click)="toggleTeleheartSubMenu()"
      *appShowForRoles="roleGroups['appointments']"
    >
      <div
        class="sidebar-nav-link"
        [class.item-active]="router.isActive('/dashboard/appointments', false)"
      >
        <div class="nav-link-text">
          <img src="../../../assets/custom-icons/sidebar_teleheart_icon.svg" alt="Consultas" />
          <span class="fontSemiBold13 fontColorDefaultGray">{{
            'appointment.appointments' | translate
          }}</span>
        </div>
        <img class="dropdown-icon" src="../../../assets/custom-icons/dropdown_icon.svg" />
      </div>

      <ul class="sidebar-sub-menu" *ngIf="showTeleheartSubMenu">
        <li
          class="sidebar-sub-menu-item"
          routerLink="dashboard/appointments/schedule-appointment"
          routerLinkActive="sub-item-active"
          [routerLinkActiveOptions]="{ exact: true }"
          *appShowForRoles="roleGroups['scheduleAppointments']"
        >
          {{ 'appointment.schedule_appointment' | translate }}
        </li>
        <li
          class="sidebar-sub-menu-item"
          routerLink="dashboard/appointments/check-appointments"
          routerLinkActive="sub-item-active"
          [routerLinkActiveOptions]="{ exact: true }"
          *appShowForRoles="roleGroups['checkAppointments']"
        >
          {{ 'appointment.check_appointments' | translate }}
        </li>
      </ul>
    </li>
  </ng-container>

  <!-- TICKETS TAB -->
  <li
    class="sidebar-nav-item"
    (click)="toggleTicketsSubMenu()"
    *appShowForRoles="roleGroups['ticketsTab']"
  >
    <div
      class="sidebar-nav-link"
      [class.item-active]="router.isActive('/dashboard/tickets', false)"
    >
      <div class="nav-link-text">
        <img src="../../../assets/custom-icons/sidebar_tickets_icon.svg" alt="Situações" />
        <span class="fontSemiBold13 fontColorDefaultGray">{{ 'situations' | translate }}</span>
      </div>
      <img class="dropdown-icon" src="../../../assets/custom-icons/dropdown_icon.svg" />
    </div>
    <ul class="sidebar-sub-menu" *ngIf="showTicketsSubMenu">
      <li
        *appShowForRoles="roleGroups['createTicket']"
        class="sidebar-sub-menu-item"
        routerLink="dashboard/tickets/create-ticket"
        routerLinkActive="sub-item-active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        {{ 'create_situation' | translate }}
      </li>
      <li
        *appShowForRoles="roleGroups['ticketsOverview']"
        class="sidebar-sub-menu-item"
        routerLink="dashboard/tickets/tickets-overview"
        routerLinkActive="sub-item-active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        {{ 'tickets_overview' | translate }}
      </li>
    </ul>
  </li>

  <!-- MANAGEMENT TAB -->
  <li
    class="sidebar-nav-item"
    (click)="toggleManagementSubMenu()"
    *appShowForRoles="roleGroups['managementTab']"
  >
    <div
      class="sidebar-nav-link"
      [class.item-active]="router.isActive('/dashboard/management', false)"
    >
      <div class="nav-link-text">
        <img src="../../../assets/custom-icons/sidebar_management_icon.svg" alt="Gestão" />
        <span class="fontSemiBold13 fontColorDefaultGray">{{ 'management' | translate }}</span>
      </div>
      <img class="dropdown-icon" src="../../../assets/custom-icons/dropdown_icon.svg" />
    </div>
    <ul class="sidebar-sub-menu" *ngIf="showManagementSubMenu">
      <li
        *appShowForRoles="roleGroups['companies']"
        class="sidebar-sub-menu-item"
        routerLink="dashboard/management/companies"
        routerLinkActive="sub-item-active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        {{ 'companies' | translate }}
      </li>
      <li
        *appShowForRoles="roleGroups['invoices']"
        class="sidebar-sub-menu-item"
        routerLink="dashboard/management/invoices"
        routerLinkActive="sub-item-active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        {{ 'invoices' | translate }}
      </li>
      <li
        *appShowForRoles="roleGroups['listings']"
        class="sidebar-sub-menu-item"
        routerLink="dashboard/management/listings"
        routerLinkActive="sub-item-active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        {{ 'listings' | translate }}
      </li>
      <li
        *appShowForRoles="roleGroups['complaints']"
        class="sidebar-sub-menu-item"
        routerLink="dashboard/management/complaints"
        routerLinkActive="sub-item-active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        {{ 'complaints_book' | translate }}
      </li>
      <li
        class="sidebar-sub-menu-item"
        routerLink="dashboard/management/stores"
        routerLinkActive="sub-item-active"
        [routerLinkActiveOptions]="{ exact: true }"
        *appShowForRoles="roleGroups['stores']"
      >
        {{ 'stores' | translate }}
      </li>
      <li
        *appShowForRoles="roleGroups['material']"
        class="sidebar-sub-menu-item"
        routerLink="dashboard/management/material"
        routerLinkActive="sub-item-active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        {{ 'material' | translate }}
      </li>
      <li
        *appShowForRoles="roleGroups['metrics']"
        class="sidebar-sub-menu-item"
        routerLink="dashboard/management/metrics"
        routerLinkActive="sub-item-active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        {{ 'metrics' | translate }}
      </li>
      <li
        *appShowForRoles="roleGroups['patients']"
        class="sidebar-sub-menu-item"
        routerLink="dashboard/management/patients"
        routerLinkActive="sub-item-active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        {{ 'patients' | translate }}
      </li>
      <li
        class="sidebar-sub-menu-item"
        routerLink="dashboard/management/people"
        routerLinkActive="sub-item-active"
        [routerLinkActiveOptions]="{ exact: true }"
        *appShowForRoles="roleGroups['people']"
      >
        {{ 'people' | translate }}
      </li>
      <li
        *appShowForRoles="roleGroups['receipts']"
        class="sidebar-sub-menu-item"
        routerLink="dashboard/management/receipts-deposits"
        routerLinkActive="sub-item-active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        {{ 'receipts_deposits' | translate }}
      </li>
      <li
        class="sidebar-sub-menu-item"
        routerLink="dashboard/management/store-access-request"
        routerLinkActive="sub-item-active"
        [routerLinkActiveOptions]="{ exact: true }"
        *appShowForRoles="roleGroups['storeAccessRequests']"
      >
        {{ 'store_access_request' | translate }}
      </li>
      <li
        class="sidebar-sub-menu-item"
        routerLink="dashboard/management/permissions"
        routerLinkActive="sub-item-active"
        [routerLinkActiveOptions]="{ exact: true }"
        *appShowForRoles="roleGroups['permissions']"
      >
        {{ 'permissions' | translate }}
      </li>
      <li
        class="sidebar-sub-menu-item"
        routerLink="dashboard/management/announcements"
        routerLinkActive="sub-item-active"
        [routerLinkActiveOptions]="{ exact: true }"
        *appShowForRoles="roleGroups['announcements']"
      >
        {{ 'announcements' | translate }}
      </li>
    </ul>
  </li>

  <!-- TOOLS TAB -->
  <li
    class="sidebar-nav-item"
    (click)="toggleToolsSubMenu()"
    *appShowForRoles="roleGroups['toolsTab']"
  >
    <div class="sidebar-nav-link" [class.item-active]="router.isActive('/dashboard/tools', false)">
      <div class="nav-link-text">
        <img src="../../../assets/custom-icons/sidebar_tools_icon.svg" alt="Ferramentas" />
        <span class="fontSemiBold13 fontColorDefaultGray">{{ 'tools' | translate }}</span>
      </div>
      <img class="dropdown-icon" src="../../../assets/custom-icons/dropdown_icon.svg" />
    </div>
    <ul class="sidebar-sub-menu" *ngIf="showToolsSubMenu">
      <li
        class="sidebar-sub-menu-item"
        routerLink="dashboard/tools/codes"
        routerLinkActive="sub-item-active"
        [routerLinkActiveOptions]="{ exact: true }"
        *appShowForRoles="roleGroups['codes']"
      >
        {{ 'codes' | translate }}
      </li>
      <li
        class="sidebar-sub-menu-item"
        routerLink="dashboard/tools/documents"
        routerLinkActive="sub-item-active"
        [routerLinkActiveOptions]="{ exact: true }"
        *appShowForRoles="roleGroups['documents']"
      >
        {{ 'documents' | translate }}
      </li>
    </ul>
  </li>

  <li class="logout-container" style="cursor: pointer" (click)="userLogout()">
    <img src="../../../assets/custom-icons/logout_icon.svg" alt="" />
    <span class="fontRegular13 fontColorMediumDarkGray">{{ 'sign_out' | translate }}</span>
  </li>
</ul>

<div class="searchContainer" [class.active]="searchActiveApplied" *ngIf="showSearchInput">
  <img class="searchIcon" src="../../../assets/custom-icons/search_icon.svg" alt="" />
  <input
    class=""
    type="text"
    [placeholder]="'search_placeholder' | translate"
    (focus)="addSearchActiveClass()"
    (focusout)="removeSearchActiveClass()"
    tabindex="-1"
    (input)="onSearchChange($event)"
    [(ngModel)]="searchingValue"
    required
  />
</div>
