import { Injectable } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'
import { Issue } from '../models/issue'
import { IssueComment } from '../models/issue-comment'
import { IssueHasAccount } from '../models/issue-has-account'

@Injectable({
  providedIn: 'root'
})
export class TicketsRealTimeService {
  constructor() {}
  currentTicketsList: Issue[] = []

  newTicketInfoSub = new Subject<Issue>()
  newTicketCreatedSub = new Subject<Issue>()
  newCommentSub = new Subject<IssueComment>()
  accountWasRemovedFromTicket = new Subject<IssueHasAccount>()
  unreadTickets: Issue[] = []
  unreadTicketsCount = new BehaviorSubject<number>(0)

  emitNewTicketCreated(newTicket: Issue) {
    this.newTicketCreatedSub.next(newTicket)
  }

  emitUpdatedTicketInfo(updatedInfo: Issue) {
    this.newTicketInfoSub.next(updatedInfo)
  }

  emitNewComment(newComment: IssueComment) {
    this.newCommentSub.next(newComment)
  }

  emitNewTicketsUnreadCount(data: any) {
    console.log('new unread comment: ', data)
    const issueIdHasNewComments = data[0].IssueHasAccount.issue_id

    // if index was not found, then update the unread comments count
    // for the ticket
    let index = this.unreadTickets.findIndex(ticket => ticket.id == issueIdHasNewComments)

    // if index was not found, then add the ticket to the list, otherwise, remove it
    if (index < 0) {
      // console.log('add new issue to unread list')
      this.unreadTickets.push({ id: issueIdHasNewComments } as Issue)
      this.unreadTicketsCount.next(this.unreadTicketsCount.value + 1)
    }
  }
}
